import { Table } from 'antd';
import { FC, ReactElement, useMemo } from 'react';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table/interface';
import { MAPPED_COLUMNS_NAME } from '../ReferralSystemContent.constant';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import classes from './NestedRefTable.module.scss';

interface NestedTableProps {
  isNested?: boolean;
  items: Record<string, any>[];
  page?: number;
  pageSize?: number;
  // eslint-disable-next-line no-unused-vars
  handleChangePagination?: (pagination: TablePaginationConfig) => void;
  isLoading?: boolean;
  total?: number;
}

const NestedTable: FC<NestedTableProps> = ({
  isNested,
  items,
  page,
  handleChangePagination,
  pageSize,
  isLoading,
  total,
}): ReactElement => {
  const { t } = useTranslation();
  const isDisabledPagination = page === undefined && !handleChangePagination;

  const columns: ColumnsType<Record<string, any>> = useMemo(() => {
    const [item] = items;

    if (!item) {
      return [];
    }

    const itemKeys = Object.keys(item);

    const itemsList = itemKeys.map(key => {
      const name = MAPPED_COLUMNS_NAME?.[key];

      if (!name) {
        return null;
      }

      return {
        ellipsis: key !== 'username',
        title: t(name),
        dataIndex: key,
        key,
        responsive: ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'],
      };
    });

    return itemsList.filter(Boolean) as ColumnsType<Record<string, any>>;
  }, [items, t]);

  const rootItems = useMemo(
    () =>
      items.filter(item => {
        const level = item?.level || null;

        if (level === null) {
          return true;
        }

        return level === 1;
      }),
    [items]
  );

  const nestedRows = useMemo(() => {
    const nestedRowsMap: Record<string, any> = {};

    items.forEach((item, index, itemsList) => {
      const parentItem: Record<string, any> = itemsList[index - 1];

      const id: string = parentItem?.key || parentItem?.id;

      if (!id) {
        return;
      }

      if (item.level > 1 && index) {
        nestedRowsMap[`${id}__${index}`] = { ...item };
      }
    });

    return nestedRowsMap;
  }, [items]);

  const checkRowExpandable = (row: Record<string, any>) => {
    const id: string = row?.key || row?.id;

    return (
      nestedRows && Object.keys(nestedRows).some(key => key.includes(id))
    );
  };

  // eslint-disable-next-line consistent-return
  const renderExpandIcon = (props: Record<string, any>) => {
    if (props.expandable) {
      return (
        <a onClick={e => props.onExpand(props.record, e)}>
          {props.expanded ? (
            <ArrowUpOutlined className={classes.arrow} />
          ) : (
            <ArrowDownOutlined className={classes.arrow} />
          )}
        </a>
      );
    }
  };

  const expandedRowRender = (row: Record<string, any>) => {
    const data = Object.entries(nestedRows).reduce((acc, [key, value]) => {
      const id: string = row?.key || row?.id;

      if (key.includes(id)) {
        return [...acc, { ...value }];
      }

      return acc;
    }, [] as Record<string, any>[]);

    if (!data.length) {
      return null;
    }

    return (
      <Table
        tableLayout="fixed"
        columns={columns}
        dataSource={data}
        pagination={false}
        expandIcon={props => renderExpandIcon(props)}
        expandedRowRender={isNested ? expandedRowRender : undefined}
        expandable={{
          rowExpandable: checkRowExpandable,
        }}
      />
    );
  };

  const pagination: TablePaginationConfig = useMemo(
    () => ({
      current: page,
      pageSize: nestedRows
        ? Object.keys(nestedRows).length + Number(pageSize)
        : pageSize,
      total,
    }),
    [page, pageSize, total, nestedRows]
  );

  return (
    <Table
      className={classes.root}
      rowKey={row => row.key}
      onChange={handleChangePagination}
      columns={columns}
      loading={isLoading}
      expandedRowRender={isNested ? expandedRowRender : undefined}
      expandIcon={props => renderExpandIcon(props)}
      dataSource={rootItems}
      expandable={{
        rowExpandable: checkRowExpandable,
      }}
      pagination={!isDisabledPagination && pagination}
    />
  );
};

NestedTable.defaultProps = {
  isNested: true,
};

export default NestedTable;
