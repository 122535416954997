import { Checkbox } from "antd";
import clsx from "clsx";
import { ChangeEvent, FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { RegFormProps } from "../../types/components.types";
import Button from "../common/Button/Button";
import Input from "../common/Input/Input";
import classes from "./RegForm.module.scss";

const RegForm: FC<RegFormProps> = ({
  handleSubmitForm,
  handleChangePassword,
  handleChangeRepeatPassword,
  handleChangeAccept,
  values,
  validation,
  disabled,
  handleChangeName,
}): ReactElement => {
  const { t } = useTranslation();

  return (
    <form name="reg" className={classes.regForm}>
      <div className={classes.formItem}>
        <label htmlFor="usernameValue" className={classes.labelForm}>
          {t("regForm_username")}
        </label>
        <Input
          id="usernameValue"
          type="text"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            // @ts-ignore
            handleChangeName(e.target.value)
          }
          className={clsx(
            classes.regInput,
            validation?.username === false && classes.invalid
          )}
          disabled
          placeholder="User Name"
          value={values.accountName}
        />
      </div>
      <div className={classes.formItem}>
        <label htmlFor="password" className={classes.labelForm}>
          {t("regForm_password")}
        </label>
        <Input
          autoComplete="new-password"
          id="password"
          type="password"
          className={clsx(
            classes.regInput,
            validation?.password === false && classes.invalid
          )}
          onChange={handleChangePassword}
          value={values.password}
        />
      </div>
      <div className={classes.formItem}>
        <label form="" htmlFor="repeatPassword" className={classes.labelForm}>
          {t("regForm_repeatPassword")}
        </label>
        <Input
          autoComplete="new-password"
          id="repeatPassword"
          type="password"
          className={clsx(
            classes.regInput,
            validation?.repeatPassword === false && classes.invalid
          )}
          onChange={handleChangeRepeatPassword}
          value={values.repeatPassword}
        />
      </div>
      <div
        className={clsx(
          classes.formItemAccept,
          validation?.accept === false && classes.invalidFormItem
        )}
      >
        <Checkbox
          className={clsx(classes.acceptInput)}
          onChange={handleChangeAccept}
          checked={values.accept}
        >
          <span
            className={clsx(
              validation?.accept === false && classes.invalidAccept
            )}
          >
            {t("regForm_agree")}{" "}
            <span className={classes.underlineText}>{t("regForm_term")}</span>{" "}
            {t("regForm_and")}{" "}
            <span className={classes.underlineText}>
              {t("regForm_privacy")}
            </span>
          </span>
        </Checkbox>
      </div>
      <div className={classes.submitForm}>
        <Button
          disabled={disabled}
          className={classes.submit}
          onClick={handleSubmitForm}
          type="primary"
        >
          {t("regForm_regButton")}
        </Button>
      </div>
    </form>
  );
};

export default RegForm;
