import { Layout, notification } from 'antd';
import Cookies from 'universal-cookie';
import { FC, ReactElement, useCallback, useEffect, useRef } from 'react';
import { compose } from 'redux';
import withMapContextInstance from '../../api/MapContext/withMapContextInstance';
import Content from '../../components/Content/Content';
import ContentPopup from '../../components/ContentPopup/ContentPopup';
import Aside from '../../components/Aside/Aside';
import withMapContext from '../../api/MapContext/withMapContext';
import useShallowEqualSelector from '../../utils/useShallowEqualSelector';
import {
  setClearSearchString,
  setErrorMessage,
} from '../../redux/appReducer/appReducer.slice';
import { useDispatch } from 'react-redux';
import {
  loadOauthUser,
  tryRefreshSession,
} from '../../redux/appReducer/appReducer.thunk';
import { OAUTH_ID } from '../../components/AuthContent/AuthContent.constant';
import classes from './Dashboard.module.scss';
import { MENU_ID_MAP } from '../../components/Menu/Menu.constant';
import { useLocation, Route } from 'react-router-dom';
import Settings from '../../components/ProfileContent/Settings/Settings';
import { contentUrl } from '../../config/url.config';
import ChangePassword from '../../components/ProfileContent/ChangePassword/ChangePassword';
import useOpenView from '../../hooks/useOpenView';
import ReceiveStage from '../../components/BlockchainContent/ReceiveStage/ReceiveStage';
import SendStage from '../../components/BlockchainContent/SendStage/SendStage';
import ReferralSystemContent from '../../components/ReferralSystemContent/ReferralSystemContent';
import SubscribeInfo from '../../components/ProfileContent/SubscribeInfo/SubscribeInfo';
import routes from './routes';
import BillContent from '../../components/BillContent/BillContent';
import RegistrationContent from '../../components/RegistrationContent/RegistrationContent';
import AuthContent from '../../components/AuthContent/AuthContent';
import CodeApply from '../../components/BillContent/CodeApply/CodeApply';
import SeedContent from '../../components/SeedContent/SeedContent';

const Dashboard: FC = (): ReactElement => {
  const actions = useOpenView();
  const location = useLocation();

  const dispatch = useDispatch();
  const asideRef = useRef(null);
  const timeoutId = useRef<ReturnType<typeof setTimeout> | null>();

  const {
    errorType,
    errorMessage,
    shouldClearSearchString,
    userInfoLoaded,
    hasUser,
  } = useShallowEqualSelector(state => {
    const { appReducer } = state;
    return {
      hasUser: !!appReducer.user,
      userInfoLoaded: appReducer.userInfoLoaded,
      errorType: appReducer.errorType,
      errorMessage: appReducer.errorMessage,
      shouldClearSearchString: appReducer.shouldClearSearchString,
    };
  });

  useEffect(() => {
    timeoutId.current = setInterval(() => {
      dispatch(tryRefreshSession(true));
    }, 15000);

    return () => {
      if (timeoutId.current) {
        clearInterval(timeoutId.current);
      }
    };
  }, [dispatch]);

  const clearSearchValue = useCallback(
    (value: string, full = false) => {
      const search: URLSearchParams = new URLSearchParams(location.search);

      if (full) {
        search.forEach((_, key) => {
          search.delete(key);
        });
      } else if (search.get(value)) {
        search.delete(value);
      }

      setTimeout(
        () => {
          actions.open(location.pathname);
        },
        full ? 0 : 500
      );
    },
    [actions, location.pathname, location.search]
  );

  useEffect(() => {
    const search: URLSearchParams = new URLSearchParams(location.search);

    const payment = search.get('payment');
    const walletValue = search.get('walletValue');
    const userId = search.get('userId');

    if (payment !== 'wallet' || !hasUser || !userId) {
      return;
    }

    setTimeout(() => {
      actions.open(contentUrl(MENU_ID_MAP.WALLET), {
        pay: true,
        walletValue,
      });
    }, 1000);
  }, [location.search, dispatch, hasUser, actions]);

  useEffect(() => {
    const querystring = new URLSearchParams(location.search);
    const payment = querystring.get('payment');

    if (shouldClearSearchString && !payment) {
      clearSearchValue('', true);
      dispatch(setClearSearchString(false));
    }
  }, [dispatch, shouldClearSearchString, clearSearchValue, location.search]);

  useEffect(() => {
    const hasToken = !!new Cookies().get('token');
    const querystring = new URLSearchParams(location.search);
    const facebookCode = querystring.get('code');
    const ref = querystring.get('referral');
    const payment = querystring.get('payment');

    if (payment) {
      return;
    }

    if (hasToken && facebookCode) {
      clearSearchValue('code');

      return;
    }

    if (facebookCode) {
      dispatch(
        loadOauthUser({ values: facebookCode, type: OAUTH_ID.FACEBOOK })
      );
    }

    if (facebookCode && ref) {
      clearSearchValue('code');
    }

    if (ref && !hasToken) {
      actions.open(routes.REGISTRATION, undefined, ref);
    } else if (!ref && !hasToken && location.pathname !== routes.REGISTRATION) {
      actions.open(contentUrl(MENU_ID_MAP.AUTH), { isInitialReg: true });
    }

    if (!facebookCode && hasToken && ref) {
      dispatch(setClearSearchString(true));
    }
  }, [dispatch, clearSearchValue, actions, location.search, location.pathname]);

  useEffect(() => {
    const hasToken = !!new Cookies().get('token');

    if (hasToken && !userInfoLoaded) {
      dispatch(tryRefreshSession(false));
    }
  }, [userInfoLoaded, dispatch, hasUser]);

  useEffect(() => {
    if (!errorType && !errorMessage) {
      return;
    }

    if (errorType && errorMessage) {
      notification.open({
        type: errorType === 'error' || errorMessage ? 'error' : 'info',
        message: errorType,
        description: errorMessage,
      });
    }

    dispatch(setErrorMessage(''));
  }, [dispatch, errorMessage, errorType]);

  return (
    <Layout className={classes.dashboardLayout}>
      <Aside ref={asideRef} />
      <Route exact path={routes.SETTINGS}>
        <ContentPopup
          backUrl={contentUrl(MENU_ID_MAP.PROFILE)}
          isVisible
          asideRef={asideRef}
          as={Settings}
        />
      </Route>
      <Route exact path={routes.CHANGE_PASSWORD}>
        <ContentPopup
          backUrl={routes.SETTINGS}
          isVisible
          asideRef={asideRef}
          as={ChangePassword}
        />
      </Route>
      <Route exact path={routes.RECEIVE}>
        <ContentPopup
          backUrl={contentUrl(MENU_ID_MAP.WALLET)}
          isVisible
          asideRef={asideRef}
          as={ReceiveStage}
          title="receive_name"
        />
      </Route>
      <Route exact path={routes.SEED}>
        <ContentPopup
          isVisible
          asideRef={asideRef}
          as={SeedContent}
          title="seed_title"
        />
      </Route>
      <Route exact path={routes.SEND}>
        <ContentPopup
          backUrl={contentUrl(MENU_ID_MAP.WALLET)}
          isVisible
          asideRef={asideRef}
          as={SendStage}
          title="send_name"
        />
      </Route>
      <Route exact path={routes.REF}>
        <ContentPopup
          backUrl={contentUrl(MENU_ID_MAP.PROFILE)}
          isVisible
          asideRef={asideRef}
          as={ReferralSystemContent}
        />
      </Route>
      <Route path={routes.REGISTRATION}>
        <ContentPopup
          backUrl={routes.LOGIN}
          isVisible
          asideRef={asideRef}
          as={RegistrationContent}
          title="reg_title"
        />
      </Route>
      <Route path={routes.LOGIN}>
        <ContentPopup isVisible asideRef={asideRef} as={AuthContent} />
      </Route>
      <Route exact path={routes.SUB}>
        <ContentPopup
          backUrl={contentUrl(MENU_ID_MAP.PROFILE)}
          isVisible
          asideRef={asideRef}
          as={SubscribeInfo}
        />
      </Route>
      <Route exact path={routes.BILL_PAGE}>
        <ContentPopup
          backUrl={routes.SEND}
          isVisible
          asideRef={asideRef}
          as={BillContent}
          title="bill_title"
        />
      </Route>
      <Route exact path={routes.BILL_CODE}>
        <ContentPopup
          backUrl={routes.BILL_PAGE}
          isVisible
          asideRef={asideRef}
          as={CodeApply}
          title="bill_titleCode"
        />
      </Route>
      <Route exact path={routes.MENU_ITEM}>
        <ContentPopup isVisible asideRef={asideRef} />
      </Route>
      <Route exact path={routes.ITEM_CHANGE}>
        <ContentPopup isVisible asideRef={asideRef} />
      </Route>
      <Content />
    </Layout>
  );
};

export default compose(withMapContext, withMapContextInstance)(Dashboard);
