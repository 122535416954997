import { FC, useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';
import useQuery from '../../hooks/useQuery';
import { subscriptionsList } from '../../components/SubscribeContent/SubscribeContent.constant';
import classes from './SubscribePaymentPage.module.scss';
import { subscribeOnPlan } from '../../api/WalletApi/WalletApi.api';
import { fetchRefreshUser } from '../../api/AppApi/AppApi.api';
import RequestError from '../../models/RequestError/RequestError';
import { handleRequestError } from '../../utils/utils.global';
import { useHistory } from 'react-router-dom';

type paymentType = 'wallet' | 'sub';

const SubscribePaymentPage: FC = () => {
  const history = useHistory();

  const timerId = useRef<ReturnType<typeof setTimeout>>();

  const [textMessage, setTextMessage] = useState<string>(
    'Payment processing...'
  );
  const [isPaymentProcess, setPaymentProcess] = useState<boolean>(false);
  const [payType, setPayType] = useState<paymentType | null>(null);
  const [codeValue, setCode] = useState<string | null>(null);
  const [value, setValue] = useState<string | null>(null);

  const queryString = useQuery();

  const forceRedirect = () => {
    window.location.assign('/');
  };

  useEffect(() => {
    if (!queryString) {
      forceRedirect();
    }

    if (!queryString || value) {
      return;
    }

    const type = queryString.get('type');
    const code = queryString.get('callback');
    const payTypeQuery = queryString.get('payType');

    if (!code || !type || !payTypeQuery) {
      forceRedirect();
    }

    setPayType(payTypeQuery as paymentType);
    setCode(code);
    setValue(type);
  }, [value, queryString]);

  useEffect(() => {
    if (value && !isPaymentProcess) {
      const subItem =
        subscriptionsList.find(item => item.id === value) || null;

      if (!subItem && payType === 'sub') {
        forceRedirect();

        return;
      }

      if (payType === 'wallet') {
        window.location.assign(
          `/?payment=wallet&walletValue=${value}&userId=${codeValue}`
        );

        return;
      }

      if (!subItem) {
        forceRedirect();

        return;
      }

      setPaymentProcess(true);

      timerId.current = setTimeout(() => {
        fetchRefreshUser()
          .then(loadedUser => {
            if (loadedUser && loadedUser.id && loadedUser.id === codeValue) {
              return subscribeOnPlan(subItem.id as string);
            }

            throw new RequestError('invalid load current user', {
              error: 'Invalid payment',
              message: 'Payment subscribe fail',
            });
          })
          .then(([data]) => {
            if (data) {
              setTextMessage('Payment resolved, please wait for redirect...');
            }
          })
          .catch(error => {
            setTextMessage('Payment failed, please wait for redirect...');
            handleRequestError(error);
          })
          .finally(() => {
            timerId.current = setTimeout(() => {
              forceRedirect();
            }, 1500);
          });
      }, 1500);
    }
  }, [value, isPaymentProcess, codeValue, payType, history]);

  useEffect(
    () => () => {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
    },
    []
  );

  return (
    <div className={classes.component}>
      <Spin size="large" tip={textMessage} />
    </div>
  );
};

export default SubscribePaymentPage;
