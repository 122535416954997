import {
  ReactElement,
  useEffect,
  forwardRef,
  RefObject,
  MouseEventHandler,
  useCallback,
  RefCallback,
  LegacyRef,
} from 'react';
import { Dropdown, Menu } from 'antd';
import { isContain } from '../../utils/utils.global';
import CheckIcon from '../Icons/CheckIcon/CheckIcon';
import CofeeIcon from '../Icons/CofeeIcon/CoffeIcon';
import { MENU_ID_MAP } from '../Menu/Menu.constant';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalStore } from '../../interfaces';
import DefaultForm from './DefaultForm/DefaultForm';
import PowerbankForm from './PowerbankForm/PowerbankForm';
import clsx from 'clsx';
import { mapMenuType } from '../../types';
import { PointPopupProps } from '../../types/components.types';
import classes from './PointPopup.module.scss';
import { fetchApprovePoint } from '../../redux/mapReducer/mapReducer.thunk';
import { useTranslation } from 'react-i18next';
import useOpenView from '../../hooks/useOpenView';
import { contentItemUrl } from '../../config/url.config';

const PointPopup = forwardRef<RefCallback<HTMLDivElement>, PointPopupProps>(
  (
    {
      style,
      onHide,
      password,
      username,
      isMobile,
      id,
      approved,
      address,
      daysSinceCreated,
    },
    ref
  ): ReactElement => {
    const actions = useOpenView();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const type = useSelector<GlobalStore, mapMenuType | ''>(state => {
      const { mapType } = state.mapReducer;
      return mapType;
    });

    const isPowerbankType = type === 'powerbanks';

    useEffect(() => {
      const handleEscapeClosePopup = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          onHide();

          event.stopPropagation();
        }
      };

      document.addEventListener('keydown', handleEscapeClosePopup, true);

      return () => {
        document.removeEventListener('keydown', handleEscapeClosePopup, true);
      };
    }, [onHide, dispatch]);

    useEffect(() => {
      const handleHidePopup = ({ target }: MouseEvent) => {
        const element = (ref as RefObject<HTMLDivElement | null>).current;

        if (!element || !target || isContain(element, target, 'dropdown')) {
          return;
        }

        onHide();
      };

      document.addEventListener('click', handleHidePopup);

      return () => {
        document.removeEventListener('click', handleHidePopup);
      };
    }, [onHide, ref]);

    const handleChangeWifiPoint = () => {
      actions.open(contentItemUrl(MENU_ID_MAP.WIFI_CHANGE, id));
    };

    const handleApprovePoint: MouseEventHandler = useCallback(() => {
      dispatch(fetchApprovePoint(id));
    }, [dispatch, id]);

    const actionsList = (
      <Menu>
        {/* {!isPowerbankType && (
          <Menu.Item onClick={handleMapDirectionService}>Найти путь</Menu.Item>
        )} */}
        <Menu.Item onClick={handleChangeWifiPoint}>
          {t('change_actionName')}
        </Menu.Item>
      </Menu>
    );

    return (
      <div
        ref={ref as LegacyRef<HTMLDivElement>}
        style={isMobile ? undefined : style}
        className={clsx(
          classes.pointPopup,
          isMobile && classes.mobileVersionPopup
        )}
      >
        <div className={classes.header}>
          <div className={classes.pointNameBlock}>
            <div className={classes.icon}>
              <CofeeIcon />
            </div>
            <div className={classes.about}>
              <div className={classes.nameBlock}>
                <p className={classes.name}>
                  {username || t('pointPopup_username')}
                </p>
                {approved && <CheckIcon width="25" />}
              </div>
              <span className={classes.address}>{address || '-'}</span>
              {!approved && (
                <div className={classes.approveSign}>
                  <span>{t('pointPopup_notApproved')}</span>
                </div>
              )}
            </div>
          </div>
          <Dropdown
            trigger={['hover', 'click']}
            className="popup-dropdown"
            overlay={actionsList}
            placement="bottomRight"
          >
            <div className={classes.dotsIcon}>...</div>
          </Dropdown>
        </div>
        <div className={classes.content}>
          {!isPowerbankType ? (
            <DefaultForm
              handleApprovePoint={handleApprovePoint}
              password={password}
              username={username || t('pointPopup_username')}
              approved={approved}
              daysSinceCreated={daysSinceCreated}
            />
          ) : (
            <PowerbankForm />
          )}
        </div>
      </div>
    );
  }
);

PointPopup.defaultProps = {
  username: '',
  password: '',
  approved: false,
};

export default PointPopup;
