import { message } from 'antd';
import { AUTH_FORM, REG_EXP } from '../../App.constant';
import i18n from '../../i18n';
import { formAuthType } from '../../types';
import { AUTH_STAGES_MAP } from './AuthContent.constant';

export const isValidEmailString = (email: string): boolean =>
  REG_EXP.EMAIL.test(email);

export const validationAuthValues = (
  validation: Record<string, boolean>,
  loginValues: Record<string, string | boolean>,
  stage: formAuthType
): Record<string, boolean> => {
  const { password, repeatPassword, accept, username } = loginValues;

  const isLessMinNumber: boolean =
    !!password && (<string>password).length < AUTH_FORM.MIN_PASSWORD;

  const isInvalidPassword = !password || isLessMinNumber;
  const validationValues = Object.values(validation);
  // const isValidEmail = isValidEmailString(<string>email);

  const newValidation: Record<string, boolean> = {};

  if (
    validationValues.length > 1 &&
    validationValues.every(value => !value)
  ) {
    // newValidation.email = isValidEmail;
    newValidation.password = !isInvalidPassword;
  }

  if (isLessMinNumber) {
    message.error(
      `${i18n.t('message_passwordMore')} ${AUTH_FORM.MIN_PASSWORD}`
    );
  }

  // if (!REG_EXP.EMAIL.test(<string>email)) {
  //  newValidation.email = false;
  //   message.error(i18n.t('message_badEmailFormat'));
  // } else if (validation?.email === false) {
  //  newValidation.email = true;
  // }

  if (isInvalidPassword) {
    newValidation.password = false;
    message.error(i18n.t('message_badPasswordFormat'));
  } else if (validation?.password === false) {
    newValidation.password = true;
  }

  if (stage !== AUTH_STAGES_MAP.REGISTRATION) {
    return newValidation;
  }

  if (repeatPassword !== password) {
    newValidation.repeatPassword = false;
    message.error(i18n.t('message_passwordNotEqual'));
  } else if (repeatPassword === password) {
    newValidation.repeatPassword = true;
  }

  if (!accept) {
    message.error(i18n.t('message_shouldApproveRule'));
  }

  newValidation.accept = accept as boolean;

  if (!username) {
    message.error(i18n.t('message_badUsername'));
    newValidation.username = false;
  } else if (username) {
    newValidation.username = true;
  }

  return newValidation;
};
