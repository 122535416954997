import { message } from 'antd';
import Button from '../../common/Button/Button';
import Input from '../../common/Input/Input';
import {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  MouseEventHandler,
  ReactElement,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { fetchChangePassword } from '../../../api/AppApi/AppApi.api';
import classes from './ChangePassword.module.scss';
import withAuth from '../../helpers/withAuth';

const ChangePassword: FC = (): ReactElement => {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isValid, setValid] = useState<boolean>(true);
  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');

  const handleChangePassword: ChangeEventHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (password === repeatPassword && !isValid) {
      setValid(true);
    }

    setPassword(event.target.value);
  };

  const handleChangeRepeatPassword: ChangeEventHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (password === repeatPassword && !isValid) {
      setValid(true);
    }

    setRepeatPassword(event.target.value);
  };

  const handleSubmitButton: MouseEventHandler = () => {
    if (password !== repeatPassword) {
      message.error(t('changeForm_invalidMessagePassword'));
      setValid(false);
    }

    setLoading(true);

    fetchChangePassword(password)
      .then(result => {
        if (result) {
          message.success(t('settings_changePasswordMessage'));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const disabled = password === '' || repeatPassword === '';

  return (
    <section className={classes.root}>
      <form className={classes.content}>
        <div className={classes.contentBlock}>
          <span className={classes.title}>{t('regForm_password')}</span>
          <Input
            onChange={handleChangePassword}
            autoComplete="new-password"
            type="password"
            className={classes.input}
            value={password}
          />
        </div>
        <div className={classes.contentBlock}>
          <span className={classes.title}>{t('regForm_repeatPassword')}</span>
          <Input
            onChange={handleChangeRepeatPassword}
            autoComplete="new-password"
            type="password"
            className={classes.input}
            value={repeatPassword}
          />
        </div>
        <div className={classes.submitSave}>
          <Button
            disabled={isLoading || disabled}
            onClick={handleSubmitButton}
            className={classes.saveButton}
            type="primary"
          >
            {t('changeForm_save')}
          </Button>
        </div>
      </form>
    </section>
  );
};

export default withAuth(ChangePassword);
