// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
import getWalletStore from "./getWalletStore";
import { Aes, PrivateKey } from "2ab2-js";
import walletStore from "../walletStore/wallet";

const validatePassword = async (
  password: string,
  unlock = true
): Promise<boolean> => {
  // eslint-disable-next-line no-shadow
  const wallet = await getWalletStore();

  if (!wallet) {
    throw new Error("wallet not found");
  }

  try {
    const passwordPrivate = PrivateKey.fromSeed(password);
    const passwordPubkey = passwordPrivate.toPublicKey().toPublicKeyString();

    if (wallet.password_pubkey !== passwordPubkey) {
      return false;
    }

    if (unlock) {
      const passwordAes = Aes.fromSeed(password);
      const encryptionPlainbuffer = passwordAes.decryptHexToBuffer(
        wallet.encryption_key
      );

      walletStore.aesPrivate = Aes.fromSeed(encryptionPlainbuffer);
    }

    return true;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return false;
  }
};

export default validatePassword;
