import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchApprovePointThunkCreator,
  fetchCreatePointThunkCreator,
  viewportPointsThunkCreator,
} from './mapReducer.creators';

export const fetchCreatePoint = createAsyncThunk(
  'mapReducer/fetchCreatePoint',
  fetchCreatePointThunkCreator
);

export const fetchViewportPoints = createAsyncThunk(
  'mapReducer/fetchViewportPoints',
  viewportPointsThunkCreator
);

export const fetchApprovePoint = createAsyncThunk(
  'mapReducer/fetchApprovePoint',
  fetchApprovePointThunkCreator
);
