import { FC, useMemo } from 'react';
import clsx from 'clsx';
import { AvatarProps } from '../../interfaces';
import classes from './Avatar.module.scss';
import UserIcon from '../Icons/UserIcon/UserIcon';

const Avatar: FC<AvatarProps> = ({
  onClick,
  urlAvatar,
  user,
  className,
  isActive,
}) => {
  const { avatar, username, email, photo } = user || {};

  const { firstNameLatter, firstLastNameLatter } = useMemo(() => {
    const value = username || email;

    if (!value) {
      return {};
    }

    const [name, lastName] = value.split(' ');

    if (!name && !lastName) {
      return {};
    }

    return {
      firstNameLatter: name?.[0] || '',
      firstLastNameLatter: lastName?.[0] || '',
    };
  }, [email, username]);

  if (!user) {
    return (
      <UserIcon
        className={classes.userIcon}
        onClick={onClick}
        isActive={isActive}
      />
    );
  }

  if (avatar || urlAvatar || photo) {
    return (
      <div
        style={{
          backgroundImage: `url(${avatar || urlAvatar || photo})`,
        }}
        onClick={onClick || undefined}
        className={clsx(classes.avatarImage, className, {
          [classes.interactive]: !!onClick,
          [classes.active]: isActive,
        })}
      />
    );
  }

  return (
    <div
      onClick={onClick || undefined}
      aria-label="open profile or menu"
      role="button"
      aria-pressed="false"
      tabIndex={-1}
      className={clsx(classes.container, className, {
        [classes.active]: isActive,
      })}
    >
      <div className={classes.avatarValue}>
        {firstNameLatter} {firstLastNameLatter}
      </div>
    </div>
  );
};

Avatar.defaultProps = {
  urlAvatar: '',
  user: null,
  onClick: null,
};

export default Avatar;
