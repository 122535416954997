import {
  ErrorResponse,
  MapPoint,
  PointValues,
  ViewportCoords,
  ViewportPoint,
} from '../../interfaces';
import { v4 as uuidv4 } from 'uuid';
import Request from '../../models/Request/Request';
import RequestError from '../../models/RequestError/RequestError';
import {
  returnCreatePointType,
  returnViewportPoints,
  returnApprovePoint,
} from '../../types';
import {
  getUrlWithQueryParams,
  handleRequestError,
} from '../../utils/utils.global';
import {
  getUndefinedWifiPointEndpointById,
  MAP_ENDPOINTS,
} from '../Endpoints.constant';
import { message } from 'antd';
import i18n from '../../i18n';
import { pointUrlApi } from '../Endpoints.constant';

export const createPoint = async (
  values: PointValues
): Promise<returnCreatePointType> => {
  try {
    const request = new Request();
    const response = await request.send(
      MAP_ENDPOINTS.CREATE_POINT,
      'POST',
      values
    );

    const { status, data } = response;

    if ([200, 201].every(code => status !== code)) {
      throw new RequestError('Bad create point', data);
    }

    const { latitude, longitude, ...pointValues } = values;

    const point = {
      ...pointValues,
      lat: latitude,
      lng: longitude,
    };

    return [point, false];
  } catch (err) {
    return handleRequestError(err as RequestError, true);
  }
};

export const fetchPoints = async (
  viewportCoord: ViewportCoords
): Promise<returnViewportPoints> => {
  try {
    const request = new Request('public');

    const apiUrl: string = getUrlWithQueryParams(
      MAP_ENDPOINTS.GET_VIEWPORT_POINTS,
      viewportCoord as Record<string, any>
    );

    const response = await request.send(apiUrl);

    const { status, data } = response;

    if (status !== 200 || !request.isValidResponse(response)) {
      throw new RequestError('Bad get viewport points', response.data);
    }

    const pointData = data as ViewportPoint | Array<ViewportPoint>;

    if (!pointData) {
      throw new RequestError('invalid response data', response.data);
    }

    let pointValue: MapPoint | Array<MapPoint> | null = null;

    if (Array.isArray(pointData)) {
      pointValue = pointData.map(
        ({ id, username, coordinates, ...otherPointValues }) => ({
          id,
          username,
          lat: coordinates.latitude,
          lng: coordinates.longitude,
          ...otherPointValues,
        })
      );
    } else {
      const { id, username, coordinates, ...otherPointValues } = data;
      pointValue = {
        id,
        username,
        lat: coordinates.latitude,
        lng: coordinates.longitude,
        ...otherPointValues,
      };
    }

    if (!pointValue) {
      throw new RequestError('Invalid pointValue', response.data);
    }

    return [pointValue, false];
  } catch (err) {
    return handleRequestError(err as RequestError, true);
  }
};

export const approvePoint = async (
  wifiPointId: string
): Promise<returnApprovePoint> => {
  try {
    const request = new Request();

    message.info(i18n.t('pointForm_approvedMessageInfo'));

    const response = await request.send(MAP_ENDPOINTS.APPROVE_POINT, 'POST', {
      id: uuidv4(),
      wifiPointId,
    });

    return [response.data, false];
  } catch (err) {
    return handleRequestError(err as RequestError, true);
  }
};

export const requestUndefinedPointById = async (
  wifiPointId: string
): Promise<[boolean | ErrorResponse, boolean]> => {
  try {
    const request = new Request();

    const response = await request.send(
      getUndefinedWifiPointEndpointById(wifiPointId),
      'GET'
    );

    message.info(i18n.t('pointForm_undefinedPointMessage'));

    return [response.data, false];
  } catch (err) {
    return handleRequestError(err as RequestError, true);
  }
};

export const fetchChangePointPassword = async (
  id: string,
  password: string
): Promise<boolean> => {
  try {
    const request = new Request();

    const response = await request.send(MAP_ENDPOINTS.CHANGE_POINT, 'POST', {
      wifiPointId: id,
      password,
    });

    if (!(response.status >= 200 && response.status < 300)) {
      throw new RequestError('Invalid change point', response.data);
    }

    return response.data;
  } catch (error) {
    handleRequestError(error as RequestError, true);
    return false;
  }
};

export const fetchPoint = async (id: string): Promise<MapPoint | null> => {
  try {
    const request = new Request();
    const response = await request.send(pointUrlApi(id));

    const { status, data } = response;

    if (status !== 200 || !request.isValidResponse(response)) {
      throw new RequestError('Bad load point', data);
    }

    return data;
  } catch (err) {
    handleRequestError(err as RequestError, true);
    return null;
  }
};
