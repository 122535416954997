import restoreSrc from './restore.png';
import { FC, PropsWithChildren } from 'react';
import classes from './AwaitBackground.module.scss';

const AwaitBackground: FC<PropsWithChildren<Record<string, unknown>>> = ({
  children,
}) => (
  <>
    <div
      style={{ backgroundImage: `url(${restoreSrc})` }}
      className={classes.contentImage}
    />
    {children}
  </>
);

export default AwaitBackground;
