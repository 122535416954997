import { RefIdTab } from './ReferralSystemContent.interface';

export const TABS_REF_ID: Readonly<Record<string, RefIdTab>> = {
  STRUCTURE_ID: 'Structure',
  FINANCE_ID: 'Finance',
};

export const MAPPED_COLUMNS_NAME: Readonly<Record<string, string>> = {
  level: 'columns_level',
  username: 'columns_username',
  countOfUsersInStructure: 'columns_peopleInTheStructure',
  edcReferralRevenue: 'columns_referralRevenue',
  createdAt: 'columns_date',
  amount: 'columns_amount',
  status: 'columns_status',
};

interface IBonus {
  id: number;
  value: number;
  text: string;
}

export const bonusList: Array<IBonus> = [
  {
    id: 1,
    value: 15,
    text: 'refBonusList_item1',
  },
  {
    id: 2,
    value: 12,
    text: 'refBonusList_item2',
  },
  {
    id: 3,
    value: 8,
    text: 'refBonusList_item3',
  },
  {
    id: 4,
    value: 5,
    text: 'refBonusList_item4',
  },
  {
    id: 5,
    value: 3,
    text: 'refBonusList_item5',
  },
];
