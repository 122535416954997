import { FC, ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import withError from './components/ErrorBoundary/ComponentWithErrorBoundary';
import Dashboard from './pages/Dashboard/Dashboard';
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage';
import SubscribePaymentPage from './pages/SubscribePaymentPage/SubscribePaymentPage';
import { PAYMENT_PAGE_PATHNAME } from './components/QCode/Qcode.constant';

const App: FC = (): ReactElement => (
  <Switch>
    <Route path={PAYMENT_PAGE_PATHNAME} component={SubscribePaymentPage} />
    <Route exact path="/reset-password" component={ResetPasswordPage} />
    <Route path="*" component={Dashboard} />
  </Switch>
);

export default withError(App);
