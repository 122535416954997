import {
  JSXElementConstructor,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';

type SeedStageState = 'PENDING' | 'DONE';

export const SEED_STATE_STORAGE_KEY = 'SEED_STAGE';

const withSeedGuard =
  (Component: JSXElementConstructor<any>) =>
  (props: Record<string, any>): ReactElement | null => {
    const history = useHistory();
    const [isLock, setLock] = useState<boolean>(true);

    useEffect(() => {
      const stage = localStorage.getItem(
        SEED_STATE_STORAGE_KEY
      ) as SeedStageState;

      if (stage === 'DONE') {
        history.goBack();
      }

      setLock(false);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLock) {
      return null;
    }

    return <Component {...props} />;
  };

export default withSeedGuard;
