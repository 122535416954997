import { ChainConfig } from "2ab2-ws-js";

ChainConfig.setPrefix("AB2");

interface IWalletMemoryStore {
  aesPrivate: Record<string, any> | undefined;
  brainKeyLookAhead: string;
  chainstoreAccountIdsByKey: any;
  generateNextKeyPubCache: Record<string, any>[];
  // eslint-disable-next-line no-unused-vars
  setAesPrivate(_aesPrivate: Record<string, any>): void;
  accountRefs: Record<string, any>[];
}

const walletStore: IWalletMemoryStore = {
  aesPrivate: undefined,
  chainstoreAccountIdsByKey: null,
  generateNextKeyPubCache: [],
  brainKeyLookAhead: "",
  accountRefs: [],
  setAesPrivate(_aesPrivate: Record<string, any>) {
    this.aesPrivate = _aesPrivate;
  },
};

// glime filler repaper imine dazedly ragtag catlap monocot oxidase atune coalify unsleek pithos lumbago cern brink

export default walletStore;
