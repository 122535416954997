/* eslint-disable arrow-parens */
import {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  MouseEventHandler,
  ReactElement,
  useEffect,
  useState,
} from "react";
import Button from "../common/Button/Button";
import { v4 as uuidv4 } from "uuid";
import { ContentProps } from "../../interfaces";
import RegForm from "../RegForm/RegForm";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setClearSearchString } from "../../redux/appReducer/appReducer.slice";
import useShallowEqualSelector from "../../utils/useShallowEqualSelector";
import { MENU_ID_MAP } from "../Menu/Menu.constant";
import classes from "./RegistrationContent.module.scss";
import { useParams } from "react-router-dom";
import useOpenView from "../../hooks/useOpenView";
import { contentUrl } from "../../config/url.config";
import routes from "../../pages/Dashboard/routes";
import { validationAuthValues } from "../AuthContent/AuthContent.utils";
import {
  AUTH_STAGES_MAP,
  AUTH_STAGE_NAMES,
} from "../AuthContent/AuthContent.constant";
import Seed from "../SeedContent/SeedContent";
import createAccount from "../../api/v2/blockchain/actions/createAccount";
import useQuery from "../../hooks/useQuery";
import onSetWallet from "../../api/v2/blockchain/actions/onSetWallet";
import onDeleteAllWallets from "../../api/v2/blockchain/actions/onDeleteAllWallets";
import { fetchCreateUser } from "../../redux/appReducer/appReducer.thunk";
import { notification } from "antd";
import generateKey, { ACCOUNT_NAME_KEY } from "../../utils/generateKey";

const RegistrationContent: FC<ContentProps> = (): ReactElement => {
  const actions = useOpenView();
  const { activeMenuId } = useParams<Record<string, string>>();

  const { t } = useTranslation();
  const queryString = useQuery();
  // const queryString = useQuery();
  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [isVisibleSeedPrepare, setVisibleSeedPrepare] =
    useState<boolean>(false);
  const [validation, setValidation] = useState<Record<string, boolean>>({});

  const [accountName] = useState<string>(() => generateKey());
  const [accept, setAccept] = useState<boolean>(false);
  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const user = useShallowEqualSelector((state) => state.appReducer.user);

  useEffect(() => {
    // hack for redirect from auth page to profile
    if (user && activeMenuId === MENU_ID_MAP.PROFILE) {
      actions.open(contentUrl(MENU_ID_MAP.PROFILE));
    }

    // eslint-disable-next-line
  }, [user, dispatch]);

  const handleChangeAccept = (event: CheckboxChangeEvent) =>
    setAccept(event.target.checked);

  const handleChangeRepeatPassword: ChangeEventHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => setRepeatPassword(event.target.value);

  const handleChangePassword: ChangeEventHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => setPassword(event.target.value);

  const createWallet = async () => {
    await onDeleteAllWallets();
    return onSetWallet("default", password);
  };

  const createUser = async () => {
    const referral = queryString?.get("referral") || "";

    return dispatch(
      fetchCreateUser({
        id: uuidv4(),
        referral,
        password,
        email: `${accountName}@email.com`,
      })
    );
  };

  const createNewAccount = async () => {
    const referrer = queryString?.get("referral") || "";

    localStorage.setItem(ACCOUNT_NAME_KEY, accountName);

    const blockchainAccountResult = await createAccount(accountName, referrer);

    if (!blockchainAccountResult) {
      console.log("error");

      // throw new Error('Unknown error in create account');
    }

    try {
      await createUser();

      setVisibleSeedPrepare(true);
    } catch (error: any) {
      const errorMsg =
        error.base && error.base.length && error.base.length > 0
          ? error.base[0]
          : "unknown error";

      notification.error({
        message: "create account error",
        description: errorMsg,
      });

      // eslint-disable-next-line no-console
      console.error(errorMsg);
    }
  };

  const handleSubmitForm: MouseEventHandler = async () => {
    const validationValues: Record<string, string | boolean> = {};

    validationValues.password = password;
    validationValues.accept = accept;
    validationValues.repeatPassword = repeatPassword;
    validationValues.username = accountName;

    const newValidation = validationAuthValues(
      validation,
      validationValues,
      AUTH_STAGES_MAP.AUTH
    );

    if (newValidation !== validation) {
      setValidation(newValidation);
    }

    if (Object.values(newValidation).some((value) => !value)) {
      return;
    }

    // setVisibleSeedPrepare(true);

    await createWallet();
    await createNewAccount();
  };

  const handleChangeMode = () => {
    dispatch(setClearSearchString(true));

    actions.open(routes.LOGIN);
  };

  if (isVisibleSeedPrepare) {
    return <Seed />;
  }

  return (
    <section className={classes.authPage}>
      <div className={classes.content}>
        <RegForm
          validation={validation}
          handleChangePassword={handleChangePassword}
          handleSubmitForm={handleSubmitForm}
          handleChangeRepeatPassword={handleChangeRepeatPassword}
          handleChangeAccept={handleChangeAccept}
          disabled={!accountName || !password}
          values={{
            password,
            accountName,
            repeatPassword,
            accept,
          }}
        />
      </div>
      <div className={classes.footer}>
        <Button
          onClick={handleChangeMode}
          className={classes.regButton}
          type="default"
        >
          {t(AUTH_STAGE_NAMES.AUTH)}
        </Button>
      </div>
    </section>
  );
};

export default RegistrationContent;
