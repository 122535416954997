/* eslint-disable eqeqeq */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */

const getGeolocationMarkerClass = (): any => {
  class GeolocationMarker extends window.google.maps.MVCObject {
    constructor(
      optap: any,
      opt_outerMarkerOpts: any,
      opt_markerOpts: any,
      opt_circleOpts: any
    ) {
      super();

      this.outerMarker_ = null;

      this.marker_ = null;

      this.circle_ = null;

      this.watchId_ = -1;

      let outerMarkerOpts = {
        radius: 5,
        clickable: false,
        cursor: 'pointer',
        draggable: false,
        flat: true,
        icon: {
          path: window.google.maps.SymbolPath.CIRCLE,
          fillColor: '#C8D6EC',
          fillOpacity: 0.7,
          scale: 12,
          strokeWeight: 0,
        },
        position: new window.google.maps.LatLng(0, 0),
        title: 'Current location',
        zIndex: 2,
      };

      let markerOpts = {
        clickable: false,
        cursor: 'pointer',
        draggable: false,
        flat: true,
        icon: {
          path: window.google.maps.SymbolPath.CIRCLE,
          fillColor: '#56CCF2',
          fillOpacity: 1,
          scale: 6,
          strokeColor: 'white',
          strokeWeight: 2,
        },
        // This marker may move frequently - don't force canvas tile redraw
        optimized: false,
        position: new window.google.maps.LatLng(0, 0),
        zIndex: 3,
      };

      if (opt_outerMarkerOpts) {
        outerMarkerOpts = this.copyOptions_(
          outerMarkerOpts,
          opt_outerMarkerOpts
        ) as any;
      }

      if (opt_markerOpts) {
        markerOpts = this.copyOptions_(markerOpts, opt_markerOpts) as any;
      }

      let circleOpts = {
        clickable: false,
        radius: 100,
        strokeColor: '56ccf2',
        strokeOpacity: 0.1,
        fillColor: '56ccf2',
        fillOpacity: 0.1,
        strokeWeight: 0.3,
        zIndex: 1,
      };

      if (opt_circleOpts) {
        circleOpts = this.copyOptions_(circleOpts, opt_circleOpts) as any;
      }

      this.outerMarker_ = new window.google.maps.Marker(outerMarkerOpts);
      this.marker_ = new window.google.maps.Marker(markerOpts);
      this.circle_ = new window.google.maps.Circle(circleOpts);

      window.google.maps.MVCObject.prototype.set.call(this, 'accuracy', null);
      window.google.maps.MVCObject.prototype.set.call(this, 'position', null);
      window.google.maps.MVCObject.prototype.set.call(this, 'map', null);

      this.set('minimum_accuracy', null);

      this.set('position_options' /** GeolocationPositionOptions */, {
        enableHighAccuracy: true,
        maximumAge: 1000,
      });

      this.circle_.bindTo('map', this.outerMarker_);
      this.circle_.bindTo('map', this.marker_);

      if (optap) {
        this.setMap(optap);
      }
    }

    set(
      key: string,
      value: { enableHighAccuracy: boolean; maximumAge: number } | null
    ) {
      if (GeolocationMarker.invalidPropertiesExpr_.test(key)) {
        // eslint-disable-next-line no-throw-literal
        throw '\'' + key + '\' is a read-only property.';
      } else if (key === 'map') {
        this.setMap(value);
      } else {
        window.google.maps.MVCObject.prototype.set.call(this, key, value);
      }
    }

    getMap() {
      return this.get('map');
    }

    getPositionOptions() {
      return this.get('position_options');
    }

    setPositionOptions(positionOpts: any) {
      this.set('position_options', positionOpts);
    }

    getPosition() {
      return this.get('position');
    }

    getBounds() {
      if (this.get('position')) {
        return this.circle_.getBounds();
      }
      return null;
    }

    getAccuracy() {
      return this.get('accuracy');
    }

    getMinimumAccuracy() {
      return this.get('minimum_accuracy');
    }

    setMinimumAccuracy(accuracy: any) {
      this.set('minimum_accuracy', accuracy);
    }

    setMap(map: any) {
      window.google.maps.MVCObject.prototype.set.call(this, 'map', map);
      if (map) {
        this.watchPosition_();
      } else {
        this.outerMarker_.unbind('position');
        this.marker_.unbind('position');
        this.circle_.unbind('center');
        this.circle_.unbind('radius');
        window.google.maps.MVCObject.prototype.set.call(this, 'accuracy', null);
        window.google.maps.MVCObject.prototype.set.call(this, 'position', null);
        navigator.geolocation.clearWatch(this.watchId_);
        this.watchId_ = -1;
        this.outerMarker_.setMap(map);
        this.marker_.setMap(map);
      }
    }

    setOuterMarkerOptions(outerMarkerOpts: any) {
      this.outerMarker_.setOptions(this.copyOptions_({}, outerMarkerOpts));
    }

    setMarkerOptions(markerOpts: any) {
      this.marker_.setOptions(this.copyOptions_({}, markerOpts));
    }

    setCircleOptions(circleOpts: any) {
      this.circle_.setOptions(this.copyOptions_({}, circleOpts));
    }

    updatePosition_(position: {
      coords: { latitude: any; longitude: any; accuracy: number };
    }) {
      const newPosition = new window.google.maps.LatLng(
        position.coords.latitude,
        position.coords.longitude
      );
      const mapNotSet = this.marker_.getMap() == null;

      if (mapNotSet) {
        if (
          this.getMinimumAccuracy() != null &&
          position.coords.accuracy > this.getMinimumAccuracy()
        ) {
          return;
        }
        this.outerMarker_.setMap(this.getMap());
        this.marker_.setMap(this.getMap());
        this.outerMarker_.bindTo('position', this);
        this.marker_.bindTo('position', this);
        this.circle_.bindTo('center', this, 'position');
        this.circle_.bindTo('radius', this, 'accuracy');
      }

      // eslint-disable-next-line eqeqeq
      if (this.getAccuracy() != position.coords.accuracy) {
        // The local set method does not allow accuracy to be updated
        window.google.maps.MVCObject.prototype.set.call(
          this,
          'accuracy',
          position.coords.accuracy
        );
      }

      if (
        mapNotSet ||
        this.getPosition() == null ||
        !this.getPosition().equals(newPosition)
      ) {
        // The local set method does not allow position to be updated
        window.google.maps.MVCObject.prototype.set.call(
          this,
          'position',
          newPosition
        );
      }
    }

    watchPosition_() {
      if (navigator.geolocation) {
        this.watchId_ = navigator.geolocation.watchPosition(
          this.updatePosition_.bind(this),
          this.geolocationError_.bind(this),
          this.getPositionOptions()
        );
      }
    }

    geolocationError_(data: any) {
      window.google.maps.event.trigger(this, 'geolocation_error', data);
    }

    // eslint-disable-next-line class-methods-use-this
    copyOptions_(
      target: {
        [x: string]: any;
        radius?: number;
        clickable?: boolean;
        cursor?: string;
        draggable?: boolean;
        flat?: boolean;
        icon?:
          | {
              path: any;
              fillColor: string;
              fillOpacity: number;
              scale: number;
              strokeWeight: number;
            }
          | {
              path: any;
              fillColor: string;
              fillOpacity: number;
              scale: number;
              strokeColor: string;
              strokeWeight: number;
            };
        position?: any;
        title?: string;
        zIndex?: number;
        optimized?: boolean;
        strokeColor?: string;
        strokeOpacity?: number;
        fillColor?: string;
        fillOpacity?: number;
        strokeWeight?: number;
      },
      source: { [x: string]: any }
    ) {
      // eslint-disable-next-line no-restricted-syntax
      for (const opt in source) {
        if (GeolocationMarker.DISALLOWED_OPTIONS[opt] !== true) {
          target[opt] = source[opt];
        }
      }
      return target;
    }
  }

  GeolocationMarker.DISALLOWED_OPTIONS = {
    map: true,
    position: true,
    radius: true,
  };

  GeolocationMarker.invalidPropertiesExpr_ = /^(?:position|accuracy)$/i;

  return GeolocationMarker;
};

export default getGeolocationMarkerClass;
