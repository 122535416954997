import { FC, ReactElement } from 'react';
import { IconProps } from '../../../interfaces';

const AddIcon: FC<IconProps> = ({ color }): ReactElement => (
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="26" cy="26" r="26" fill={color || 'var(--darkBlueColor)'} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.5005 17.3333C27.5005 16.5048 26.8289 15.8333 26.0005 15.8333C25.1721 15.8333 24.5005 16.5048 24.5005 17.3333V24.4999H17.334C16.5056 24.4999 15.834 25.1715 15.834 25.9999C15.834 26.8283 16.5056 27.4999 17.334 27.4999H24.5005V34.6666C24.5005 35.495 25.1721 36.1666 26.0005 36.1666C26.8289 36.1666 27.5005 35.495 27.5005 34.6666V27.4999H34.6673C35.4957 27.4999 36.1673 26.8283 36.1673 25.9999C36.1673 25.1715 35.4957 24.4999 34.6673 24.4999H27.5005V17.3333Z"
      fill="white"
    />
  </svg>
);

AddIcon.defaultProps = {
  color: '',
};

export default AddIcon;
