import { ChangeEventHandler } from 'react';
import clsx from 'clsx';
import { ChangeEvent } from 'react';
import { FC, MouseEventHandler, ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { fetchCreateTransaction } from '../../../redux/walletReducer/walletReducer.thunk';
import { SendStageProps } from '../../../types/components.types';
import useShallowEqualSelector from '../../../utils/useShallowEqualSelector';
import Button from '../../common/Button/Button';
import Input from '../../common/Input/Input';
import classes from './SendStage.module.scss';
import { useTranslation } from 'react-i18next';
import useOpenView from '../../../hooks/useOpenView';
import ArrowRightButton from './ArrowRightButton';
import { moneyValueNormalized } from '../../../utils/utils.global';
import Commission from './Commission';
import routes from '../../../pages/Dashboard/routes';

const SendStage: FC<SendStageProps> = ({ sendWallet }): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const actions = useOpenView();

  const [memo, setMemo] = useState<string>('');
  const [address, setAddress] = useState<string>(sendWallet);
  const [amountToSend, setAmountToSend] = useState<number | string>(0);
  const [commission] = useState<string>('0.004 EDC');

  const user = useShallowEqualSelector(state => state.appReducer.user);

  const { balance = 0 } = user || ({} as any);

  const unavailableAmountToSend = parseFloat(balance) < amountToSend;

  const handleChangeMemo: ChangeEventHandler<HTMLInputElement> = (
    event: ChangeEvent<HTMLInputElement>
  ) => setMemo(event.target.value);

  const handleChangeAddress: ChangeEventHandler<HTMLInputElement> = (
    event: ChangeEvent<HTMLInputElement>
  ) => setAddress(event.target.value);

  const handleChangeAmount: ChangeEventHandler<HTMLInputElement> = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = moneyValueNormalized(event.target.value);

    if (value !== null) {
      setAmountToSend(value);
    }
  };

  const handleRedirectToCreateBill = () => {
    actions.open(routes.BILL_PAGE);
  };

  const handleSend: MouseEventHandler = () =>
    dispatch(
      fetchCreateTransaction({
        amount: parseFloat(amountToSend as string),
        address,
        id: uuidv4(),
        memo: memo || null,
      })
    );

  return (
    <div className={classes.content}>
      <div className={classes.balanceBlock}>
        <div className={classes.balanceTitle}>{t('send_balance')}:</div>
        <div
          className={clsx(
            classes.balance,
            unavailableAmountToSend && classes.invalid
          )}
        >
          {balance} EDC
        </div>
      </div>
      <div className={classes.sendValues}>
        <div className={classes.amount}>
          <span className={classes.inputTitle}>{t('send_amount')}</span>
          <Input
            className={classes.input}
            onChange={handleChangeAmount}
            value={amountToSend}
          />
        </div>
        <div className={classes.address}>
          <span className={classes.inputTitle}>{t('send_address')}</span>
          <Input
            className={classes.input}
            onChange={handleChangeAddress}
            value={address}
          />
        </div>
        <div className={classes.memo}>
          <span className={classes.inputTitle}>{t('send_memo')}</span>
          <Input
            className={classes.input}
            onChange={handleChangeMemo}
            value={memo}
          />
        </div>
      </div>
      <Commission>{commission}</Commission>
      <div className={classes.submitButtonWrapper}>
        <Button
          disabled={unavailableAmountToSend || !address || !amountToSend}
          onClick={handleSend}
          className={classes.sendButton}
          type="primary"
        >
          {t('send_sendButton')}
        </Button>
      </div>
      <div className={classes.split} />
      <div className={classes.action}>
        <Button
          className={classes.btn}
          onClick={handleRedirectToCreateBill}
          type="link"
        >
          {t('send_bill')} <ArrowRightButton />
        </Button>
      </div>
    </div>
  );
};

SendStage.defaultProps = {
  sendWallet: '',
};

export default SendStage;
