import { FC, MouseEventHandler } from 'react';
import { FallbackProps } from 'react-error-boundary';
import Button from '../common/Button/Button';
import classes from './ErrorFallback.module.scss';

const ErrorFallback: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const handleHardReset: MouseEventHandler = () => window.location.reload();

  return (
    <div className={classes.container} role="alert">
      <h1 className={classes.title}>App error</h1>
      <div className={classes.errorContainer}>
        <p className={classes.errorMessage}>{error.message}</p>
      </div>
      <Button
        className={classes.resetSubmitButton}
        onClick={resetErrorBoundary}
      >
        Reload
      </Button>
      <Button
        type="default"
        className={classes.hardResetButton}
        onClick={handleHardReset}
      >
        Hard reload
      </Button>
    </div>
  );
};

export default ErrorFallback;
