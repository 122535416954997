import clsx from 'clsx';
import { FC, MouseEventHandler, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import useOpenView from '../../hooks/useOpenView';
import { AuthFormProps } from '../../types/components.types';
import Button from '../common/Button/Button';
import Input from '../common/Input/Input';
import { MENU_ID_MAP } from '../Menu/Menu.constant';
import classes from './AuthForm.module.scss';
import { contentUrl } from '../../config/url.config';
import TextArea from 'antd/lib/input/TextArea';

const AuthForm: FC<AuthFormProps> = ({
  values,
  validation,
  handleChangeRepeatPassword,
  handleSubmitForm,
  handleChangeSeed,
  handleChangePassword,
  disabled,
}): ReactElement => {
  const { t } = useTranslation();
  const actions = useOpenView();

  const handleRedirectToRecovery: MouseEventHandler = () => {
    actions.open(contentUrl(MENU_ID_MAP.RECOVERY));
  };

  return (
    <form name="auth" className={classes.authForm}>
      <div className={classes.formItem}>
        <label htmlFor="email" className={classes.labelForm}>
          {t('regForm_password')}
        </label>
        <Input
          id="password"
          type="password"
          className={clsx(
            classes.authInput,
            validation?.password === false && classes.invalid
          )}
          value={values.password}
          onChange={handleChangePassword}
        />
      </div>
      <div className={classes.formItem}>
        <label htmlFor="repeatPassword" className={classes.labelForm}>
          {t('regForm_repeatPassword')}
        </label>
        <Input
          onChange={handleChangeRepeatPassword}
          autoComplete="new-password"
          id="repeatPassword"
          type="password"
          className={clsx(
            classes.authInput,
            validation?.repeatPassword === false && classes.invalid
          )}
          value={values.repeatPassword}
        />
        <div className={classes.pharseForm}>
          <TextArea
            className={clsx(
              classes.authInput,
              validation?.email === false && classes.invalid
            )}
            rows={5}
            onChange={handleChangeSeed}
            placeholder="Seed phrase"
          />
        </div>
      </div>
      <div className={classes.changePasswordForm}>
        <Button onClick={handleRedirectToRecovery} type="link">
          {t('authForm_forgotPassword')}
        </Button>
      </div>
      <div className={classes.submitForm}>
        <Button
          disabled={disabled}
          className={classes.submit}
          onClick={handleSubmitForm}
          type="primary"
        >
          {t('authForm_authButton')}
        </Button>
      </div>
    </form>
  );
};

export default AuthForm;
