import { JSXElementConstructor, lazy } from 'react';
import { MENU_ID_MAP } from '../Menu/Menu.constant';

const SubscribeContent = lazy(
  () => import('../SubscribeContent/SubscribeContent')
);
const AddContent = lazy(() => import('../AddContent/AddContent'));
const BlockchainContent = lazy(
  () => import('../BlockchainContent/BlockchainContent')
);
const ProfileContent = lazy(() => import('../ProfileContent/ProfileContent'));
const WifiPointChangeContent = lazy(
  () => import('../WifiPointChangeContent/WifiPointChangeContent')
);
const AuthContent = lazy(() => import('../AuthContent/AuthContent'));
const RecoveryContent = lazy(
  () => import('../RecoveryContent/RecoveryContent')
);

export const POPUP_PAGES_NAMES: Readonly<Record<string, string>> = {
  [MENU_ID_MAP.ADD]: 'add_title',
  [MENU_ID_MAP.AUTH]: 'auth_title',
  [MENU_ID_MAP.RECOVERY]: 'recovery_title',
  [MENU_ID_MAP.WALLET]: 'edc_title',
  [MENU_ID_MAP.SUBSCRIBE]: 'afterReg_title',
  [MENU_ID_MAP.WIFI_POINT_ADDED_INFO]: 'add_addName',
  [MENU_ID_MAP.WIFI_CHANGE]: 'change_actionName',
  [MENU_ID_MAP.WIFI_POINT_CHANGED_INFO]: 'change_review',
  [MENU_ID_MAP.REF_PAGE]: 'refPage_title',
  [MENU_ID_MAP.BILL_PAGE]: 'bill_title',
};

export const POPUP_PAGES_REGISTER: Readonly<
  Record<string, JSXElementConstructor<any> | null>
> = {
  [MENU_ID_MAP.ADD]: AddContent,
  [MENU_ID_MAP.AUTH]: AuthContent,
  [MENU_ID_MAP.MAP]: null,
  [MENU_ID_MAP.WALLET]: BlockchainContent,
  [MENU_ID_MAP.POWERBANKS]: null,
  [MENU_ID_MAP.RECOVERY]: RecoveryContent,
  [MENU_ID_MAP.SUBSCRIBE]: SubscribeContent,
  [MENU_ID_MAP.PROFILE]: ProfileContent,
  [MENU_ID_MAP.WIFI_CHANGE]: WifiPointChangeContent,
};
