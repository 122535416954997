import { MapPoint } from '../../interfaces';
import { PointFeature } from 'supercluster';

interface IPropGeo {
  cluster: boolean;
  password?: string;
  username?: string;
  // eslint-disable-next-line camelcase
  point_count?: number;
  id: string;
}

export const toGeoJson = (
  points: MapPoint[]
): Array<PointFeature<IPropGeo>> => {
  if (!points?.length) {
    return [];
  }

  return points.map(point => {
    const geoJson: PointFeature<IPropGeo> = {
      type: 'Feature',
      properties: { cluster: false, password: '', username: '', id: '' },
      geometry: {
        type: 'Point',
        coordinates: [] as number[],
      },
    };

    geoJson.properties = {
      cluster: false,
      password: point.password,
      username: point.username,
      id: point.id,
    };

    geoJson.geometry.coordinates = [point.lng, point.lat];

    return geoJson;
  });
};
