import { BootstrapURLKeys, Coords, MapOptions } from 'google-map-react';

export const googleMapConfigOptions: MapOptions = {
  fullscreenControl: false,
  zoomControl: false,
};

export const bootstrapKeys: BootstrapURLKeys = {
  key: process.env.REACT_APP_GOOGLE_API_KEY || '',
  language: window.navigator.language,
  libraries: ['places', 'geometry', 'drawing'],
};

export const MAP_DEFAULT_COORDS: Readonly<Record<string, Coords>> = {
  CENTER: { lat: -34.397, lng: 150.644 },
};

export const DEFAULT_ZOOM: Readonly<number> = 14;
