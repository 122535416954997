/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-parens */
import { ChainValidation, FetchChain, TransactionBuilder } from "2ab2-js";
import processTransaction from "../utils/processTransaction";

const createBlockchainAccount = async (
  ownerPubKey: string,
  activePubKey: string,
  newAccountName: string,
  registrar = "",
  referrer = "1.2.19",
  referrerPercent?: number
): Promise<any> => {
  const simpleRegister = "1.2.18";

  ChainValidation.required(registrar || simpleRegister, "registrar_id");
  ChainValidation.required(referrer || null, "referrer_id");

  const res = await Promise.all([
    registrar ? FetchChain("getAccount", registrar) : simpleRegister,
    FetchChain("getAccount", referrer),
  ]);

  const [chainRegistrar, chainReferrer] = res;

  const tr = new TransactionBuilder();

  // noinspection SuspiciousTypeOfGuard
  const isStringRegisterValue = typeof chainRegistrar === "string";

  // carnose actor scopate unkey lugsail enserf iodo towny ratbite dyingly revest kelep shumac beira cupric hornily

  fetch("https://auth.2ab2.com/registration", {
    method: "post",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      account: {
        name: newAccountName,
        owner_key: ownerPubKey,
        active_key: activePubKey,
        memo_key: activePubKey,
        referrer: chainReferrer?.get("id") || chainReferrer,
      },
    }),
  }).then((r) => r.json());

  // await tr.add_type_operation("account_create", {
  //   fee: {
  //     amount: 0,
  //     asset_id: "1.3.1",
  //   },
  //   registrar: isStringRegisterValue
  //     ? chainRegistrar
  //     : chainRegistrar?.get("id"),
  //   referrer: chainReferrer?.get("id") || chainReferrer,
  //   referrer_percent: referrerPercent,
  //   name: newAccountName,
  //   owner: {
  //     weight_threshold: 1,
  //     account_auths: [],
  //     key_auths: [[ownerPubKey, 1]],
  //     address_auths: [],
  //   },
  //   active: {
  //     weight_threshold: 1,
  //     account_auths: [],
  //     key_auths: [[activePubKey, 1]],
  //     address_auths: [],
  //   },
  //   options: {
  //     memo_key: activePubKey,
  //     voting_account: "1.2.5",
  //     num_witness: 0,
  //     num_committee: 0,
  //     votes: [],
  //   },
  // });

  return processTransaction(
    tr,
    null // signer_private_keys,
  );
};

export default createBlockchainAccount;
