/* eslint-disable react/jsx-closing-bracket-location */

import { message } from 'antd';
import {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  MouseEventHandler,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { refreshUser } from '../../../redux/appReducer/appReducer.slice';
import useShallowEqualSelector from '../../../utils/useShallowEqualSelector';
import { isValidEmailString } from '../../AuthContent/AuthContent.utils';
import Button from '../../common/Button/Button';
import Input from '../../common/Input/Input';
import classes from './Settings.module.scss';
import { uploadUserAvatar } from '../../../api/AppApi/AppApi.api';
import { contentUrl } from '../../../config/url.config';
import useOpenView from '../../../hooks/useOpenView';
import withAuth from '../../helpers/withAuth';

const Settings: FC = (): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const actions = useOpenView();

  const user = useShallowEqualSelector(state => state.appReducer.user);

  const [isLoadingAvatar, setLoadingAvatar] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [urlAvatar, setUrlAvatar] = useState<string>(
    '/img/settingsAvatar-g.png'
  );

  const [newUsername, setUsername] = useState<string | null>(null);
  const [newEmail, setEmail] = useState<string | null>(null);

  const { username = '', email = '', authorizationMethod = '' } = user || {};

  useEffect(() => {
    if (newUsername === null) {
      setUsername(username);
    }

    if (email && newEmail === null) {
      setEmail(email);
    }
  }, [username, email, newUsername, newEmail]);

  const handleSave: MouseEventHandler = () => {
    if (!newUsername || !newEmail || !isValidEmailString(newEmail)) {
      message.error(t('settings_invalidData'));
      return;
    }

    dispatch(refreshUser({ username: newUsername, email: newEmail }));
    message.success(t('settings_doneData'));
  };

  const handleChangeUsername: ChangeEventHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (!hasChanges) {
      setHasChanges(true);
    }
    setUsername(event.target.value);
  };

  const handleChangeEmail: ChangeEventHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (!hasChanges) {
      setHasChanges(true);
    }

    setEmail(event.target.value);
  };

  const handleOpenChangePassword = () => {
    actions.open(contentUrl('profile/settings/change-password'));
  };

  const handleChangeAvatar: ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    const items = target?.files || [];
    const file = items[0];

    if (!file) {
      return;
    }

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = ({ target: targetLoad }) => {
      const { result } = targetLoad || {};

      if (!result) {
        return;
      }

      setLoadingAvatar(true);

      const datUrlString =
        result instanceof ArrayBuffer
          ? btoa(String.fromCharCode(...new Uint8Array(result)))
          : result;

      uploadUserAvatar(datUrlString)
        .then(uploadResult => {
          if (uploadResult) {
            setUrlAvatar(datUrlString);
          }
        })
        .finally(() => {
          setLoadingAvatar(false);
        });
    };

    reader.onerror = ({ target: targetError }) => {
      setLoadingAvatar(false);
      if (targetError) {
        // eslint-disable-next-line no-console
        console.error(targetError.error);

        return;
      }

      // eslint-disable-next-line no-console
      console.error(targetError);
    };
  };

  const isDisableOauth = authorizationMethod !== 'EMAIL_AUTH';

  return (
    <section className={classes.content}>
      <div className={classes.settings}>
        <label className={classes.header}>
          <div
            role="button"
            aria-pressed="false"
            aria-label="upload image"
            style={{ backgroundImage: `url(${user?.avatar || urlAvatar})` }}
            className={classes.avatar}
          />
          <input
            key={`${isLoadingAvatar}`}
            disabled={isLoadingAvatar}
            aria-hidden="true"
            className={classes.fileInput}
            type="file"
            accept="image/*"
            onChange={handleChangeAvatar}
          />
        </label>
        <div className={classes.userDataBlock}>
          <div className={classes.usernameBlock}>
            <div className={classes.usernameTitle}>
              {t('settings_username')}
            </div>
            <Input
              disabled={isDisableOauth}
              onChange={handleChangeUsername}
              className={classes.input}
              value={newUsername || ''}
            />
          </div>
          <div className={classes.emailBlock}>
            <div className={classes.emailTitle}>{t('regForm_email')}</div>
            <Input
              disabled={isDisableOauth}
              onChange={handleChangeEmail}
              className={classes.input}
              value={newEmail || ''}
            />
          </div>
        </div>
        <div className={classes.submitSave}>
          <Button
            disabled={!hasChanges}
            onClick={handleSave}
            className={classes.saveButton}
            type="primary"
          >
            {t('changeForm_save')}
          </Button>
        </div>
        <div className={classes.changePasswordBlock}>
          <Button
            onClick={handleOpenChangePassword}
            className={classes.changePasswordButton}
            type="default"
          >
            {t('settings_changePassword')}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default withAuth(Settings);
