import { menuItem, menuItemId } from '../../types';

export const MENU_ID_MAP: Readonly<Record<string, menuItemId>> = {
  ADD: 'add',
  MAP: 'map',
  WALLET: 'wallet',
  POWERBANKS: 'powerbanks',
  AUTH: 'auth',
  RECOVERY: 'recovery',
  SUBSCRIBE: 'subscribe',
  WIFI_POINT_ADDED_INFO: 'wifiPointAdded',
  WIFI_POINT_CHANGED_INFO: 'wifiPointChanged',
  PROFILE: 'profile',
  WIFI_CHANGE: 'wifiPointChanges',
  REF_PAGE: 'ref',
  SUB_PAGE: 'sub',
  BILL_PAGE: 'bill',
};

export const menuList: Readonly<Array<menuItem>> = [
  {
    id: MENU_ID_MAP.ADD,
    name: 'menu_add',
    reverseMobile: true,
  },
  {
    id: MENU_ID_MAP.MAP,
    name: 'menu_map',
    option: true,
    optionType: 'default',
  },
  {
    id: MENU_ID_MAP.WALLET,
    name: 'menu_wallet',
  },
  // {
  //   id: MENU_ID_MAP.POWERBANKS,
  //   name: 'Powerbanks аренда',
  //   option: true,
  //   optionType: 'powerbanks',
  // },
  {
    id: MENU_ID_MAP.AUTH,
    includeUserId: MENU_ID_MAP.PROFILE,
    name: 'menu_profile',
    mobileOnly: true,
  },
];

export const MENU_SIZES: Readonly<Record<string, number | string>> = {
  DEFAULT_MENU_WIDTH: 124,
  DEFAULT_MENU_MOBILE_WIDTH: '95%',
};
