import { FC, ReactElement, useState } from 'react';
import {
  MapContextInstance,
  MapContextInstanceAction,
} from './MapContext.context';

const withMapContextInstance = (Component: FC<any>): FC<any> => {
  const ContextComponent: FC<any> = (
    props: Record<string, any>
  ): ReactElement => {
    const [mapInstance, setMapInstance] = useState<null | Record<string, any>>(
      null
    );

    return (
      <MapContextInstance.Provider value={mapInstance}>
        <MapContextInstanceAction.Provider value={setMapInstance}>
          <Component {...props} />
        </MapContextInstanceAction.Provider>
      </MapContextInstance.Provider>
    );
  };

  ContextComponent.displayName = 'MapContextInstance';

  return ContextComponent;
};

export default withMapContextInstance;
