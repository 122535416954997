import { useState, createContext, FC, PropsWithChildren } from 'react';

export type IRouteState<T = any> = Record<string, T>;

export const RouteStateContext = createContext<IRouteState>({});

// eslint-disable-next-line no-unused-vars
type setValueState = (value: IRouteState) => any;

// eslint-disable-next-line no-unused-vars
export const RouteStateActionContext = createContext<setValueState>(() => null);

const RouteState: FC<PropsWithChildren<Record<string, unknown>>> = ({
  children,
}) => {
  const [state, setState] = useState<IRouteState>(() => ({}));

  return (
    <RouteStateContext.Provider value={state}>
      <RouteStateActionContext.Provider value={setState}>
        {children}
      </RouteStateActionContext.Provider>
    </RouteStateContext.Provider>
  );
};

export default RouteState;
