import Immutable from 'immutable';
import { FetchChain, ChainStore } from '2ab2-js';
import { Apis } from '2ab2-ws-js';
import { loadLinkedAccounts } from '../../../../models/IndexedDb/actions';
import addAccountRefs from '../utils/addAccountRefs';

const accountRefsActions = {
  chainStoreUpdate(): void {
    addAccountRefs();
  },
  onLoadDbData: async (): Promise<Immutable.Set<unknown>> => {
    const linkedAccounts = Immutable.Set().asMutable();

    const chainId = Apis.instance().chain_id;

    const data = await loadLinkedAccounts();

    if (data) {
      const filteredData = data.filter(a => {
        if (a.chainId) {
          return a.chainId === chainId;
        }
        return true;
      });

      const accountPromises: Promise<any>[] = filteredData.map(a => {
        linkedAccounts.add(a.name);
        return FetchChain('getAccount', a.name);
      });

      if (accountPromises.length) {
        await Promise.all(accountPromises).catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
      }

      ChainStore.subscribe(accountRefsActions.chainStoreUpdate);
    }

    return linkedAccounts.asMutable();
  },
};

export default accountRefsActions;
