import { FC, MouseEventHandler, ReactElement } from 'react';
import clsx from 'clsx';
import classes from './MenuItem.module.scss';

export type MenuItemProps = {
  children: ReactElement | string;
  icon?: string | null | JSX.Element;
  onClick?: MouseEventHandler;
  isActive: boolean;
};

const MenuItem: FC<MenuItemProps> = ({
  children,
  onClick,
  isActive,
  icon,
}): ReactElement => (
  <li className={clsx(classes.menuItem)}>
    <div
      className={classes.button}
      tabIndex={-1}
      aria-label="open menu item"
      role="menuitem"
      onClick={onClick}
    >
      {icon && icon}
      <span className={clsx(classes.menuName, isActive && classes.active)}>
        {children}
      </span>
    </div>
  </li>
);

MenuItem.defaultProps = {
  onClick: undefined,
  icon: null,
};

export default MenuItem;
