import { FC, ReactElement } from 'react';
import { IconProps } from '../../../interfaces';

const PowerbankIcon: FC<IconProps> = ({ color }): ReactElement => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.4546 10.7356H5.3152C2.93198 10.7356 1 12.6676 1 15.0508V27.5715C1 29.9547 2.93198 31.8867 5.3152 31.8867H34.4546C36.8378 31.8867 38.7698 29.9547 38.7698 27.5715V15.0508C38.7698 12.6676 36.8378 10.7356 34.4546 10.7356Z"
      stroke={color || 'var(--grayColor)'}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M41 17.7888V24.8385"
      stroke={color || 'var(--grayColor)'}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.1774 13.3232C21.3934 13.4098 21.5212 13.6339 21.4856 13.8639L20.5772 19.7334H24.9819C25.1681 19.7334 25.3389 19.8369 25.4251 20.002C25.5113 20.1671 25.4986 20.3664 25.3922 20.5192L19.4066 29.1139C19.2736 29.3049 19.0264 29.3788 18.8104 29.2923C18.5943 29.2058 18.4666 28.9817 18.5021 28.7517L19.4105 22.8821H15.0059C14.8196 22.8821 14.6488 22.7786 14.5626 22.6136C14.4765 22.4485 14.4891 22.2492 14.5956 22.0964L20.5812 13.5017C20.7142 13.3107 20.9613 13.2367 21.1774 13.3232ZM15.9634 21.8821H19.9939C20.1398 21.8821 20.2785 21.9459 20.3735 22.0567C20.4685 22.1676 20.5103 22.3143 20.488 22.4586L19.8209 26.7692L24.0244 20.7334H19.9939C19.8479 20.7334 19.7092 20.6697 19.6142 20.5588C19.5192 20.448 19.4774 20.3012 19.4998 20.157L20.1669 15.8463L15.9634 21.8821Z"
      fill={color || 'var(--grayColor)'}
    />
  </svg>
);

PowerbankIcon.defaultProps = {
  color: '',
};

export default PowerbankIcon;
