import iDB from '../../../../models/IndexedDb/IndexedDb.init';
import Immutable from 'immutable';
import { key } from '2ab2-js';

export type AddressIndexStore = Immutable.Map<unknown, unknown>;

export const addressIndexName = 'AddressIndex';

const addressMemoryState = {
  pubKeys: new Set(),
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const saveAddyMap = (addresses: AddressIndexStore) => {
  if (!iDB.instance) {
    throw new Error('iDB.instance invalid');
  }

  return iDB.instance.setProperty<Record<string, any>>(
    addressIndexName,
    addresses.toObject()
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const loadAddyMap = async () => {
  if (!iDB.instance) {
    throw new Error('iDB.instance invalid');
  }

  const map = await iDB.instance.getProperty<AddressIndexStore>(
    addressIndexName
  );

  const addresses = map ? Immutable.Map(map) : Immutable.Map();

  addresses
    .valueSeq()
    .forEach(pubKey => addressMemoryState.pubKeys.add(pubKey));

  return addresses;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const addPubKeyToAddressesIndex = async (pubKey: string) => {
  let addresses = await loadAddyMap();

  let dirty = false;

  if (addressMemoryState.pubKeys.has(pubKey)) {
    return;
  }

  addressMemoryState.pubKeys.add(pubKey);

  // Gather all 5 legacy address formats (see key.addresses)
  const addressStrings = key.addresses(pubKey);

  // eslint-disable-next-line no-restricted-syntax
  for (const address of addressStrings) {
    addresses = addresses.set(address, pubKey);
    dirty = true;
  }

  if (dirty) {
    await saveAddyMap(addresses);
  }
};
