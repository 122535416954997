import { subId } from '../../types';
import { Subscribe } from '../../interfaces';

export const SUBSCRIBE_TYPES: Readonly<Record<string, subId>> = {
  FREE: 'FREE',
  BASIC: 'BASIC',
  PREMIUM: 'PREMIUM',
};

export const subscriptionsList: Readonly<Array<Readonly<Subscribe>>> = [
  {
    subId: SUBSCRIBE_TYPES.FREE,
    term: 'subItem_termTime',
    description: ['template_create3points', 'template_confirm10points'],
    meta: {
      descriptionColor: '#2F80ED',
    },
  },
  {
    subId: SUBSCRIBE_TYPES.BASIC,
    term: 'subItem_termTime',
    description: ['template_create10points', 'template_confirm100points'],
    meta: {
      descriptionColor: 'var(--lightLightOrangeColor)',
    },
  },
  {
    subId: SUBSCRIBE_TYPES.PREMIUM,
    term: 'subItem_termTime',
    description: ['template_create30points', 'template_confirm300points'],
    meta: {
      descriptionColor: '#9B51E0',
    },
  },
];
