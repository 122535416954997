import {
  FC,
  ForwardedRef,
  MouseEvent,
  ReactElement,
  RefCallback,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Layout } from 'antd';
import Map from '../Map/Map';
import classes from './Content.module.scss';
import PointPopup from '../PointPopup/PointPopup';
import { MapPoint } from '../../interfaces';
import useBreakpoint from '../../hooks/useBreakpoint';
import { MEDIA_QUERIES } from '../../App.constant';
import { useMapInstance } from '../../api/MapContext/MapContext.hooks';
import { Coords, Point as PointType } from 'google-map-react';
import { calculatePopupPosition } from '../Map/Map.utils';
import { throttle } from '../../utils/utils.global';
import ContentControls from '../ContentControls/ContentControls';

const Content: FC = (): ReactElement => {
  const mapInstance = useMapInstance();
  const breakpoints = useBreakpoint(MEDIA_QUERIES);

  const [pointPopupElement, setPointPopupElement] =
    useState<HTMLDivElement | null>(null);

  const [ownPosition, setOwnPosition] = useState<Coords | null>(null);
  const [openedPointsProps, setOpenedPointsProps] = useState<MapPoint | null>(
    null
  );
  const [openedPointId, setOpenedPointId] = useState<string | null>(null);
  const [popupCoords, setPopupCoords] = useState<PointType | null>(null);

  const isVisiblePointPopup = typeof openedPointId === 'string';
  const { isMobile } = breakpoints || {};
  const { x: left, y: top } = popupCoords || {};

  const closePopup = () => {
    setOpenedPointId(null);
    setPopupCoords(null);
  };

  const handleHidePointPopup = () => setOpenedPointId(null);

  const createHandleClickPoint = useCallback(
    (id: string) => (event: MouseEvent) => {
      if (openedPointId) {
        closePopup();
      }

      if (!isMobile) {
        setPopupCoords({ x: event.clientX, y: event.clientY });
      }

      setOpenedPointId(id);

      event.stopPropagation();
    },
    [isMobile, openedPointId]
  );

  const handleChangePopupPosition = useCallback(() => {
    if (!openedPointId || !pointPopupElement || !popupCoords || isMobile) {
      return;
    }

    const newPopupCoords = calculatePopupPosition(
      pointPopupElement,
      popupCoords
    );

    if (
      newPopupCoords.y !== popupCoords.y ||
      newPopupCoords.x !== popupCoords.x
    ) {
      setPopupCoords(newPopupCoords);
    }
  }, [isMobile, openedPointId, pointPopupElement, popupCoords]);

  const throttlerHandleChangePopupPosition = useMemo(
    () => throttle(handleChangePopupPosition, 600),
    [handleChangePopupPosition]
  );

  useEffect(() => {
    window.addEventListener('resize', throttlerHandleChangePopupPosition);

    return () => {
      window.removeEventListener('resize', throttlerHandleChangePopupPosition);
    };
  }, [throttlerHandleChangePopupPosition]);

  useEffect(() => {
    handleChangePopupPosition();
  }, [handleChangePopupPosition]);

  return (
    <Layout.Content className={classes.content}>
      {isVisiblePointPopup && !!openedPointsProps && (
        <Suspense fallback="">
          <PointPopup
            key={openedPointsProps?.id}
            ref={
              setPointPopupElement as ForwardedRef<RefCallback<HTMLDivElement>>
            }
            isMobile={isMobile}
            {...openedPointsProps}
            style={{ left, top }}
            map={mapInstance}
            onHide={handleHidePointPopup}
          />
        </Suspense>
      )}
      <Map
        createHandleClickPoint={createHandleClickPoint}
        openedPointId={openedPointId}
        isVisiblePointPopup={isVisiblePointPopup}
        setOpenedPointsProps={setOpenedPointsProps}
        ownPosition={ownPosition}
        handleHidePointPopup={handleHidePointPopup}
      />
      <ContentControls
        setOwnPosition={setOwnPosition}
        ownPosition={ownPosition}
      />
    </Layout.Content>
  );
};

export default Content;
