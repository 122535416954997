import { useLocation } from 'react-router';

const useQuery = (shouldDecode = false): URLSearchParams | null => {
  const location = useLocation();

  if (location && location?.search) {
    return new URLSearchParams(
      shouldDecode
        ? decodeURIComponent(location.search).slice(1)
        : location.search
    );
  }

  return null;
};

export default useQuery;
