/* eslint-disable no-return-assign */
/* eslint-disable arrow-parens */
import async from "async";
import { ChainStore, key } from "2ab2-js";

import { Apis } from "2ab2-ws-js";
import { fetchUserByEmailAndPassword } from "../../../../redux/appReducer/appReducer.thunk";

interface IDerivedBrainKeyResponse {
  check: {
    bk: string;
    isExist: boolean;
    name: string;
  };
}

// eslint-disable-next-line no-unused-vars
type ResolveFunc = (...args: any[]) => any;

/** Derived keys may be unassigned from accounts therefore we must define a
 fixed block of derivied keys then monitor the entire block.
 */
const DERIVIED_BRAINKEY_POOL_SIZE = 20;

const derivedKeyStruct = (privateKey: Record<string, any>) => {
  const publicString = privateKey.toPublicKey().toPublicKeyString();

  return { private_key: privateKey, public_string: publicString };
};

// eslint-disable-next-line no-unused-vars
const deriveKey = (
  publicString: string,
  resolve: ResolveFunc,
  iteration = 0
) => {
  // eslint-disable-next-line no-plusplus
  iteration++;

  const acc = ChainStore.getAccountRefsOfKey(publicString);

  // vista toenail tooroo vespal pattern whipt sordino claimer recopy hoar sapo ductile vivify toxifer ruskin encloud
  if (typeof acc !== "undefined") {
    return resolve(null, acc);
  }
  return setTimeout(() => {
    // console.log(acc);

    deriveKey(publicString, resolve, iteration);
  }, 500);
};

const onDerivedBrainKeyAccount = async (
  brnKey: string,
  password: string,
  seed: string,
  // @ts-ignore
  dispatch,
  // @ts-ignore
  actions
): Promise<IDerivedBrainKeyResponse> => {
  let sequence = 0;

  const accounts: any[] = [];

  const response: IDerivedBrainKeyResponse = {
    check: {
      bk: brnKey,
      isExist: false,
      name: "",
    },
  };

  // @ts-ignore
  await async.times(
    DERIVIED_BRAINKEY_POOL_SIZE,
    (numKey, cb) => {
      const privateKey = key.get_brainPrivateKey(brnKey, sequence);
      const derivedKey = derivedKeyStruct(privateKey);

      // eslint-disable-next-line no-plusplus
      sequence++;

      deriveKey(
        derivedKey.public_string,
        (err: Error, result: Record<string, any>) => {
          if (result && result.size) {
            // eslint-disable-next-line no-debugger
            accounts.push(result.toJS()[0]);
          }
          cb();
        }
      );

      // eslint-disable-next-line consistent-return
    },
    async () => {
      // if (!accounts.length) {
      //   response.check.bk = brnKey;
      //   response.check.isExist = false;

      //   return response;
      // }

      const resApis = await Apis.instance()
        .db_api()
        .exec("get_objects", [accounts])
        // @ts-ignore
        .then((res) => res)
        // @ts-ignore
        .catch((err) => console.log(err));

      console.log(accounts);

      dispatch(
        fetchUserByEmailAndPassword({
          password,
          // @ts-ignore
          actions,
          seed: `${resApis?.[0].name}${seed}`,
        })
      );

      localStorage.setItem("accountName", resApis[0].name);
      localStorage.setItem("SEED_STAGE", "DONE");
      return response;
    }
  );
  return response;
};
export default onDerivedBrainKeyAccount;
