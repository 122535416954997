import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { IRouteState } from '../components/helpers/routeState';
import { getUrlWithQueryParams } from '../utils/utils.global';
import { useRouteStateDispatch } from './useRouteState';

export interface IOpenViewActions {
  // eslint-disable-next-line no-unused-vars
  open: (path: string, state?: IRouteState, referral?: string) => void;
}

const useOpenView = (): IOpenViewActions => {
  const dispatch = useRouteStateDispatch();
  const history = useHistory();

  const actions: IOpenViewActions = useMemo(
    () => ({
      open: (path: string, state?: IRouteState, referral?: string) => {
        history.push(
          referral ? getUrlWithQueryParams(path, { referral }) : path
        );

        if (state) {
          dispatch(state);

          return;
        }

        dispatch({});
      },
    }),
    [dispatch, history]
  );

  return useMemo(() => actions, [actions]);
};

export default useOpenView;
