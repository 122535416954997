import { FC, ReactElement } from 'react';
import { GooglePlayIconProps } from '../../../types/components.types';
import classes from './GooglePlayIcon.module.scss';

const GooglePlayIcon: FC<GooglePlayIconProps> = ({ onClick }): ReactElement => (
  <div
    role="link"
    tabIndex={-1}
    aria-label="open google play link"
    onClick={onClick}
  >
    <img
      className={classes.icon}
      src="/img/googlePlay.png"
      alt="googlePlay_download"
    />
  </div>
);

export default GooglePlayIcon;
