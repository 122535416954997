import clsx from 'clsx';
import { FC } from 'react';
import Switch from '../common/Switch/Switch';
import classes from './SwitchBlock.module.scss';

interface ISwitchBlockProps {
  type: string;
  title: string;
  checked: boolean;
  name: string;
  // eslint-disable-next-line no-unused-vars
  handleChange: (checked: boolean) => void;
  className?: string;
}

const SwitchBlock: FC<ISwitchBlockProps> = ({
  type,
  name,
  checked,
  handleChange,
  title,
  className,
}) => (
  <div className={clsx(classes.switchBlock, className)}>
    <span className={classes.switchName}>{title}</span>
    <Switch key={type} name={name} checked={!checked} onChange={handleChange} />
  </div>
);

SwitchBlock.defaultProps = {
  className: '',
};

export default SwitchBlock;
