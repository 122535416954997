import { AppStore, ErrorMessage, IPlan, SimpleUser } from '../../interfaces';
import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import {
  fetchCreateUser,
  fetchPlansList,
  fetchUserByEmailAndPassword,
  loadOauthUser,
  logoutUser,
  tryRefreshSession,
} from './appReducer.thunk';
import { fetchCreatePoint } from '../mapReducer/mapReducer.thunk';
import { StoreUser, User } from '../../types';
import { fetchSubOnPlan } from '../walletReducer/walletReducer.thunk';

const initialState: AppStore = {
  user: null,
  userInfoLoaded: false,
  content: null,
  errorType: '',
  errorMessage: '',
  shouldClearSearchString: false,
  plansList: [],
};

const appReducer: Slice<AppStore> = createSlice({
  name: 'appReducer',
  initialState,
  reducers: {
    setClearSearchString: {
      reducer: (state, { payload }: PayloadAction<boolean>) => {
        state.shouldClearSearchString = payload;
      },
      prepare: (value: boolean) => ({ payload: value }),
    },
    setErrorMessage: {
      reducer: (state, { payload }: PayloadAction<ErrorMessage>) => {
        state.errorMessage = payload.message || '';
        state.errorType = payload.errorType || '';
      },
      prepare: (message: string, errorType = '') => ({
        payload: { message, errorType },
      }),
    },
    refreshUser: {
      reducer: (state, { payload }: PayloadAction<User>) => {
        const { user } = state;
        state.user = {
          ...user,
          ...payload,
        };
      },
      prepare: (refreshUserDataFields: User) => ({
        payload: refreshUserDataFields,
      }),
    },
  },
  extraReducers: builder => {
    const loadUserCallback = (
      state: AppStore,
      action: PayloadAction<StoreUser>
    ) => {
      if (!action.payload) {
        return;
      }

      state.user = action.payload;
    };

    const showMessageCallback = (
      state: AppStore,
      action: PayloadAction<any>
    ) => {
      const { message, error } = action.payload || {};

      if (!message && error) {
        state.errorMessage = error;
        state.errorType = 'Error';
        return;
      }

      state.errorMessage = message;
      state.errorType = error;
    };

    builder.addCase(
      logoutUser.fulfilled,
      (state: AppStore, action: PayloadAction<null>) => {
        state.user = action.payload;
      }
    );
    builder.addCase(
      tryRefreshSession.fulfilled,
      (
        state: AppStore,
        action: PayloadAction<(StoreUser | boolean | undefined)[]>
      ) => {
        if (state.user) {
          state.user = {
            ...state.user,
            ...(action.payload[0] as StoreUser),
          };
          return;
        }

        state.user = action.payload[0] as StoreUser;

        state.userInfoLoaded = true;
      }
    );

    builder.addCase(fetchUserByEmailAndPassword.fulfilled, loadUserCallback);

    builder.addCase(loadOauthUser.fulfilled, loadUserCallback);

    builder.addCase(
      loadOauthUser.rejected,
      (state: AppStore, action: PayloadAction<unknown>) => {
        showMessageCallback(state, action);

        state.shouldClearSearchString = true;
      }
    );
    builder.addCase(fetchCreatePoint.rejected, showMessageCallback);
    builder.addCase(fetchUserByEmailAndPassword.rejected, showMessageCallback);
    builder.addCase(fetchCreateUser.rejected, showMessageCallback);
    builder.addCase(fetchSubOnPlan.rejected, showMessageCallback);

    builder.addCase(
      fetchPlansList.fulfilled,
      (state, action: PayloadAction<IPlan[]>) => {
        state.plansList = action.payload;
      }
    );

    builder.addCase(fetchPlansList.rejected, state => {
      state.plansList = null;
    });

    builder.addCase(
      fetchCreateUser.fulfilled,
      (state, action: PayloadAction<SimpleUser>) => {
        if (!action.payload) {
          return;
        }

        if (action.payload?.referral) {
          state.shouldClearSearchString = true;
        }

        state.user = action.payload;
      }
    );
  },
});

export const { refreshUser, setErrorMessage, setClearSearchString } =
  appReducer.actions;

export default appReducer.reducer;
