import { FC, ReactElement } from 'react';

const LockIcon: FC = (): ReactElement => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8 11.1264C11.8 8.9889 12.3915 7.36331 13.3972 6.2804C14.393 5.2081 15.9061 4.54785 18.0033 4.54785C20.1015 4.54785 21.6126 5.20673 22.6063 6.27683C23.6099 7.35763 24.2 8.98129 24.2 11.1197V14.5999H11.8V11.1264ZM9.79998 14.5999V11.1264C9.79998 8.63124 10.4939 6.46758 11.9317 4.91938C13.3794 3.36057 15.468 2.54785 18.0033 2.54785C20.5383 2.54785 22.6255 3.35835 24.0719 4.91591C25.5083 6.46277 26.2 8.62505 26.2 11.1197V14.5999H29.5C30.3284 14.5999 31 15.2714 31 16.0999V31.8999C31 32.7283 30.3284 33.3999 29.5 33.3999H6.5C5.67157 33.3999 5 32.7283 5 31.8999V16.0999C5 15.2714 5.67157 14.5999 6.5 14.5999H9.79998ZM7 31.3999V16.5999H29V31.3999H7Z"
      fill="var(--lightBlue)"
    />
  </svg>
);

export default LockIcon;
