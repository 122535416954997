/* eslint-disable @typescript-eslint/no-unused-vars */
import { Moment } from 'moment';
import { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BillInput from '../BillInput/BillInput';
import SelectTime from './SelectTime/SelectTime';

interface ITimeStepInputProps {
  disabled?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: Moment | null) => void;
  className?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  showTime?: boolean;
}

const TimeStepInput: FC<ITimeStepInputProps> = ({
  disabled,
  // eslint-disable-next-line no-unused-vars
  onChange,
  className,
}): ReactElement => {
  const { t } = useTranslation();
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);

  const handleChangeStep = (h: number, m: number) => {
    let hourValue = h;
    let minuteValue = m;

    if (Number.isNaN(h) || Number.isNaN(m)) {
      return;
    }

    if (minuteValue > 60) {
      minuteValue = 0;
      hourValue += 1;
    }

    setHours(hourValue);
    setMinutes(minuteValue);
  };

  const handleSelect = (value: number) => {
    if (value < 60) {
      setHours(0);
      setMinutes(value);

      return;
    }

    const [hour = 0, minute = 0] = (value / 60).toString().split('.');

    setHours(Number(hour));
    setMinutes(Number(minute));
  };

  return (
    <>
      <div>
        <BillInput
          prefix={t('bill_in')}
          disabled={disabled}
          className={className}
          h={hours}
          onChangeStep={handleChangeStep}
          m={minutes}
          type="step"
        />
      </div>
      <SelectTime disabled={disabled} onSelect={handleSelect} />
    </>
  );
};

export default TimeStepInput;
