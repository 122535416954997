/** Discover derived keys that are not in this wallet */
import getWalletStore from './getWalletStore';
import walletStore from '../walletStore/wallet';
import { generateNextKey } from './generateNextKey';
import { ChainStore } from '2ab2-js';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const checkNextGeneratedKey = async () => {
  const wallet = await getWalletStore();

  if (!wallet) {
    return;
  }

  if (!walletStore.aesPrivate) {
    return;
  }

  if (!wallet.encrypted_brainkey) {
    return;
  }

  if (walletStore.chainstoreAccountIdsByKey === ChainStore.account_ids_by_key)
    return; // no change
  walletStore.chainstoreAccountIdsByKey = ChainStore.account_ids_by_key;
  // Helps to ensure we are looking at an un-used key
  try {
    await generateNextKey(false);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export default checkNextGeneratedKey;
