import {
  AuthValues,
  IPlan,
  OauthLoadValue,
  SimpleUser,
  UserCreator,
} from "../../interfaces";
import {
  createUser,
  fetchPlansApi,
  fetchRefreshUser,
  loadFacebookUser,
  loadGoogleUser,
  loadUser,
} from "../../api/AppApi/AppApi.api";
import { StoreUser, User } from "../../types";
import { AsyncThunkPayloadCreator } from "@reduxjs/toolkit";
import { GoogleLoginResponse } from "react-google-login";
import { OAUTH_ID } from "../../components/AuthContent/AuthContent.constant";
import Request from "../../models/Request/Request";
import { MENU_ID_MAP } from "../../components/Menu/Menu.constant";
import { contentUrl } from "../../config/url.config";

export const fetchUserByEmailAndPasswordThunkCreator: AsyncThunkPayloadCreator<
  User,
  Exclude<AuthValues, "repeatPassword">
> = async (values: Exclude<AuthValues, "repeatPassword">, thunkApi) => {
  const [data, isError] = await loadUser(values.seed, values.password);

  if (isError) {
    return thunkApi.rejectWithValue(data);
  }

  if (values.actions) {
    values.actions.open("content/profile");
    // TODO: Should be fix in feature
    // @ts-ignore
    values.actions.open(contentUrl(MENU_ID_MAP.PROFILE));
  }

  return data as User;
};

export const fetchCreateUserThunkCreator: AsyncThunkPayloadCreator<
  User,
  UserCreator
> = async (values: UserCreator, thunkApi) => {
  const [data, isError] = await createUser(values);

  if (isError) {
    return thunkApi.rejectWithValue(data);
  }

  return data as User;
};

export const loadOauthUserThunkCreator: AsyncThunkPayloadCreator<
  StoreUser,
  OauthLoadValue
> = async (oauthValue: OauthLoadValue, thunkApi) => {
  const { accessToken = "", profileObj } =
    oauthValue.values as GoogleLoginResponse;

  if (accessToken && profileObj && oauthValue.type === OAUTH_ID.GOOGLE) {
    const [user, isError] = await loadGoogleUser(
      oauthValue.values as GoogleLoginResponse
    );

    if (isError) {
      return thunkApi.rejectWithValue(user);
    }

    return user as StoreUser;
  }

  if (oauthValue.type === OAUTH_ID.FACEBOOK) {
    const [user, isError] = await loadFacebookUser(oauthValue.values as string);

    if (isError) {
      return thunkApi.rejectWithValue(user);
    }

    return user as StoreUser;
  }

  return thunkApi.rejectWithValue(oauthValue.values);
};

export const tryRefreshSessionThunkCreator: AsyncThunkPayloadCreator<
  [SimpleUser, boolean | undefined],
  boolean | undefined
> = async (isInterval: boolean | undefined, thunkApi) => {
  const result = await fetchRefreshUser();

  if (result) {
    return [result, isInterval];
  }

  return thunkApi.rejectWithValue(result);
};

export const logoutUserThunkCreator: AsyncThunkPayloadCreator<null> =
  async () => {
    Request.logout();
    return null;
  };

export const fetchPlansListThunkCreator: AsyncThunkPayloadCreator<IPlan[]> =
  async (_, thunkApi) => {
    const result = await fetchPlansApi();

    if (result) {
      return result;
    }

    return thunkApi.rejectWithValue(result);
  };
