import { CustomRequestError } from '../../interfaces';
import { requestResponseType } from '../../types';

class RequestError extends Error implements CustomRequestError {
  private requestResponse: requestResponseType;

  constructor(message: string, errorResponse: requestResponseType) {
    super(message);
    this.requestResponse = errorResponse;
  }

  getErrorResponse(): requestResponseType {
    return this.requestResponse;
  }

  setErrorResponse(response: requestResponseType): void {
    this.requestResponse = response;
  }
}

export default RequestError;
