import { ReactElement, useState } from 'react';
import { moneyValueNormalized } from '../../utils/utils.global';
import classes from './BillContent.module.scss';
import Button from '../common/Button/Button';
import BillInput from './BillInput/BillInput';
import { useTranslation } from 'react-i18next';
import { Moment } from 'moment';
import Commission from '../BlockchainContent/SendStage/Commission';
import TimeSwitch from './TimeSwitch/TimeSwitch';
import SwitchBlock from '../SwitchBlock/SwitchBlock';
import useOpenView from '../../hooks/useOpenView';
import routes from '../../pages/Dashboard/routes';

const BillContent = (): ReactElement => {
  const { t } = useTranslation();

  const actions = useOpenView();
  const [isMultipleAvailable, setMultiple] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
  const [ab2, setAb2] = useState<string>('');

  const handleChangeAb2 = (ab2Value: string) => {
    const value = moneyValueNormalized(ab2Value);

    if (value !== null) {
      setAb2(value);
    }
  };

  const handleCreateBill = () => {
    // eslint-disable-next-line no-console
    console.log('handleCreateBill');

    actions.open(routes.BILL_CODE);
  };

  return (
    <section className={classes.content}>
      <div className={classes.formSection}>
        <div>
          <BillInput
            title="AB2"
            value={ab2}
            placeholder="0.000"
            onChangeText={handleChangeAb2}
            suffix={<span className={classes.sufix}>{t('bill_max')}</span>}
          />
          <Commission className={classes.commissionText}>0.000 AB2</Commission>
        </div>
        <BillInput
          type="date"
          title="Valid from"
          dateValue={selectedDate}
          placeholder="09.12.21"
          onChangeDate={setSelectedDate}
          showTime
        />
      </div>
      <div className={classes.switchContent}>
        <p className={classes.end}>{t('bill_end')}</p>
        <TimeSwitch />
      </div>
      <SwitchBlock
        className={classes.switchMultiple}
        type="switchMultiple"
        name="multiple"
        checked={isMultipleAvailable}
        handleChange={setMultiple}
        title={t('bill_reusableBill')}
      />
      {isMultipleAvailable && (
        <div className={classes.section}>
          <BillInput
            className={classes.users}
            title={t('bill_users')}
            value={ab2}
            placeholder=""
            onChangeText={handleChangeAb2}
          />
        </div>
      )}
      {!!ab2 && (
        <div className={classes.containerSum}>
          <div className={classes.sumTitle}>Общая сумма:</div>
          <div>
            <span className={classes.sum}>{ab2}</span>
            <Commission className={classes.commissionTextSum}>
              0.000 AB2
            </Commission>
          </div>
        </div>
      )}
      <div className={classes.submitButtonWrapper}>
        <Button
          onClick={handleCreateBill}
          className={classes.btn}
          type="primary"
        >
          {t('bill_submitText')}
        </Button>
      </div>
    </section>
  );
};

export default BillContent;
