import { FC, ReactElement, useState } from 'react';
import { MapContextApi, MapContextApiAction } from './MapContext.context';

const withMapContext = (Component: FC<any>): FC<any> => {
  const ContextComponent: FC<any> = (
    props: Record<string, any>
  ): ReactElement => {
    const [mapApi, setMapApi] = useState<null | Record<string, any>>(null);

    return (
      <MapContextApi.Provider value={mapApi}>
        <MapContextApiAction.Provider value={setMapApi}>
          <Component {...props} />
        </MapContextApiAction.Provider>
      </MapContextApi.Provider>
    );
  };

  ContextComponent.displayName = 'MapContextApi';

  return ContextComponent;
};

export default withMapContext;
