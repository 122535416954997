import iDB from '../../../../models/IndexedDb/IndexedDb.init';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const tryToSetCurrentAccount = async () => {
  if (!iDB.instance) {
    throw new Error('iDB.instance error');
  }

  const transaction = iDB.instance.getTransaction(
    ['linked_accounts'],
    'readwrite'
  );

  const linkedAccounts = transaction.objectStore('linked_accounts');

  const accounts = await linkedAccounts.getAll();

  if (accounts.length) {
    // eslint-disable-next-line no-console
    console.log('linked_accounts');
  }
};

export default tryToSetCurrentAccount;
