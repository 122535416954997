import {
  FC,
  MouseEventHandler,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { mapMenuType, menuItem, menuItemId } from '../../types';
import { MENU_ID_MAP, MENU_SIZES, menuList } from './Menu.constant';
import MenuItem from './MenuItem/MenuItem';
import { useDispatch } from 'react-redux';
import { ICONS_REGISTER } from '../Icons/Icons.constant';
import { setMapType } from '../../redux/mapReducer/mapReducer.slice';
import Sider from '../Layout/Sider/Sider';
import AppStoreIcon from '../Icons/AppStoreIcon/AppStoreIcon';
import GooglePlayIcon from '../Icons/GooglePlayIcon/GooglePlayIcon';
import useShallowEqualSelector from '../../utils/useShallowEqualSelector';
import useBreakpoint from '../../hooks/useBreakpoint';
import Logo from '../Icons/Logo/Logo';
import { MEDIA_QUERIES } from '../../App.constant';
import { MenuProps } from '../../types/components.types';
import Language from '../Language/Language';
import { useTranslation } from 'react-i18next';
import classes from './Menu.module.scss';
import Avatar from '../Avatar/Avatar';
import useOpenView from '../../hooks/useOpenView';
import { contentUrl } from '../../config/url.config';

const Menu: FC<MenuProps> = ({
  activeMenuId,
  customMenuList,
  hasLangSwitcher,
}): ReactElement => {
  const actions = useOpenView();
  const { t } = useTranslation();
  const matchBreakpoints = useBreakpoint(MEDIA_QUERIES);
  const dispatch = useDispatch();

  const [menuItems, setMenuItems] = useState<Readonly<Array<menuItem>> | null>(
    null
  );
  const [siderWidth, setSiderWidth] = useState<string | number>(124);

  const { isMobile } = matchBreakpoints || {};

  const selectorValues = useShallowEqualSelector(state => {
    const { appReducer, mapReducer } = state;

    return {
      mapType: mapReducer.mapType,
      user: appReducer.user,
    };
  });

  const { user, mapType } = selectorValues;

  useEffect(() => {
    if (menuItems) {
      return;
    }

    if (customMenuList) {
      setMenuItems(customMenuList);

      return;
    }

    setMenuItems(menuList);
  }, [customMenuList, menuItems]);

  useEffect(() => {
    const newSiderWidth = isMobile
      ? MENU_SIZES.DEFULAT_MENU_MOBILE_WIDTH
      : MENU_SIZES.DEFAULT_MENU_WIDTH;

    if (matchBreakpoints !== null && newSiderWidth !== siderWidth) {
      setSiderWidth(newSiderWidth);
    }
  }, [matchBreakpoints, isMobile, siderWidth]);

  const handleOpen = useCallback(
    (id: menuItemId) => {
      if (!id) {
        return;
      }

      actions.open(contentUrl(id));
    },
    [actions]
  );

  const handleApplyOption = useCallback(
    (type: mapMenuType) => {
      dispatch(setMapType(type));
      actions.open('');
    },
    [dispatch, actions]
  );

  const createHandleApplyOption = useCallback(
    (type: mapMenuType): MouseEventHandler =>
      () =>
        handleApplyOption(type),
    [handleApplyOption]
  );

  const createHandleOpenMenuItem = useCallback(
    (id: menuItemId): MouseEventHandler =>
      () =>
        handleOpen(id),
    [handleOpen]
  );

  const normalizedMenuList = useMemo(() => {
    if (!menuItems) {
      return null;
    }

    if (!isMobile) {
      return menuItems;
    }

    return [...menuItems].sort((_, b) => {
      if (b.reverseMobile) {
        return -1;
      }

      return 1;
    });
  }, [isMobile, menuItems]);

  const menuItemsList = useMemo(() => {
    if (!normalizedMenuList) {
      return null;
    }

    return normalizedMenuList.map((item: menuItem) => {
      if (!isMobile && item.mobileOnly) {
        return null;
      }

      let contentId = item.id;

      if (item.includeUserId && user !== null) {
        contentId = item.includeUserId;
      }

      const Icon = ICONS_REGISTER?.[item.id];
      const isActive =
        activeMenuId === contentId || mapType === item?.optionType;

      const onClick =
        item.option && item.optionType
          ? createHandleApplyOption(item.optionType)
          : createHandleOpenMenuItem(contentId);

      return (
        <MenuItem
          onClick={item.id !== MENU_ID_MAP.MAP ? onClick : undefined}
          key={item.id}
          icon={
            Icon ? (
              <Icon color={isActive ? 'var(--lightBrownColor)' : null} />
            ) : null
          }
          isActive={isActive}
        >
          {t(item.name)}
        </MenuItem>
      );
    });
  }, [
    user,
    isMobile,
    normalizedMenuList,
    createHandleOpenMenuItem,
    activeMenuId,
    createHandleApplyOption,
    mapType,
    t,
  ]);

  const handleOpenMenu = createHandleOpenMenuItem(
    user === null ? MENU_ID_MAP.AUTH : MENU_ID_MAP.PROFILE
  );

  const appLinks = (
    <div className={classes.downloadLinks}>
      <AppStoreIcon />
      <GooglePlayIcon />
    </div>
  );

  return (
    <Sider
      classNameContent={classes.menuMobile}
      className={classes.menu}
      width={siderWidth}
    >
      <div className={classes.logoContainer}>
        <p className={classes.logo}>
          <Logo />
        </p>
      </div>
      <div className={classes.menuContainer}>
        <menu className={classes.menuList}>{menuItemsList}</menu>
        {!isMobile && (
          <div className={classes.menuFooter}>
            <Avatar
              className={classes.avatar}
              user={user}
              onClick={handleOpenMenu}
              isActive={
                activeMenuId === MENU_ID_MAP.PROFILE ||
                activeMenuId === MENU_ID_MAP.AUTH
              }
            />
            {hasLangSwitcher && <Language />}
            {appLinks}
          </div>
        )}
      </div>
      {isMobile && <>{appLinks}</>}
    </Sider>
  );
};

Menu.defaultProps = {
  hasLangSwitcher: true,
};

export default Menu;
