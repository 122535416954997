// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
import { IDBPTransaction } from 'idb';
import transactionUpdateKeys from './transactionUpdateKeys';
import { ChainConfig } from '2ab2-ws-js';
import ManageDatabase from '../../../../models/IndexedDb/IndexedDb.utils';
import walletStore from '../walletStore/wallet';
import t from 'tcomb';
import { addPubKeyToAddressesIndex } from '../actions/onAddressIndex';

const PrivateKeyTcomb = t.struct(
  {
    id: t.maybe(t.Number),
    pubkey: t.String,
    label: t.maybe(t.String),
    import_account_names: t.maybe(t.Array),
    brainkey_sequence: t.maybe(t.Number),
    encrypted_key: t.String,
  },
  'PrivateKeyTcomb'
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const saveKey = async (
  privateKey: Record<string, any>,
  brainkeySequence: number,
  importAccountNames?: string | null,
  publicKeyStringArg = '',
  transaction = transactionUpdateKeys()
) => {
  let publicKeyString = publicKeyStringArg;

  const privateCipherhex = !walletStore.aesPrivate
    ? null
    : walletStore.aesPrivate.encryptToHex(privateKey.toBuffer());

  if (!publicKeyString) {
    const publicKey = privateKey.toPublicKey();
    publicKeyString = publicKey.toPublicKeyString();
    // eslint-disable-next-line eqeqeq
  } else if (publicKeyString.indexOf(ChainConfig.address_prefix) != 0)
    throw new Error(
      'Public Key should start with ' + ChainConfig.address_prefix
    );

  // const wallet = await this.getStore();

  const privateKeyObject = {
    import_account_names: importAccountNames,
    encrypted_key: privateCipherhex,
    pubkey: publicKeyString,
    brainkey_sequence: brainkeySequence,
  };

  await addPubKeyToAddressesIndex(privateKeyObject.pubkey);

  let duplicate = false;

  try {
    PrivateKeyTcomb(privateKeyObject);

    PrivateKeyTcomb(privateKeyObject);

    await ManageDatabase.add(
      transaction.objectStore('private_keys'),
      privateKeyObject
    );

    if (duplicate) {
      return { result: 'duplicate', id: null };
    }

    // eslint-disable-next-line eqeqeq
    if (privateKeyObject.brainkey_sequence == null) {
      // eslint-disable-next-line no-console
      console.log('binaryBackupRecommended');
      // this.binaryBackupRecommended() // non-deterministic
    }

    return {
      result: 'added',
      privateKeyObject,
    };
  } catch (event) {
    // ignore_duplicates
    // @ts-ignore

    // @ts-ignore
    const { error } = event?.target || {};

    // eslint-disable-next-line no-console
    console.log('... error', error, event);

    // eslint-disable-next-line eqeqeq
    if (
      error &&
      (error?.name !== 'ConstraintError' ||
        error?.message.indexOf('by_encrypted_key') === -1)
    ) {
      throw event;
    }

    duplicate = true;
  }

  return {
    result: 'added',
    privateKeyObject,
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const saveKeys = (
  privateKeys: Record<string, any>[],
  transaction: IDBPTransaction<any>,
  publicKeyString = ''
) => {
  const promises = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const privatKeyRecord of privateKeys) {
    promises.push(
      saveKey(
        privatKeyRecord.private_key,
        privatKeyRecord.sequence,
        null,
        publicKeyString,
        transaction as unknown as IDBPTransaction
      )
    );
  }
  return Promise.all(promises);
};
