import iDB from '../../../../models/IndexedDb/IndexedDb.init';
import ManageDatabase from '../../../../models/IndexedDb/IndexedDb.utils';
import { WalletTcomb } from '../../../../lib/store/tcombStructure';

const loadWalletDbData = async (): Promise<any> => {
  if (!iDB.instance) {
    throw new Error('iDB.instance invalid');
  }

  const db = iDB.instance.getDatabase();

  if (!db) {
    throw new Error('iDB.instance db invalid');
  }

  let walletStoreValue;

  let cursorStore = await ManageDatabase.cursor(db, 'wallet');

  while (cursorStore) {
    walletStoreValue = cursorStore.value;

    walletStoreValue.created = new Date(walletStoreValue.created);
    walletStoreValue.last_modified = new Date(walletStoreValue.last_modified);
    walletStoreValue.backup_date = walletStoreValue.backup_date
      ? new Date(walletStoreValue.backup_date)
      : null;
    walletStoreValue.brainkey_backup_date =
      walletStoreValue.brainkey_backup_date
        ? new Date(walletStoreValue.brainkey_backup_date)
        : null;
    try {
      WalletTcomb(walletStoreValue);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('WalletDb format error', e);
    }

    // eslint-disable-next-line no-await-in-loop
    cursorStore = await cursorStore.continue();
  }

  return walletStoreValue;
};

export default loadWalletDbData;
