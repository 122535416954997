import ManageDatabase from '../../../../models/IndexedDb/IndexedDb.utils';
import iDB from '../../../../models/IndexedDb/IndexedDb.init';
import { PrivateKeyTcomb } from '../../../../lib/store/tcombStructure';
import Immutable from 'immutable';
import { addPubKeyToAddressesIndex } from './onAddressIndex';

const privateKeyActions = {
  onLoadDbData: async (
    name = 'private_keys'
  ): Promise<Immutable.Map<unknown, unknown>> => {
    if (!iDB.instance) {
      throw new Error('db none');
    }

    const keys = Immutable.Map().asMutable();

    const db = iDB.instance.getDatabase();

    if (!db) {
      throw new Error('indexed db none');
    }

    let cursorStore = await ManageDatabase.cursor(db, name);

    if (!cursorStore) {
      return keys;
    }

    while (cursorStore) {
      // eslint-disable-next-line no-console
      console.log(cursorStore.key, cursorStore.value);

      const privateKeyTcomb = PrivateKeyTcomb(cursorStore.value);

      keys.set(privateKeyTcomb.pubkey, privateKeyTcomb);

      // eslint-disable-next-line no-await-in-loop
      await addPubKeyToAddressesIndex(privateKeyTcomb.pubkey);

      // eslint-disable-next-line no-await-in-loop
      cursorStore = await cursorStore.continue();
    }

    return keys;
  },
};

export default privateKeyActions;
