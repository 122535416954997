import { FC, ReactElement } from 'react';

interface Props {
  type?: 'default' | 'refPerson';
}

const PersonIcon: FC<Props> = ({ type }): ReactElement => {
  if (type === 'refPerson') {
    return (
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.9999 2.23999C13.2724 2.23999 9.43994 6.07243 9.43994 10.8C9.43994 14.9933 12.4551 18.4823 16.4356 19.2174C13.2328 19.4595 10.5082 20.4765 8.50798 22.4477C6.18659 24.7355 5 28.1516 5 32.6399C5 33.1922 5.44772 33.6399 6 33.6399C6.55228 33.6399 7 33.1922 7 32.6399C7 28.4883 8.09333 25.6644 9.91185 23.8722C11.7333 22.0771 14.4415 21.16 17.9998 21.16C21.5582 21.16 24.2664 22.0771 26.088 23.8722C27.9066 25.6644 28.9999 28.4883 28.9999 32.6399C28.9998 33.1921 29.4476 33.6399 29.9998 33.6399C30.5521 33.6399 30.9998 33.1922 30.9999 32.6399C30.9999 28.1516 29.8133 24.7355 27.4918 22.4477C25.4916 20.4765 22.767 19.4595 19.5642 19.2174C23.5448 18.4823 26.5599 14.9933 26.5599 10.8C26.5599 6.07243 22.7275 2.23999 17.9999 2.23999ZM11.4399 10.8C11.4399 7.177 14.377 4.23999 17.9999 4.23999C21.6229 4.23999 24.5599 7.177 24.5599 10.8C24.5599 14.423 21.6229 17.36 17.9999 17.36C14.377 17.36 11.4399 14.423 11.4399 10.8Z"
          fill="var(--lightBlue)"
        />
      </svg>
    );
  }

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1.32495C7.4181 1.32495 5.32503 3.41802 5.32503 5.99995C5.32503 8.58188 7.4181 10.675 10 10.675C12.582 10.675 14.675 8.58188 14.675 5.99995C14.675 3.41802 12.582 1.32495 10 1.32495ZM6.27503 5.99995C6.27503 3.94269 7.94277 2.27495 10 2.27495C12.0573 2.27495 13.725 3.94269 13.725 5.99995C13.725 8.05721 12.0573 9.72495 10 9.72495C7.94277 9.72495 6.27503 8.05721 6.27503 5.99995ZM3.8084 18.1332C3.8084 15.8132 4.41956 14.2205 5.4501 13.2049C6.48205 12.1879 8.01009 11.6749 9.99997 11.6749C11.9899 11.6749 13.5179 12.1879 14.5499 13.2049C15.5805 14.2205 16.1917 15.8132 16.1917 18.1332C16.1916 18.3955 16.4043 18.6082 16.6666 18.6082C16.929 18.6082 17.1416 18.3956 17.1417 18.1332C17.1417 15.6533 16.4862 13.7793 15.2168 12.5283C13.9487 11.2786 12.1434 10.7249 9.99997 10.7249C7.85652 10.7249 6.05127 11.2786 4.78327 12.5283C3.51386 13.7793 2.8584 15.6533 2.8584 18.1332C2.8584 18.3955 3.07106 18.6082 3.3334 18.6082C3.59573 18.6082 3.8084 18.3955 3.8084 18.1332Z"
        fill="var(--lightBrownColor)"
      />
    </svg>
  );
};

PersonIcon.defaultProps = {
  type: 'default',
};

export default PersonIcon;
