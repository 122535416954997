import { Point } from 'google-map-react';

export const calculatePopupPosition = (
  pointPopupElement: HTMLDivElement,
  popupCoords: Point
): Point => {
  const newPopupCoords: Point = { x: 0, y: 0 };

  if (!pointPopupElement) {
    return newPopupCoords;
  }

  const paddingFromBorder = 10;
  const totalPopupHeight = popupCoords.y + pointPopupElement.clientHeight;

  const isMoreDownWindowBorder = totalPopupHeight > window.innerHeight;
  const isMoreLeftWindowBorder =
    popupCoords.x - pointPopupElement.clientWidth < 0;
  const isMoreRightWindowBorder =
    popupCoords.x + pointPopupElement.clientWidth > window.innerWidth;

  newPopupCoords.x = popupCoords.x;
  newPopupCoords.y = popupCoords.y;

  if (isMoreDownWindowBorder) {
    newPopupCoords.y = popupCoords.y - pointPopupElement.clientHeight;
  }

  if (newPopupCoords.y < 0) {
    newPopupCoords.y = paddingFromBorder;
  }

  if (isMoreLeftWindowBorder) {
    newPopupCoords.x = paddingFromBorder;
  }

  if (isMoreRightWindowBorder) {
    newPopupCoords.x =
      window.innerWidth - pointPopupElement.clientWidth - paddingFromBorder;
  }

  return newPopupCoords;
};
