import { DatePicker, Input, TimePicker } from 'antd';
import clsx from 'clsx';
import { Moment } from 'moment';
import { FC, ReactElement, ChangeEventHandler, ReactNode } from 'react';
import classes from './BillInput.module.scss';

interface IBillInput {
  // eslint-disable-next-line no-unused-vars
  onChangeText?: (value: string) => void;
  // eslint-disable-next-line no-unused-vars
  onChangeDate?: (value: Moment | null) => void;
  // eslint-disable-next-line no-unused-vars
  onChangeStep?: (h: number, m: number) => void;
  title?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
  placeholder?: string;
  type?: 'date' | 'default' | 'time' | 'step';
  className?: string;
  disabled?: boolean;
  dateValue?: Moment | null;
  value?: string;
  showTime?: boolean;
  h?: number;
  m?: number;
}

const BillInput: FC<IBillInput> = ({
  value,
  onChangeText,
  onChangeStep,
  title,
  prefix,
  h,
  m,
  suffix,
  placeholder,
  type,
  onChangeDate,
  className,
  disabled,
  showTime,
  dateValue: dateValueProp,
}): ReactElement => {
  const isDate = type === 'date';
  const isDefault = type === 'default';
  const isTime = type === 'time';
  const isStep = type === 'step';

  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    if (onChangeText) {
      onChangeText(event.target.value);
    }
  };

  const handleStepChange: ChangeEventHandler<HTMLInputElement> = event => {
    const contentValue = event.target.value.trim();

    if (!contentValue.includes('ч') || !contentValue.includes('мин')) {
      return;
    }

    if (!contentValue.endsWith('мин')) {
      return;
    }

    const [hours, minutesString] = contentValue.split('ч');

    if (onChangeStep) {
      onChangeStep(Number(hours), parseFloat(minutesString));
    }
  };

  const handleChangeDate = (dateValue: Moment | null) => {
    if (onChangeText) {
      onChangeText(dateValue?.format('DD.MM.YYYY') || '');
    }

    if (onChangeDate) {
      onChangeDate(dateValue);
    }
  };

  let component = null;

  if (isDate) {
    component = (
      <DatePicker
        className={clsx(classes.input, className)}
        suffixIcon={suffix}
        disabled={disabled}
        placeholder={placeholder}
        onChange={handleChangeDate}
        value={dateValueProp}
        showTime={showTime}
      />
    );
  }

  if (isTime) {
    component = (
      <TimePicker
        className={clsx(classes.input, className)}
        suffixIcon={suffix}
        disabled={disabled}
        placeholder={placeholder || (prefix as string)}
        onChange={handleChangeDate}
        value={dateValueProp}
      />
    );
  }

  if (isDefault || isStep) {
    component = (
      <Input
        className={clsx(classes.input, className)}
        prefix={prefix}
        suffix={suffix}
        disabled={disabled}
        value={isStep ? `${h} ч ${m} мин` : value}
        placeholder={placeholder}
        onChange={isStep ? handleStepChange : handleChange}
      />
    );
  }

  return (
    <div className={classes.root}>
      {!!title && (
        <div className={classes.titleRoot}>
          <span className={classes.title}>{title}</span>
        </div>
      )}
      {component}
    </div>
  );
};

BillInput.defaultProps = {
  type: 'default',
  title: '',
  prefix: undefined,
  suffix: undefined,
  placeholder: undefined,
  className: '',
  disabled: false,
  dateValue: null,
  value: '',
  showTime: false,
};

export default BillInput;
