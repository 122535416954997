import { FC, ReactElement } from 'react';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import { SwitcherProps } from '../../../types/components.types';

const Switch: FC<SwitcherProps> = (props): ReactElement | null => {
  const { switcherType } = props;
  if (switcherType !== 'default') {
    return null;
  }

  return <ToggleSwitch {...props} />;
};

Switch.defaultProps = {
  switcherType: 'default',
};

export default Switch;
