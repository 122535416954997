import { FC, ReactElement, useState } from 'react';
import classes from './ReferralSystemContent.module.scss';
import { Tabs } from 'antd';
import { fetchSearchReferral } from '../../api/AppApi/AppApi.api';
import { TABS_REF_ID } from './ReferralSystemContent.constant';
import Structure from './Tabs/Structure/Structure';
import Finance from './Tabs/Finance/Finance';
import { useTranslation } from 'react-i18next';
import { ContentProps } from '../../interfaces';

const { TabPane } = Tabs;

const ReferralSystemContent: FC<ContentProps> = (): ReactElement => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState<string>(TABS_REF_ID.STRUCTURE_ID);

  const search = async (
    page: number,
    searchValue?: string
  ): Promise<unknown> => {
    try {
      const result = await fetchSearchReferral(searchValue || '', page);

      if (!result) {
        throw new Error('invalid search');
      }

      return result;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      return [];
    }
  };

  const handleTabClick = (key: string) => {
    if (key !== activeTab) {
      setActiveTab(key);
    }
  };

  return (
    <section className={classes.content}>
      <h3 className={classes.title}>{t('refPage_title')}</h3>
      <Tabs
        className={classes.tabs}
        activeKey={activeTab}
        onTabClick={handleTabClick}
        defaultActiveKey={TABS_REF_ID.STRUCTURE_ID}
        centered
        destroyInactiveTabPane
      >
        <TabPane
          tab={t(`refPage_${TABS_REF_ID.STRUCTURE_ID}`)}
          key={TABS_REF_ID.STRUCTURE_ID}
        >
          <Structure onSearch={search} />
        </TabPane>
        <TabPane
          tab={t(`refPage_${TABS_REF_ID.FINANCE_ID}`)}
          key={TABS_REF_ID.FINANCE_ID}
        >
          <Finance />
        </TabPane>
      </Tabs>
    </section>
  );
};

export default ReferralSystemContent;
