import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './Language.module.scss';

const Language: FC = () => {
  const { i18n } = useTranslation();

  const [currentLang, setCurrentLang] = useState<string>(i18n.language);

  const createHandleChangeLanguage =
    (nextLang: string, shouldPushState = true) =>
    () => {
      if (nextLang === currentLang) {
        return;
      }

      i18n.changeLanguage(nextLang).finally(() => {
        setCurrentLang(nextLang);
      });

      if (shouldPushState) {
        localStorage.setItem('lang', nextLang);
      }
    };

  useEffect(() => {
    const lang = localStorage.getItem('lang');

    if (lang && lang !== currentLang && i18n.languages.includes(lang)) {
      createHandleChangeLanguage(lang, false)();
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.container}>
      <div
        onClick={createHandleChangeLanguage('ru')}
        className={clsx(
          classes.buttonLanguage,
          i18n.language === 'ru' && classes.active
        )}
        tabIndex={-1}
        role="button"
        aria-label="change language on ru"
        aria-pressed="false"
      >
        RUS
      </div>
      <div
        onClick={createHandleChangeLanguage('en')}
        className={clsx(
          classes.buttonLanguage,
          i18n.language === 'en' && classes.active
        )}
        tabIndex={-1}
        aria-label="change language on eng"
        role="button"
        aria-pressed="false"
      >
        ENG
      </div>
    </div>
  );
};

export default Language;
