import { FC, ReactElement } from 'react';
import { IconProps } from '../../../interfaces';

const WalletIcon: FC<IconProps> = ({ color }): ReactElement => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 10.5C3.5 9.57174 3.86875 8.6815 4.52513 8.02513C5.1815 7.36875 6.07174 7 7 7H35C35.9283 7 36.8185 7.36875 37.4749 8.02513C38.1313 8.6815 38.5 9.57174 38.5 10.5V31.5C38.5 32.4283 38.1313 33.3185 37.4749 33.9749C36.8185 34.6313 35.9283 35 35 35H7C6.07174 35 5.1815 34.6313 4.52513 33.9749C3.86875 33.3185 3.5 32.4283 3.5 31.5V10.5Z"
      stroke={color || 'var(--lightBlueColor)'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.5 14H38.5"
      stroke={color || 'var(--lightBlueColor)'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.5 21H15.75C15.75 22.75 16.8 26.25 21 26.25C25.2 26.25 26.25 22.75 26.25 21H38.5"
      stroke={color || 'var(--lightBlueColor)'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

WalletIcon.defaultProps = {
  color: '',
};

export default WalletIcon;
