import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: navigator.language.split('-')[0],
    fallbackLng: ['en', 'ru'], // use en if detected lng is not available
    saveMissing: true, // send not translated keys to endpoint
    keySeparator: '_',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    initImmediate: false,
    react: {
      useSuspense: false,
    },
  });
export default i18n;
