import { FC, useEffect, useState } from 'react';
import NestedTable from '../../NestedRefTable/NestedRefTable';
import { IReferralTransactions } from '../../ReferralSystemContent.interface';
import { fetchReferralTransactionsList } from '../../../../api/AppApi/AppApi.api';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import moment from 'moment';

const parseItems = (items: IReferralTransactions[]) =>
  items.map(item => {
    if (item?.createdAt) {
      return {
        ...item,
        createdAt: moment(item.createdAt).format('DD.MM.YY hh:mm'),
      };
    }
    return item;
  });

const Finance: FC = () => {
  const [pageSize, setPageSize] = useState<number>(15);
  const [page, setPage] = useState<number>(1);
  const [items, setItems] = useState<IReferralTransactions[]>([]);
  const [total, setTotal] = useState<number>(items.length);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    fetchReferralTransactionsList<IReferralTransactions>(page)
      .then(result => {
        if (!result) {
          throw new Error('invalid load');
        }

        setTotal(result.totalCount);
        setPageSize(result.itemsPerPage);
        setPage(result.currentPageNumber);
        setItems(parseItems(result.items));
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page]);

  const handleChangePagination = (pagination: TablePaginationConfig) => {
    const currentPage = Number(pagination.current);

    if (currentPage !== page) {
      setPage(currentPage);
    }
  };

  return (
    <NestedTable
      isNested={false}
      items={items}
      pageSize={pageSize}
      page={page}
      isLoading={isLoading}
      total={total}
      handleChangePagination={handleChangePagination}
    />
  );
};

export default Finance;
