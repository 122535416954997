import { FC, ReactElement } from 'react';
import { IconProps } from '../../../interfaces';

const LocationIcon: FC<IconProps> = ({ onClick, className }): ReactElement => (
  <svg
    className={className}
    onClick={onClick}
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <circle cx="50" cy="50" r="30" fill="white" />
    </g>
    <g opacity="0.7">
      <path
        d="M34.0002 49.8557C33.9963 50.1006 34.0644 50.341 34.1955 50.5449C34.3265 50.7488 34.5143 50.9066 34.734 50.9975L45.9346 55.6384L50.4156 67.24C50.5022 67.4644 50.6517 67.6568 50.8449 67.7922C51.0381 67.9277 51.2661 68.0001 51.4994 68H51.5181C51.7544 67.9961 51.9839 67.9181 52.1764 67.7761C52.3689 67.6341 52.5152 67.435 52.596 67.205L61.929 40.6234C62.0054 40.4072 62.0205 40.1728 61.9726 39.948C61.9247 39.7231 61.8158 39.5171 61.6586 39.3542C61.5015 39.1913 61.3027 39.0783 61.0856 39.0286C60.8685 38.9788 60.6422 38.9943 60.4334 39.0733L34.7678 48.7393C34.5456 48.8232 34.3533 48.9749 34.2162 49.1745C34.0791 49.3741 34.0038 49.612 34.0002 49.8569V49.8557Z"
        fill="var(--darkBlueColor)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="100"
        height="100"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="10" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.4125 0 0 0 0 0.4125 0 0 0 0 0.4125 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

LocationIcon.defaultProps = {
  onClick: undefined,
  className: '',
};

export default LocationIcon;
