import { ChangeEventHandler, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../common/Button/Button';
import Input from '../../common/Input/Input';
import classes from './CodeApply.module.scss';

const CodeApply = (): ReactElement => {
  const { t } = useTranslation();
  const [code, setCode] = useState<string>('');

  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    setCode(event.target.value);
  };

  const handleCodeApply = () => {
    // eslint-disable-next-line no-console
    console.log('handleCApply');
  };

  return (
    <div>
      <Input
        className={classes.codeInput}
        value={code}
        onChange={handleChange}
        placeholder="ХХХХ-ХХХХ-ХХХХ-ХХХХ"
      />
      <div className={classes.submitButtonWrapper}>
        <Button
          onClick={handleCodeApply}
          className={classes.btn}
          type="primary"
        >
          {t('bill_submitCode')}
        </Button>
      </div>
    </div>
  );
};

export default CodeApply;
