import { Input as AntInput, InputProps } from 'antd';
import clsx from 'clsx';
import { FC, ReactElement } from 'react';
import classes from './Input.module.scss';

const Input: FC<InputProps> = ({ className, type, ...props }): ReactElement => (
  <AntInput
    type={type || 'text'}
    className={clsx(classes.input, className)}
    {...props}
  />
);

export default Input;
