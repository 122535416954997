import { FC, ReactElement } from 'react';
import { IconProps } from '../../../interfaces';

const MapIcon: FC<IconProps> = ({ color }): ReactElement => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.7087 8.45252L7.9625 6.52402C7.44156 6.37502 6.89313 6.349 6.36042 6.44801C5.8277 6.54703 5.32526 6.76837 4.89264 7.09461C4.46003 7.42085 4.10907 7.84308 3.86741 8.32803C3.62574 8.81299 3.49997 9.34744 3.5 9.88927V30.6093C3.49996 31.3697 3.74758 32.1095 4.20542 32.7167C4.66325 33.3238 5.30637 33.7654 6.0375 33.9745L14.7087 36.4525C15.3871 36.6462 16.1081 36.6303 16.7772 36.407L25.2228 33.593C25.8919 33.3697 26.6129 33.3539 27.2913 33.5475L34.0393 35.476C34.5601 35.6247 35.1083 35.6505 35.6408 35.5513C36.1733 35.4521 36.6755 35.2307 37.1079 34.9045C37.5403 34.5783 37.8911 34.1562 38.1326 33.6714C38.3742 33.1866 38.5 32.6524 38.5 32.1108V11.3908C38.5 10.6303 38.2524 9.89055 37.7946 9.28338C37.3368 8.6762 36.6936 8.23464 35.9625 8.02552L27.2895 5.54752C26.6112 5.35389 25.8902 5.36975 25.221 5.59302L16.7772 8.40702C16.1081 8.6303 15.3871 8.64616 14.7087 8.45252Z"
      stroke={color || 'var(--grayColor)'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.75 8.75V36.75"
      stroke={color || 'var(--grayColor)'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.25 5.25V33.25"
      stroke={color || 'var(--grayColor)'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

MapIcon.defaultProps = {
  color: '',
};

export default MapIcon;
