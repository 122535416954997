import { Spin } from 'antd';
import { FC, ReactElement } from 'react';
import classes from './Fallback.module.scss';

const Fallback: FC = (): ReactElement => (
  <section className={classes.fallback}>
    <Spin size="large" tip="Загрузка приложения..." />
  </section>
);

export default Fallback;
