import {
  FC,
  MouseEventHandler,
  useEffect,
  useState,
  MouseEvent,
  useMemo,
} from 'react';
import debounce from 'debounce';
import { message, Tooltip } from 'antd';
import clsx from 'clsx';
import Button from '../../common/Button/Button';
import Input from '../../common/Input/Input';
import LockIcon from '../../Icons/LockIcon/LockIcon';
import PersonIcon from '../../Icons/PersonIcon/PersonIcon';
import WifiIcon from '../../Icons/WifiIcon/WifiIcon';
import { DefaultFormProps } from '../../../types/components.types';
import classes from './DefaultForm.module.scss';
import { useTranslation } from 'react-i18next';
import CopyTextIcon from '../../Icons/CopyTextIcon/CopyTextIcon';
import classesPopup from '../PointPopup.module.scss';

const DefaultForm: FC<DefaultFormProps> = ({
  handleApprovePoint,
  isProgressApprove,
  approved,
  username,
  password: passwordProp,
  daysSinceCreated,
}) => {
  const { t } = useTranslation();

  const [isDisabled] = useState<boolean>(false);
  const [isCanShowPassword, setCanShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [isVisibleTooltip, setVisibleTooltip] = useState<boolean>(false);

  const isFree = !passwordProp;
  const isShowPassword = isFree || isCanShowPassword;

  useEffect(() => {
    const wifiPassword = isFree ? 'FREE' : passwordProp;

    if (wifiPassword && wifiPassword !== password) {
      setPassword(wifiPassword);
    }
  }, [isFree, passwordProp, password]);

  const handleTooltipVisible = (visible: boolean) => {
    if (visible && !isCanShowPassword) {
      return;
    }

    setVisibleTooltip(visible);
  };

  const handleShowPassword: MouseEventHandler = () =>
    setCanShowPassword(!isCanShowPassword);

  const handleApprove: MouseEventHandler = useMemo(
    () =>
      debounce((e: MouseEvent) => {
        handleApprovePoint(e);
      }, 600),
    [handleApprovePoint]
  );

  const handleCopyText = () => {
    navigator.clipboard.writeText(password);
    message.info(t('clipboard_done'));
  };

  return (
    <>
      <div className={classesPopup.wifi}>
        <div className={classes.icon}>
          <WifiIcon />
        </div>
        <div className={classesPopup.aboutWifi}>
          <p className={classesPopup.wifiName}>{username}</p>
        </div>
      </div>
      <form className={classesPopup.wifiForm}>
        <Tooltip
          visible={isVisibleTooltip}
          onVisibleChange={handleTooltipVisible}
          title={password}
          placement="top"
        >
          <div className={classesPopup.wifiPasswordBlock}>
            <Input
              type={isShowPassword ? 'text' : 'password'}
              className={clsx(
                classesPopup.input,
                classesPopup.password,
                isFree && classesPopup.freePassword
              )}
              disabled
              value={password}
              prefix={<LockIcon />}
            />
            {!isFree && (
              <Button
                type="primary"
                className={classesPopup.showPasswordButton}
                onClick={handleShowPassword}
              >
                {t('pointForm_showPassword')}
              </Button>
            )}

            {!isFree && isCanShowPassword && (
              <div
                onClick={handleCopyText}
                className={classes.copyButton}
                role="button"
                aria-pressed="false"
                tabIndex={-1}
                aria-label="copy text"
              >
                <CopyTextIcon />
              </div>
            )}
          </div>
        </Tooltip>
        <Input
          className={clsx(classesPopup.input, classesPopup.author)}
          disabled
          value={username}
          prefix={<PersonIcon />}
          suffix={
            daysSinceCreated ? (
              <span>
                {daysSinceCreated} {t('pointForm_days')}
              </span>
            ) : null
          }
        />
      </form>
      {!approved && (
        <div className={classes.approveBlock}>
          <div className={classes.approveText}>
            {t('pointForm_approveMessage')}
          </div>
          <div className={classes.approveControls}>
            <Button
              disabled={isDisabled}
              onClick={handleApprove}
              loading={isProgressApprove}
              className={classes.approveButton}
            >
              {t('pointForm_approve')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

DefaultForm.defaultProps = {
  isProgressApprove: false,
  username: '',
  password: '',
};

export default DefaultForm;
