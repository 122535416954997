import { ReactElement } from 'react';

const ArrowRightButton = (): ReactElement => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6769 5.29326C17.2864 5.68378 17.2864 6.31695 17.6769 6.70747L25.5858 14.6163H3.96004C3.40776 14.6163 2.96004 15.0641 2.96004 15.6163C2.96004 16.1686 3.40776 16.6163 3.96004 16.6163H25.5858L17.6769 24.5252C17.2864 24.9157 17.2864 25.5489 17.6769 25.9394C18.0674 26.33 18.7006 26.33 19.0911 25.9394L28.7071 16.3235C29.0976 15.9329 29.0976 15.2998 28.7071 14.9092L19.0911 5.29326C18.7006 4.90274 18.0674 4.90274 17.6769 5.29326Z"
      fill="#234A6C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6769 5.29326C17.2864 5.68378 17.2864 6.31695 17.6769 6.70747L25.5858 14.6163H3.96004C3.40776 14.6163 2.96004 15.0641 2.96004 15.6163C2.96004 16.1686 3.40776 16.6163 3.96004 16.6163H25.5858L17.6769 24.5252C17.2864 24.9157 17.2864 25.5489 17.6769 25.9394C18.0674 26.33 18.7006 26.33 19.0911 25.9394L28.7071 16.3235C29.0976 15.9329 29.0976 15.2998 28.7071 14.9092L19.0911 5.29326C18.7006 4.90274 18.0674 4.90274 17.6769 5.29326Z"
      fill="#004C8E"
    />
  </svg>
);

export default ArrowRightButton;
