import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './Commission.module.scss';

interface ICommissionProps {
  className?: string;
}

const Commission: FC<PropsWithChildren<ICommissionProps>> = ({
  children,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.commission, className)}>
      {t('send_commission')}: {children}
    </div>
  );
};

export default Commission;
