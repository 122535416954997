import { IWalletState } from '../walletStore/wallet.interface';
import getWalletStore from './getWalletStore';
import { WalletTcomb } from '../../../../lib/store/tcombStructure';
import transactionUpdate from './transactionUpdate';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const updateWallet = async (
  transaction = transactionUpdate(),
  changedWalletValues?: Partial<IWalletState>
) => {
  const wallet = await getWalletStore();

  const walletClone = {
    ...wallet,
    ...(changedWalletValues || null),
    last_modified: new Date(),
  };

  WalletTcomb(walletClone); // validate

  let walletBdStore;

  try {
    walletBdStore = transaction.objectStore('wallet');
  } catch {
    walletBdStore = transactionUpdate().objectStore('wallet');
  }

  try {
    // @ts-ignore
    return await walletBdStore.put(walletClone);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('wallet update error', err);

    return null;
  }
};

export default updateWallet;
