import loadNoAccountRefs from './loadNoAccountRefs';
import accountRefsActions from '../actions/AccountRefsActions';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const loadRefsDbData = async () => {
  await accountRefsActions.chainStoreUpdate();

  return loadNoAccountRefs();

  // return loadNoAccountRefs()
  // .then(noAccountRefs => this.no_account_refs = noAccountRefs)
  // .then(()=> this.chainStoreUpdate())
};

export default loadRefsDbData;
