import { oauthKey, authStage } from '../../types';

export const OAUTH_ID: Readonly<Record<string, oauthKey>> = {
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
};

export const AUTH_STAGES_MAP: Readonly<Record<authStage, authStage>> = {
  REGISTRATION: 'REGISTRATION',
  AUTH: 'AUTH',
};

export const AUTH_STAGE_NAMES: Readonly<Record<string, string>> = {
  [AUTH_STAGES_MAP.REGISTRATION]: 'reg_title',
  [AUTH_STAGES_MAP.AUTH]: 'auth_title',
};
