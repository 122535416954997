import { Button as AntButton, ButtonProps } from 'antd';
import clsx from 'clsx';
import { FC, ReactElement } from 'react';
import classes from './Button.module.scss';

const Button: FC<ButtonProps> = ({ className, ...props }): ReactElement => (
  <AntButton className={clsx(classes.button, className)} {...props} />
);

export default Button;
