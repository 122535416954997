export const STYLE_SIZES: Readonly<Record<string, string | number>> = {
  MOBILE_WIDTH: '95%',
  INFO_WIDTH: 400,
  DEFAULT_WIDTH: 400,
  REF_WIDTH: '70vw',
  PADDING: 10,
};

export const POPUP_TYPE: Readonly<Record<string, string>> = {
  INFO: 'info',
  DEFAULT: 'default',
};
