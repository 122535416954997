import walletStore from '../walletStore/wallet';
import iDB from '../../../../models/IndexedDb/IndexedDb.init';
import { ChainStore } from '2ab2-js';
import tryToSetCurrentAccount from './tryToSetCurrentAccount';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const addAccountRefs = async () => {
  //  Simply add them to the linkedAccounts list (no need to persist them)
  const { accountRefs } = walletStore;
  // eslint-disable-next-line no-unused-vars
  let pending = false;

  if (!iDB.instance) {
    throw new Error('idb not initialized');
  }

  const transaction = iDB.instance.getTransaction(
    ['linked_accounts'],
    'readwrite'
  );

  const linkedAccounts = transaction.objectStore('linked_accounts');

  accountRefs.forEach(id => {
    const account = ChainStore.getAccount(id);
    if (account === undefined) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      pending = true;
      return;
    }

    if (account) {
      const item = account.get('name');
      // @ts-ignore
      linkedAccounts.add(item);
    }
  });

  await tryToSetCurrentAccount();
};

export default addAccountRefs;
