import { Aes, key, PrivateKey } from '2ab2-js';
import dictionary from '../actions/dictionary.json';
import getBrainKeyPrivate from './getBrainKeyPrivate';
import { IWalletState } from '../walletStore/wallet.interface';
import { Apis } from '2ab2-ws-js';
import { WalletTcomb } from '../../../../lib/store/tcombStructure';
import transactionWalletUpdate from './transactionWalletUpdate';
import walletStore from '../walletStore/wallet';
import ManageDatabase from '../../../../models/IndexedDb/IndexedDb.utils';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const onCreateWallet = async (
  _passwordPlaintext: string,
  _brainKeyPlaintext: string,
  publicName = 'default'
) => {
  const passwordPlaintext = _passwordPlaintext;
  let brainKeyPlaintext = _brainKeyPlaintext;

  const walletCreateFct = async () => {
    let brainkeyBackupDate;

    if (brainKeyPlaintext) {
      if (brainKeyPlaintext.trim() === '')
        throw new Error('Brainkey can not be an empty string');

      if (brainKeyPlaintext.length < 50)
        throw new Error('Brainkey must be at least 50 characters long');

      // The user just provided the Brainkey so this avoids
      // bugging them to back it up again.
      brainkeyBackupDate = new Date();
    }
    const passwordAes = Aes.fromSeed(passwordPlaintext);

    const encryptionBuffer = key.get_random_key().toBuffer();
    // encryption_key is the global encryption key (does not change even if the passsword changes)
    const encryptionKey = passwordAes.encryptToHex(encryptionBuffer);
    // If unlocking, local_aes_private will become the global aes_private object
    const localAesPrivate = Aes.fromSeed(encryptionBuffer);

    if (!brainKeyPlaintext) {
      brainKeyPlaintext = key.suggest_brain_key(dictionary.en);
    } else brainKeyPlaintext = key.normalize_brainKey(brainKeyPlaintext);

    const brainKeyPrivate = getBrainKeyPrivate(brainKeyPlaintext);

    const brainKeyPubKey = brainKeyPrivate.toPublicKey().toPublicKeyString();

    const encryptedBrainKey = localAesPrivate.encryptToHex(brainKeyPlaintext);

    const passwordPrivate = PrivateKey.fromSeed(passwordPlaintext);
    const passwordPubKey = passwordPrivate.toPublicKey().toPublicKeyString();

    const walletValue: IWalletState = {
      public_name: publicName,
      password_pubkey: passwordPubKey,
      encryption_key: encryptionKey,
      encrypted_brainkey: encryptedBrainKey,
      brainkey_pubkey: brainKeyPubKey,
      brainkey_sequence: 0,
      brainkey_backup_date: brainkeyBackupDate as Date,
      created: new Date(),
      last_modified: new Date(),
      chain_id: Apis.instance().chain_id,
    };

    WalletTcomb(walletValue); // validation

    const transaction = transactionWalletUpdate();

    const store = transaction.objectStore('wallet');

    if (transaction) {
      transaction.oncomplete = () => {
        walletStore.setAesPrivate(localAesPrivate);
      };
    }

    // eslint-disable-next-line no-return-await
    return await ManageDatabase.add(store, walletValue);
  };

  // eslint-disable-next-line no-return-await
  return await walletCreateFct();
};

export default onCreateWallet;
