// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
import iDB from '../../../../models/IndexedDb/IndexedDb.init';
import ManageDatabase from '../../../../models/IndexedDb/IndexedDb.utils';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const onDeleteAllWallets = async () => {
  if (!iDB.instance) {
    throw new Error('iDB.instance invalid');
  }

  const context = await iDB.instance.getTransaction('wallet', 'readwrite');

  // @ts-ignore
  await ManageDatabase.removeAll(
    context.objectStore('wallet'),
    iDB.api.getCurrentWalletName()
  );
};

export default onDeleteAllWallets;
