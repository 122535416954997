/* eslint-disable arrow-parens */
import { PrivateKey } from "2ab2-js";
import walletStore from "../walletStore/wallet";
import isNill from "./isNill";
import Immutable from "immutable";
import privateKeyActions from "../actions/privateKeyActions";
import { loadAddyMap } from "../actions/onAddressIndex";

// eslint-disable-next-line no-shadow
const hasPubKey = (data: Immutable.Map<unknown, unknown>, key: string) =>
  data.has(key);

const getPubKeysHavingPrivateKey = async (
  pubKeys: string[],
  addys?: string[]
): Promise<any[]> => {
  const returnPubKeys = [];

  const data = await privateKeyActions.onLoadDbData();

  if (pubKeys) {
    // eslint-disable-next-line no-restricted-syntax
    for (const pubKey of pubKeys) {
      if (hasPubKey(data, pubKey)) {
        returnPubKeys.push(pubKey);
      }
    }
  }

  if (!addys) {
    return returnPubKeys;
  }

  const addresses = await loadAddyMap();

  // eslint-disable-next-line no-restricted-syntax
  for (const item of addys) {
    const pubKey = addresses.get(item);

    if (pubKey) {
      returnPubKeys.push(pubKey);
    }
  }

  return returnPubKeys;
};

const getTcombByPubKey = async (publicKeyValue: Record<string, any>) => {
  let publicKey = publicKeyValue;

  if (!publicKey) return null;

  if (publicKey.Q) {
    publicKey = publicKey.toPublicKeyString();
  }

  const data = await privateKeyActions.onLoadDbData();

  return data.find((item) => item === publicKey);
};

const decryptTcombPrivateKey = (privateKeyTcomb: Record<string, any>) => {
  if (!privateKeyTcomb) return null;
  if (!walletStore.aesPrivate) throw new Error("wallet locked");

  const privateKeyHex = walletStore.aesPrivate.decryptHex(
    privateKeyTcomb.encrypted_key
  );

  return PrivateKey.fromBuffer(Buffer.from(privateKeyHex, "hex"));
};

const getPrivateKey = (publicKeyValue: Record<string, any>) => {
  let publicKey = publicKeyValue;

  if (!publicKey) {
    return null;
  }

  if (publicKey.Q) {
    publicKey = publicKey.toPublicKeyString();
  }

  const privateKeyTcomb = getTcombByPubKey(publicKey);

  if (!privateKeyTcomb) return null;

  return decryptTcombPrivateKey(privateKeyTcomb);
};

const processTransaction = async (
  tr: Record<string, any>, // TransactionBuilder
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  signerPubkeys: any
  // eslint-disable-next-line consistent-return
): Promise<void> => {
  const assetId = tr.operations[0][1]?.fee?.asset_id;

  const feeId = !isNill(assetId) ? `1.3.${assetId}` : "1.3.1";

  try {
    await tr.set_required_fees(feeId);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }

  const signerPubKeysAdded: Record<string, boolean> = {};

  if (signerPubkeys) {
    // Balance claims are by address, only the private
    // key holder can know about these additional
    // potential keys.
    const pubKeys = await getPubKeysHavingPrivateKey(signerPubkeys);

    if (!pubKeys.length) {
      throw new Error("Missing signing key");
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const pubKeyString of pubKeys) {
      // @ts-ignore
      const privateKey = getPrivateKey(pubkeyString);
      tr.add_signer(privateKey, pubKeyString);
      signerPubKeysAdded[pubKeyString] = true;
    }
  }

  const { pubkeys, addys } = await tr.get_potential_signatures();

  const myPubKeys = await getPubKeysHavingPrivateKey(pubkeys, addys);

  const requiredPubKeys = await tr.get_required_signatures(myPubKeys);

  // eslint-disable-next-line no-restricted-syntax
  for (const pubKeyString of requiredPubKeys) {
    if (signerPubKeysAdded[pubKeyString]) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const privateKeyValue = getPrivateKey(pubKeyString);

    if (!privateKeyValue)
      // This should not happen, get_required_signatures will only
      // returned keys from my_pubkeys
      throw new Error("Missing signing key for " + pubKeyString);

    tr.add_signer(privateKeyValue, pubKeyString);
    if (signerPubKeysAdded[pubKeyString]) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const privateKey = getPrivateKey(pubKeyString);

    if (!privateKey) {
      // This should not happen, get_required_signatures will only
      // returned keys from my_pubkeys
      throw new Error("Missing signing key for " + pubKeyString);
    }

    tr.add_signer(privateKey, pubKeyString);
  }

  return tr.serialize();
};

export default processTransaction;
