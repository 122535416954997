import { FC } from 'react';
import AppStoreIcon from '../../Icons/AppStoreIcon/AppStoreIcon';
import ChargeIcon from '../../Icons/ChargeIcon/ChargeIcon';
import GooglePlayIcon from '../../Icons/GooglePlayIcon/GooglePlayIcon';
import classes from './PowerbankForm.module.scss';

const PowerbankForm: FC = () => (
  <div className={classes.content}>
    <div className={classes.availablePowerbanksBlock}>
      <div className={classes.availablePowerbanks}>
        <div className={classes.chargeCountBlock}>
          <ChargeIcon />
          <span className={classes.chargeCountText}>2 доступно</span>
        </div>
        <ul className={classes.platformList}>
          <li className={classes.platform}>IOS</li>
          <li className={classes.platform}>Android</li>
          <li className={classes.platform}>Type-C</li>
        </ul>
      </div>
      <div className={classes.returnAvailableBlock}>
        Возможность вернуть:
        <span className={classes.returnAvailableCount}> 6</span>
      </div>
      <div className={classes.workTimeBlock}>
        График работы:
        <span className={classes.workTime}>пн-вс: 10:00 - 05:00</span>
      </div>
      <div className={classes.contactBlock}>
        Телефон:
        <span className={classes.phone}> 0672464007</span>
      </div>
      <div className={classes.banner}>
        <div className={classes.bannerText}>
          Чтобы получить PowerBank, сканируй QR-код через мобильное приложение
          <span className={classes.appName}> EDC WiFi map</span>
        </div>
        <div className={classes.bannerButtons}>
          <AppStoreIcon />
          <GooglePlayIcon />
        </div>
      </div>
    </div>
  </div>
);

export default PowerbankForm;
