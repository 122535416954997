import iDB from '../../../../models/IndexedDb/IndexedDb.init';
import walletStore from '../walletStore/wallet';

const getBrainKey = async (): Promise<string | null> => {
  if (!iDB.instance) {
    throw new Error('iDB.instance error');
  }

  const transaction = iDB.instance.getTransaction(['wallet'], 'readwrite');

  const store = transaction.objectStore('wallet');

  const result = await store.get('default');

  if (!result?.encrypted_brainkey) {
    return null;
  }

  if (!walletStore.aesPrivate) {
    return null;
  }

  return walletStore.aesPrivate.decryptHexToText(result.encrypted_brainkey);
};

export default getBrainKey;
