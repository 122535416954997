export const contentUrl = (value: string, id?: string): string => {
  const urlParams = new URLSearchParams(window.location.search);

  let url = value ? `/content/${value}${id ? `/${id}` : ''}` : '/';

  const query = urlParams.toString();

  if (query) {
    url += `?${query}`;
  }

  return url;
};

export const contentItemUrl = (value: string, id: string): string =>
  contentUrl(`item/${value}`, id);

export const pointUrl = (pathname: string, id: string): string =>
  `${pathname}&point=${id}`;

export const locationResolve = (pathname: string, value: string): string =>
  `${pathname}${value}`;
