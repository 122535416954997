import { IWalletState } from "../walletStore/wallet.interface";
import iDB from "../../../../models/IndexedDb/IndexedDb.init";

const getWalletStore = async (): Promise<IWalletState> => {
  if (!iDB.instance) {
    throw new Error("iDB.instance error");
  }

  const transaction = iDB.instance.getTransaction(["wallet"], "readwrite");

  const store = transaction.objectStore("wallet");

  const result: IWalletState = await store.get("default");

  return result;
};

export default getWalletStore;
