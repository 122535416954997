import { ChainStore, key } from '2ab2-js';
import walletStore from '../walletStore/wallet';
import getWalletStore from './getWalletStore';
import { IWalletState } from '../walletStore/wallet.interface';
import updateWallet from './updateWallet';
import getBrainKey from './getBrainKey';
import getBrainKeySequence from './GetBrainKeySequence';
import incrementBrainKeySequence from './incrementBrainKeySequence';
import { saveKey } from './saveKeyToStorage';

export interface INextKey {
  // eslint-disable-next-line camelcase
  private_key: Record<string, any>;
  sequence: number | string;
}

export const generateNextKey = async (save = true): Promise<INextKey> => {
  const brainkey = await getBrainKey();

  const walletValue = await getWalletStore();

  const walletCopy: Partial<IWalletState> = walletValue
    ? {
        ...walletValue,
      }
    : {};

  let sequence = await getBrainKeySequence();

  let usedSequence = null;

  // Skip ahead in the sequence if any keys are found in use
  // Slowly look ahead (1 new key per block) to keep the wallet fast after unlocking

  // @ts-ignore
  walletStore.brainKeyLookAhead = Math.min(
    17,
    (Number(walletStore.brainKeyLookAhead) || 0) + 1
  );

  const brainKeyLookAheadNumeric = Number(walletStore.brainKeyLookAhead);

  // eslint-disable-next-line no-plusplus
  for (let i = sequence; i < sequence + brainKeyLookAheadNumeric; i++) {
    const privateKey = key.get_brainPrivateKey(brainkey, i);

    const pubKey: any = walletStore.generateNextKeyPubCache[i]
      ? walletStore.generateNextKeyPubCache[i]
      : (walletStore.generateNextKeyPubCache[i] = privateKey
          .toPublicKey()
          .toPublicKeyString());

    const nextKey = ChainStore.getAccountRefsOfKey(pubKey);

    if (nextKey && nextKey.size) {
      usedSequence = i;
      // eslint-disable-next-line no-console
      console.log(
        'WARN: Private key sequence ' +
          usedSequence +
          ' in-use. ' +
          'I am saving the private key and will go onto the next one.'
      );
      // eslint-disable-next-line no-await-in-loop
      await saveKey(privateKey, usedSequence);
    }
  }

  if (usedSequence !== null) {
    walletCopy.brainkey_sequence = Number(usedSequence) + 1;
    await updateWallet(undefined, walletCopy);
  }

  sequence = Number(walletCopy.brainkey_sequence);

  const privateKey = key.get_brainPrivateKey(brainkey, sequence);
  if (save) {
    // save deterministic private keys ( the user can delete the brainkey )
    await saveKey(privateKey, sequence);
    await incrementBrainKeySequence();
  }

  return { private_key: privateKey, sequence };
};
