import {
  FC,
  MouseEventHandler,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fetchSubOnPlan } from '../../../redux/walletReducer/walletReducer.thunk';
import { resolveTemplate } from '../../../utils/utils.global';
import Button from '../../common/Button/Button';
import SubscribeItem from '../../SubscribeContent/SubscribeItem/SubscribeItem';
import { EDC_USD_RATE } from '../ProfileContent.constant';
import useShallowEqualSelector from '../../../utils/useShallowEqualSelector';
import classes from './SubscribeInfo.module.scss';
import {
  SUBSCRIBE_TYPES,
  subscriptionsList,
} from '../../SubscribeContent/SubscribeContent.constant';
import { Subscribe } from '../../../interfaces';
import { ContentProps } from '../../../interfaces/index';
import { contentUrl } from '../../../config/url.config';
import { MENU_ID_MAP } from '../../Menu/Menu.constant';
import { useParams } from 'react-router-dom';
import useOpenView from '../../../hooks/useOpenView';
import { fetchPlansList } from '../../../redux/appReducer/appReducer.thunk';

const SubscribeInfo: FC<ContentProps> = (): ReactElement => {
  const { selectedSubId } = useParams<Record<string, string>>();

  const { t } = useTranslation();

  const actions = useOpenView();
  const dispatch = useDispatch();

  const { rate, plansList } = useShallowEqualSelector(state => ({
    rate: state.appReducer.user?.edcUsdRate || EDC_USD_RATE,
    shouldChangeStageAfterChanges: false,
    plansList: state.appReducer.plansList,
  }));

  useEffect(() => {
    if (!plansList?.length) {
      dispatch(fetchPlansList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentSub = useMemo(
    () => plansList?.find(planItem => planItem.id === selectedSubId),
    [plansList, selectedSubId]
  );

  const handleSelectNextSub = useCallback(
    (id: string) => {
      if (!plansList) {
        return;
      }

      if (!currentSub) {
        return;
      }

      actions.open(
        contentUrl(`${MENU_ID_MAP.PROFILE}/${MENU_ID_MAP.SUB_PAGE}/${id}`)
      );
    },
    [actions, currentSub, plansList]
  );

  const {
    meta,
    term = '',
    description,
    amount: price = 0,
    name = '',
    id = '',
  } = (currentSub as Subscribe) || {};

  const { descriptionColor: color = '' } = meta || {};

  const desc = useMemo(
    () =>
      description?.map((string: string) => (
        <li className={classes.descItemText} key={string}>
          - {resolveTemplate(t(string), { style: `color:${color};` })}
        </li>
      )),
    [description, color, t]
  );

  const selectedNextSubId = useMemo(() => {
    if (!currentSub || !plansList) {
      return null;
    }

    let nextSubId =
      currentSub.name === SUBSCRIBE_TYPES.PREMIUM
        ? null
        : plansList.find(planItem => planItem.name === SUBSCRIBE_TYPES.BASIC)
            ?.id;

    if (currentSub.name === SUBSCRIBE_TYPES.BASIC) {
      nextSubId = plansList.find(
        planItem => planItem.name === SUBSCRIBE_TYPES.PREMIUM
      )?.id;
    }

    return nextSubId;
  }, [currentSub, plansList]);

  const nextSub = useMemo(() => {
    if (selectedNextSubId === null || !plansList) {
      return null;
    }

    const planItem = plansList.find(sub => sub.id === selectedNextSubId);

    if (!planItem) {
      return null;
    }

    const additionalViewProperty = subscriptionsList.find(
      item => item.subId === planItem.name
    );

    return {
      ...(additionalViewProperty as Subscribe),
      ...planItem,
    } as Subscribe;
  }, [selectedNextSubId, plansList]);

  const handleChooseNextSub = () => {
    if (selectedNextSubId) {
      handleSelectNextSub(selectedNextSubId);
    }
  };

  const handlePay: MouseEventHandler = () =>
    dispatch(
      fetchSubOnPlan({
        planId: id,
        message: price ? t('sub_donePay') : t('sub_doneActive'),
      })
    );

  return (
    <section className={classes.content}>
      <div className={classes.subscribeInfo}>
        <div className={classes.selectSubItem}>
          <div style={{ color }} className={classes.selectSubTitle}>
            {name}
          </div>
          <div className={classes.term}>{t(term)}</div>
          <ul className={classes.descList}>{desc}</ul>
          <div className={classes.price}>${price}</div>
          <div className={classes.edcPrice}>{`(${(price / rate).toFixed(
            3
          )} EDC)`}</div>
          <div className={classes.submitForm}>
            <Button
              onClick={handlePay}
              className={classes.submit}
              type="primary"
            >
              {!price ? t('sub_buttonActive') : t('sub_buttonPay')}
            </Button>
          </div>
        </div>
        {!!nextSub && (
          <SubscribeItem
            key={nextSub.id}
            activeItemId={null}
            {...nextSub}
            onChoose={handleChooseNextSub}
          />
        )}
      </div>
    </section>
  );
};

export default SubscribeInfo;
