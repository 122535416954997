import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';
import { MapPoint, PointValues, ViewportCoords } from '../../interfaces';
import {
  approvePoint,
  createPoint,
  fetchPoints,
} from '../../api/MapApi/Map.api';
import { IApprovePointThunkResult } from './mapReducer.interface';

export const viewportPointsThunkCreator: AsyncThunkPayloadCreator<
  Array<MapPoint>,
  ViewportCoords
> = async (values: ViewportCoords, thunkApi) => {
  const [data, isError] = await fetchPoints(values);

  if (isError) {
    return thunkApi.rejectWithValue(data);
  }

  return data as Array<MapPoint>;
};

export const fetchApprovePointThunkCreator: AsyncThunkPayloadCreator<
  IApprovePointThunkResult,
  string
> = async (id: string, thunkApi) => {
  const [data, isError] = await approvePoint(id);

  if (isError) {
    return thunkApi.rejectWithValue(data);
  }

  return { data: data as boolean, id };
};

export const fetchCreatePointThunkCreator: AsyncThunkPayloadCreator<
  MapPoint,
  PointValues
> = async (values: PointValues, thunkApi) => {
  const [data, isError] = await createPoint(values);

  if (isError) {
    return thunkApi.rejectWithValue(data);
  }

  return data as MapPoint;
};
