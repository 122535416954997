import clsx from 'clsx';
import { ReactElement, useMemo } from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import useShallowEqualSelector from '../../../utils/useShallowEqualSelector';
import CopyLinkIcon from '../../Icons/CopyLinkIcon/CopyLinkIcon';
import { REFERRAL_LINK } from '../../ProfileContent/ProfileContent.constant';
import classes from './ReferralCopyElement.module.scss';

const ReferralCopyElement = (): ReactElement => {
  const { t } = useTranslation();

  const user = useShallowEqualSelector(state => state.appReducer.user);

  const refLink = useMemo(() => `${REFERRAL_LINK}${user?.id}`, [user]);

  const handleCopyText = () => {
    navigator.clipboard.writeText(refLink);
    message.info(t('clipboard_done'));
  };

  return (
    <div className={clsx(classes.statsItem, classes.referralBLock)}>
      <div className={classes.statItemTitle}>
        <span className={classes.linkTitle}>{t('profile_ref')}:</span>
      </div>
      <div className={classes.referralContainer}>
        <div className={clsx(classes.counter, classes.link)}>{refLink}</div>
        <div
          onClick={handleCopyText}
          className={classes.copyButton}
          role="button"
          aria-pressed="false"
          tabIndex={-1}
          aria-label="copy text"
        >
          <CopyLinkIcon />
        </div>
      </div>
    </div>
  );
};

export default ReferralCopyElement;
