import { Suspense, StrictMode } from 'react';
import * as serviceWorker from './serviceWorker';
import ReactDOM from 'react-dom';
import './index.scss';
import 'antd/dist/antd.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { Provider } from 'react-redux';
import store from './redux/store';
import { createBrowserHistory, History } from 'history';
import { Router } from 'react-router-dom';
import Fallback from './pages/Fallback/Fallback';
import RouteState from './components/helpers/routeState';
import BlockchainConnect from './api/v2/blockchain/connect/BlockchainConnect/BlockchainConnect';

// Load Montserrat typeface
require('typeface-montserrat');

const customHistory: History = createBrowserHistory();

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <Router history={customHistory}>
        <Suspense fallback={Fallback}>
          <RouteState>
            <BlockchainConnect>
              <App />
            </BlockchainConnect>
          </RouteState>
        </Suspense>
      </Router>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

serviceWorker.register();
reportWebVitals();
