import { HttpRequestHeader } from 'antd/lib/upload/interface';
import axios, {
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
  Method,
} from 'axios';
import jwtDecode from 'jwt-decode';
import Cookies from 'universal-cookie';
import jwtConfig from '../../config/jwt.config';
import { Api } from '../../interfaces';
import { headersType, sendDataType } from '../../types';

class Request implements Api {
  private apiType = 'api';

  private url = '/';

  private contentType = 'application/json';

  constructor(customType?: string, contentType?: string) {
    const { REACT_APP_DOMAIN_API } = process.env as Record<string, string>;
    const type: string = customType || this.apiType;

    if (contentType) {
      this.contentType = contentType;
    }

    this.apiType = type;
    this.url = `${REACT_APP_DOMAIN_API}/${type}`;
  }

  static getAuthToken(): string | null {
    const cookies = new Cookies();
    return cookies.get('token');
  }

  static logout(): void {
    const cookies = new Cookies();
    cookies.remove('token', jwtConfig);
  }

  static saveJwtToken = (token: string, date?: string): void => {
    const time = 60 * 60 * 24 * 1000 * 7; // milliseconds * seconds * minutes * hours * days * weeks * months
    const cookies = new Cookies();

    let expires: Date | null = date ? new Date(date) : null;

    if (!expires) {
      const dateValue = new Date();

      const expiryTime = dateValue.getTime() + time;
      dateValue.setTime(expiryTime);
      expires = dateValue;
    }

    cookies.set('token', token, {
      ...jwtConfig,
      expires,
    });
  };

  static isTokenExpired(token: string): boolean {
    try {
      if (!token) {
        throw new Error('Invalid jwt-token');
      }

      const decodedToken: { exp: number } = jwtDecode(token);

      return decodedToken.exp < Date.now() / 1000;
    } catch (err) {
      return false;
    }
  }

  public isValidResponse(response: AxiosResponse): boolean {
    return response.headers['content-type'].includes(this.contentType);
  }

  public hasJwtToken(): boolean {
    const token = Request.getAuthToken();
    return this.apiType === 'api' && !!token && !Request.isTokenExpired(token);
  }

  public send(
    path: string,
    method: Method = 'GET',
    data: sendDataType = null,
    headers: headersType = null
  ): AxiosPromise {
    const requestHeaders: HttpRequestHeader = {
      'Content-Type': this.contentType,
      ...headers,
    };

    if (this.hasJwtToken()) {
      requestHeaders.Authorization = `Bearer ${Request.getAuthToken()}`;
    }

    const config: AxiosRequestConfig = {
      url: `${this.url}${path}`,
      method,
      headers: requestHeaders,
      data,
    };

    return axios(config);
  }
}

export default Request;
