/* eslint-disable arrow-parens */
import {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  MouseEventHandler,
  ReactElement,
  useEffect,
  useState,
} from "react";
import Button from "../common/Button/Button";
import { AUTH_STAGES_MAP, AUTH_STAGE_NAMES } from "./AuthContent.constant";
import { ContentProps } from "../../interfaces";
import { validationAuthValues } from "./AuthContent.utils";
import AuthForm from "../AuthForm/AuthForm";
import { useDispatch } from "react-redux";
import useQuery from "../../hooks/useQuery";
import { useTranslation } from "react-i18next";
import { setClearSearchString } from "../../redux/appReducer/appReducer.slice";
import useShallowEqualSelector from "../../utils/useShallowEqualSelector";
import { MENU_ID_MAP } from "../Menu/Menu.constant";
import classes from "./AuthContent.module.scss";
import { useParams } from "react-router-dom";
import useOpenView from "../../hooks/useOpenView";
import { contentUrl } from "../../config/url.config";
import routes from "../../pages/Dashboard/routes";
import onDerivedBrainKeyAccount from "../../api/v2/blockchain/utils/onDerivedBrainkeyAccount";

const AuthContent: FC<ContentProps> = (): ReactElement => {
  const { activeMenuId } = useParams<Record<string, string>>();

  const { t } = useTranslation();

  const actions = useOpenView();
  const queryString = useQuery();
  const dispatch = useDispatch();

  const [validation, setValidation] = useState<Record<string, boolean>>({});
  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [seed, setSeed] = useState<string>("");

  const user = useShallowEqualSelector((state) => state.appReducer.user);

  useEffect(() => {
    // hack for redirect from auth page to profile
    if (user && activeMenuId === MENU_ID_MAP.PROFILE) {
      actions.open(contentUrl(MENU_ID_MAP.PROFILE));
    }

    // eslint-disable-next-line
  }, [user, dispatch]);

  useEffect(() => {
    const referral = queryString?.get("referral") || "";

    if (referral) {
      actions.open(routes.REGISTRATION, undefined, referral);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeRepeatPassword: ChangeEventHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setRepeatPassword(event.target.value);
  };

  const handleChangeSeed: ChangeEventHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setSeed(event.target.value);
  };

  const handleChangePassword: ChangeEventHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => setPassword(event.target.value);

  const handleSubmitForm: MouseEventHandler = async () => {
    const validationValues: Record<string, string | boolean> = {};

    validationValues.password = password;
    validationValues.repeatPassword = repeatPassword;

    const newValidation = validationAuthValues(
      validation,
      validationValues,
      AUTH_STAGES_MAP.AUTH
    );

    if (newValidation !== validation) {
      setValidation(newValidation);
    }

    if (Object.values(newValidation).some((value) => !value)) {
      return;
    }

    actions.open(routes.PROFILE);

    await onDerivedBrainKeyAccount(
      seed,
      password,
      "@email.com",
      dispatch,
      actions
    );
  };

  const handleChangeMode = () => {
    dispatch(setClearSearchString(true));

    actions.open(routes.REGISTRATION);
  };

  return (
    <section className={classes.authPage}>
      <div className={classes.content}>
        <AuthForm
          validation={validation}
          handleChangePassword={handleChangePassword}
          handleChangeRepeatPassword={handleChangeRepeatPassword}
          handleChangeSeed={handleChangeSeed}
          handleSubmitForm={handleSubmitForm}
          disabled={!seed || !password}
          values={{ seed, password, repeatPassword }}
        />
        {/* <div className={classes.oauth}>
          <p className={classes.oauthTitle}>{t('reg_with')}</p>
          <div className={classes.oauthButtons}>
            <OauthButton type={OAUTH_ID.FACEBOOK} />
            <OauthButton type={OAUTH_ID.GOOGLE} />
          </div>
        </div>
      */}
      </div>
      <div className={classes.footer}>
        <Button
          onClick={handleChangeMode}
          className={classes.regButton}
          type="default"
        >
          {t(AUTH_STAGE_NAMES.REGISTRATION)}
        </Button>
      </div>
    </section>
  );
};

export default AuthContent;
