import iDB from '../../../../models/IndexedDb/IndexedDb.init';
import { IDBPTransaction } from 'idb';

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

export const transactionUpdateKeys = (): IDBPTransaction =>
  // @ts-ignore
  iDB.instance.getTransaction(['wallet', 'private_keys'], 'readwrite');

export default transactionUpdateKeys;
