import getWalletStore from './getWalletStore';
import updateWallet from './updateWallet';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const setBrainKeyBackupDate = async () => {
  const wallet = await getWalletStore();

  wallet.brainkey_backup_date = new Date();

  return updateWallet();
};

export default setBrainKeyBackupDate;
