import { FC, ReactElement } from 'react';
import { CheckIconProps } from '../../../types/components.types';

const CheckIcon: FC<CheckIconProps> = ({ width, height }): ReactElement => (
  <svg
    width={width || '36'}
    height={height || width || '36'}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.8429 5.46222C31.3052 5.76445 31.4349 6.38419 31.1326 6.84644L15.1069 31.3563C14.9467 31.6014 14.6871 31.764 14.3966 31.801C14.1061 31.8381 13.814 31.746 13.5973 31.549L3.22772 22.1221C2.81907 21.7506 2.78895 21.1182 3.16046 20.7095C3.53196 20.3009 4.16441 20.2708 4.57307 20.6423L14.0748 29.2802L29.4587 5.75194C29.7609 5.28969 30.3807 5.15998 30.8429 5.46222Z"
      fill="var(--lightGreen)"
    />
  </svg>
);

CheckIcon.defaultProps = {
  width: '',
  height: '',
};

export default CheckIcon;
