// eslint-disable-next-line no-unused-vars
import { resolveStringTemplate } from '../utils/utils.global';

// eslint-disable-next-line no-unused-vars
type SearchFn<T = string> = (searchValue: T, page?: number) => Readonly<string>;

export const MAP_ENDPOINTS: Readonly<Record<string, string>> = {
  CREATE_POINT: '/wifi-point/',
  GET_VIEWPORT_POINTS: '/wifi-point/map',
  APPROVE_POINT: '/wifi-point/approve/create',
  CHANGE_POINT: '/wifi-point/changes-offer',
  UNDEFINED_POINT: '/wifi-point/[[id]]/not-exists',
  POINT: '/wifi-point/',
};

export const AUTH_ENDPOINTS: Readonly<Record<string, string>> = {
  SIGN_UP: '/signup/email',
  SIGN_IN: '/signin/email',
  SIGN_IN_GOOGLE: '/signin/google',
  SIGN_IN_URL_FACEBOOK: '/signin/facebook/authorization-url',
  SIGN_IN_FACEBOOK: '/signin/facebook',
};

export const USER_ENDPOINTS: Readonly<Record<string, string>> = {
  REFRESH_USER: '/user/me',
  SUB_UPDATE: '/user/update/subscription',
  INIT_RECOVERY_PASSWORD: '/user/password-recovery/init',
  FINISH_RECOVERY_PASSWORD: '/user/password-recovery/finish',
  PLAN_LIST: '/plan/get',
  UPLOAD_AVATAR: '/user/avatar',
  EDIT_USER: '/user/',
  REFERRAL_TRANSACTIONS: '/user/me/referral/transactions',
  REFERRAL_LIST: '/user/me/referral',
};

export const WALLET_ENDPOINTS: Record<string, string> = {
  TRANSACTIONS_LIST: '/transactions',
  TRANSACTION_CREATE: '/transaction',
};

export const getUndefinedWifiPointEndpointById = (id: string): string =>
  resolveStringTemplate(MAP_ENDPOINTS.UNDEFINED_POINT, () => id);

export const getSearchReferralEndpoint: SearchFn = (
  searchValue: string,
  page = 1
): Readonly<string> => {
  if ((!page && !searchValue) || !page) {
    return `${USER_ENDPOINTS.REFERRAL_LIST}?usernameOrEmail=${searchValue}`;
  }

  if (!searchValue) {
    return `${USER_ENDPOINTS.REFERRAL_LIST}?page=${page}`;
  }

  return `${USER_ENDPOINTS.REFERRAL_LIST}?usernameOrEmail=${searchValue}&page=${page}`;
};

export const pointUrlApi = (id: string): string =>
  `${MAP_ENDPOINTS.POINT}${id}`;
