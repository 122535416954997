import indexedDbActions from './IndexedDb.init';
import privateKeyActions from '../../api/v2/blockchain/actions/privateKeyActions';
import Immutable from 'immutable';
import accountRefsActions from '../../api/v2/blockchain/actions/AccountRefsActions';
import loadWalletDbData from '../../api/v2/blockchain/utils/loadWalletDbData';

export const loadLinkedAccounts = (): Promise<
  Record<string, any>[] | undefined
> => indexedDbActions.api.loadData('linked_accounts');

export const loadPrivateKeys = (): Promise<Immutable.Map<unknown, unknown>> =>
  privateKeyActions.onLoadDbData();

export const loadRefs = (): Promise<Immutable.Set<unknown>> =>
  accountRefsActions.onLoadDbData();

export const loadWalletData = (): Promise<any> => loadWalletDbData();
