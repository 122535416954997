import { ChangeEventHandler, FC, useEffect, useState } from 'react';
import Input from '../../../common/Input/Input';
import NestedTable from '../../NestedRefTable/NestedRefTable';
import classes from './Structure.module.scss';
import { bonusList } from '../../ReferralSystemContent.constant';
import {
  IReferral,
  IReferralResponse,
} from '../../ReferralSystemContent.interface';
import { fetchReferralList } from '../../../../api/AppApi/AppApi.api';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import SearchIcon from '../../../Icons/SearchIcon/SearchIcon';
import { useTranslation } from 'react-i18next';
import BonusItem from '../../BonusItem/BonusItem';
import ReferralCopyElement from '../../ReferralCopyElement/ReferralCopyElement';

interface Props {
  // eslint-disable-next-line no-unused-vars
  onSearch: (page: number, searchValue?: string) => Promise<unknown>;
}

const resort = (items: Array<IReferral>) =>
  items.map(
    ({
      level,
      key,
      username,
      email,
      countOfUsersInStructure,
      edcReferralRevenue,
    }) => ({
      level,
      key,
      username,
      email,
      countOfUsersInStructure,
      edcReferralRevenue,
    })
  );

const Structure: FC<Props> = ({ onSearch }) => {
  const { t } = useTranslation();

  const [items, setItems] = useState<IReferral[]>([]);
  const [pageSize, setPageSize] = useState<number>(15);
  const [page, setPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>('');
  const [total, setTotal] = useState<number>(items.length);
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleSearch: ChangeEventHandler<HTMLInputElement> = async event => {
    setSearchValue(event.target.value);
    setLoading(true);

    setSearchValue(event.target.value);

    const searchResponse = (await onSearch(
      page,
      event.target.value
    )) as IReferralResponse;

    setLoading(false);

    if (searchResponse && searchResponse.items) {
      setTotal(searchResponse.totalCount);
      setItems(resort(searchResponse.items));
      setPageSize(searchResponse.itemsPerPage);
      setPage(searchResponse.currentPageNumber);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchReferralList(page)
      .then(result => {
        if (!result) {
          throw new Error('invalid load');
        }

        setTotal(result.totalCount);
        setItems(resort(result.items));
        setPageSize(result.itemsPerPage);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page]);

  const handleChangePagination = (pagination: TablePaginationConfig) => {
    const currentPage = Number(pagination.current);

    if (currentPage !== page) {
      setPage(currentPage);
    }
  };

  return (
    <>
      <div className={classes.refBlock}>
        <ReferralCopyElement />
      </div>
      <p className={classes.text}>{t('refPage_about')}</p>
      <div className={classes.actions}>
        <Input
          prefix={<SearchIcon />}
          onChange={handleSearch}
          className={classes.searchInput}
          value={searchValue}
          placeholder={t('refPage_placeholder')}
        />
      </div>
      <NestedTable
        items={items}
        page={page}
        pageSize={pageSize}
        isLoading={isLoading}
        total={total}
        handleChangePagination={handleChangePagination}
      />
      <div className={classes.about}>
        <p className={classes.aboutTitle}>{t('refPage_question')}</p>
        <ul className={classes.bonusList}>
          {bonusList.map(bonus => (
            <li key={bonus.id}>
              <BonusItem value={bonus.value}>{bonus.text}</BonusItem>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Structure;
