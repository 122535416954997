import iDB from '../../../../models/IndexedDb/IndexedDb.init';
import Immutable from 'immutable';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const loadNoAccountRefs = async () => {
  if (!iDB.instance) {
    throw new Error('iDB.instance invalid');
  }

  const map = await iDB.instance.getProperty<Record<string, any>>(
    'no_account_refs',
    []
  );

  return map ? Immutable.Map(map) : Immutable.Map();
};

export default loadNoAccountRefs;
