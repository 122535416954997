import { notification } from 'antd';
import { AxiosError } from 'axios';
import { Coords } from 'google-map-react';
import parse from 'html-react-parser';
import i18n from '../i18n';
import RequestError from '../models/RequestError/RequestError';
import { requestResponseType } from '../types';

export const resolveTemplate = (
  value: string,
  attributes: Record<string, string>,
  tag = 'span'
): string | JSX.Element | JSX.Element[] => {
  if (!attributes || typeof attributes !== 'object') {
    throw new Error('Ivalid attributes for resolve template');
  }

  const attributesKeys: Array<string> = Object.keys(attributes);

  const getTemplate = (val: string): string =>
    attributesKeys.reduce((str: string, chunk: string, index): string => {
      const attributeValue = attributes[chunk];

      let newStr = `${str} ${chunk}="${attributeValue}"`;

      if (index === attributesKeys.length - 1) {
        newStr += `>${val}</${tag}>`;
      }

      return newStr;
    }, `<${tag}`);

  return parse(
    value.replace(/\[\[(.+?)\]\]/g, (_, symbol) => getTemplate(symbol))
  );
};

export const resolveStringTemplate = (
  string: string,
  getValue = (val: string) => val
): string => {
  if (typeof getValue !== 'function') {
    throw new Error('invalid get value function');
  }

  return string.replace(/\[\[(.+?)\]\]/g, (_, value) => getValue(value));
};

export const isContain = (
  target: Element,
  element: Element | EventTarget,
  className?: string
): boolean => {
  if (!target || !element) {
    return true;
  }

  const isStringClassName =
    element &&
    (element as Element)?.className &&
    typeof (element as Element).className === 'string';

  if (className && isStringClassName) {
    return true;
  }

  if (target.contains(element as Element)) {
    return true;
  }

  return Array.from(target.children).some(child => isContain(child, element));
};

export const getUrlWithQueryParams = (
  endpoint: Readonly<string>,
  queryParams: Record<string, string>
): Readonly<string> => {
  if (!queryParams) {
    throw new Error('queryParams is not object with params');
  }

  const queryStringKeys = Object.keys(queryParams);

  if (!queryStringKeys.length) {
    return endpoint;
  }

  const queryString = queryStringKeys.reduce((acc: string, value: string) => {
    const andSymbol = acc ? '&' : '';

    return `${acc}${andSymbol}${value}=${queryParams[value]}`;
  }, '');

  return `${endpoint}?${queryString}`;
};

export const getCurrentCoords = (): Promise<Coords> =>
  new Promise((resolve, reject) => {
    if (!navigator.geolocation?.getCurrentPosition) {
      reject(null);
    }

    navigator.geolocation.getCurrentPosition(
      position => {
        resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      error => {
        // eslint-disable-next-line
        console.log(error);
        reject(error);
      },
      {
        maximumAge: 60000,
        timeout: 5000,
        enableHighAccuracy: true,
      }
    );
  });

// eslint-disable-next-line no-unused-vars
export const throttle = (
  callback: () => void,
  limit: number
  // eslint-disable-next-line no-unused-vars
): ((...arg: any[]) => void) => {
  let wait = false;
  return (...arg: []) => {
    if (wait) {
      return;
    }

    callback.apply(this, arg);
    wait = true;
    setTimeout(() => {
      wait = false;
    }, limit);
  };
};

export const handleRequestError = (
  customError: AxiosError<any> | RequestError | Error,
  showMessage = false
): [requestResponseType, boolean] => {
  // eslint-disable-next-line no-console
  console.error(customError);

  if (customError instanceof RequestError) {
    if (showMessage) {
      notification.error({
        message: 'Request error',
        description: customError.message,
      });
    }

    return [customError.getErrorResponse(), true];
  }

  const errorMessage = {
    error: 'Error',
    message: customError.message || 'something wrong',
  };

  if (!(customError as AxiosError).response) {
    if (showMessage) {
      notification.error({
        message: errorMessage.error,
        description: errorMessage.message,
      });
    }

    return [errorMessage, true];
  }

  const { response = null } = customError as AxiosError<any>;

  const responseData = response?.data;

  const messageValue =
    typeof responseData === 'object' && responseData
      ? responseData?.message || responseData?.error
      : responseData;

  const message = messageValue || errorMessage;

  if (showMessage && typeof message === 'string' && message) {
    notification.error({
      message: i18n.t('backendMessageError_message'),
      description: message,
    });
  }

  const err = new RequestError('invalid response', message);

  if (response && response.headers['content-type'].includes('text/html')) {
    err.setErrorResponse({
      error: response.status,
      message: response.statusText || errorMessage.message,
    });
  }

  return [err.getErrorResponse(), true];
};

export const moneyValueNormalized = (value: string): string | null => {
  let nextValue = value;

  if (/\d+\.0\d+/gi.test(`${nextValue}`)) {
    nextValue = parseFloat(nextValue) as any;
  }

  const isRemoveAction = nextValue.length < `${value}`.length;

  if (nextValue[nextValue.length - 1] === '.' && isRemoveAction) {
    nextValue = nextValue.slice(0, -1);
  }

  if (
    !/^[+-]?\d+(\.\d+)?$/.test(nextValue) &&
    nextValue !== '' &&
    nextValue[nextValue.length - 1] !== '.'
  ) {
    return null;
  }

  return nextValue;
};
