import {
  configureStore,
  EnhancedStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import appReducer from './appReducer/appReducer.slice';
import mapReducer from './mapReducer/mapReducer.slice';
import walletReducer from './walletReducer/walletReducer.slice';
import lockReducer from './lockReducer/lockReducer.slice';

const middleware = getDefaultMiddleware({
  immutableCheck: true,
  serializableCheck: true,
  thunk: true,
});

export const createTestStore = (): EnhancedStore =>
  configureStore({
    reducer: { appReducer, mapReducer, walletReducer, lockReducer },
    middleware,
    devTools: process.env.NODE_ENV !== 'production',
  });

const store = configureStore({
  reducer: { appReducer, mapReducer, walletReducer, lockReducer },
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
