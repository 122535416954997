import { FC } from 'react';

const CopyLinkIcon: FC = () => (
  <svg
    width="15"
    height="19"
    viewBox="0 0 15 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.09375 16.6953C0.09375 18.0781 0.796875 18.7891 2.17188 18.7891H9.25781C10.6328 18.7891 11.3359 18.0703 11.3359 16.6953V15.2734H12.8281C14.2031 15.2734 14.9062 14.5625 14.9062 13.1797V6.77344C14.9062 6.17969 14.7812 5.78125 14.3594 5.35938L10.1406 1.10938C9.73438 0.695312 9.3125 0.5625 8.78125 0.5625H5.74219C4.375 0.5625 3.66406 1.27344 3.66406 2.65625V4.07031H2.17188C0.8125 4.07031 0.09375 4.78906 0.09375 6.16406V16.6953ZM10.8047 9.125L6.32031 4.60938C5.89844 4.17969 5.55469 4.07031 4.90625 4.07031H4.41406V2.67188C4.41406 1.8125 4.88281 1.3125 5.78125 1.3125H9.17188V5.46875C9.17188 6.22656 9.53125 6.54688 10.2578 6.54688H14.1562V13.1641C14.1562 14.0469 13.6875 14.5234 12.7891 14.5234H11.3359V10.5938C11.3359 9.88281 11.2344 9.5625 10.8047 9.125ZM9.875 5.39062V1.78906L13.9062 5.84375H10.3281C10 5.84375 9.875 5.71875 9.875 5.39062ZM0.84375 16.6719V6.1875C0.84375 5.32031 1.3125 4.82812 2.21094 4.82812H5.05469V9.35156C5.05469 10.1484 5.45312 10.5078 6.21094 10.5078H10.5859V16.6719C10.5859 17.5625 10.1172 18.0312 9.21875 18.0312H2.20312C1.3125 18.0312 0.84375 17.5625 0.84375 16.6719ZM6.28906 9.78906C5.92188 9.78906 5.77344 9.64844 5.77344 9.28125V5.10938L10.4219 9.78906H6.28906Z"
      fill="#4778A1"
    />
  </svg>
);

export default CopyLinkIcon;
