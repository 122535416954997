import { Apis } from '2ab2-ws-js';
import ManageDatabase from './IndexedDb.utils';
import { IDBPDatabase, IDBPTransaction, openDB } from 'idb';

const DB_PREFIX = '2ab2_db';
const DB_VERSION = 2; // Initial value was 1
const currentWalletName = 'default';

/** Usage: openIndexDB.then( db => ... */
class IndexedDbRoot {
  private manager: ManageDatabase = new ManageDatabase();

  private db: IDBPDatabase | null = null;

  private readonly databaseName: string;

  constructor(databaseName = IndexedDbRoot.getDatabaseName()) {
    this.databaseName = databaseName;
    this.openDatabase();
  }

  getDatabaseName(): string {
    return this.databaseName;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getDatabase() {
    return this.db;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getTransaction(
    name: string | string[],
    mode: IDBTransactionMode
  ): IDBPTransaction {
    if (!this.db) {
      throw new Error('db undefined');
    }

    // @ts-ignore
    return this.db.transaction(name, mode);
  }

  /** @return promise */
  async getProperty<T = string>(
    name: string,
    defaultValue: any = ''
  ): Promise<T> {
    if (!this.db) {
      throw new Error('db not init');
    }

    const transaction = this.db.transaction(['properties'], 'readonly');

    const store = transaction.objectStore('properties');

    try {
      return (await store.get(name)) || defaultValue;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      return defaultValue;
    }
  }

  /** @return promise */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async setProperty<T = any>(name: string, value: T): Promise<IDBValidKey | T> {
    if (!this.db) {
      throw new Error('db not init');
    }

    const transaction = this.db.transaction(['properties'], 'readwrite');

    const store = transaction.objectStore('properties');

    try {
      return await store.put({ name, value });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      return value;
    }
  }

  static async deleteDatabase(): Promise<void> {
    const databases = await indexedDB.databases();

    const promises = databases.map(
      db =>
        new Promise((resolve, reject) => {
          const req = indexedDB.deleteDatabase(db.name || '');
          req.onsuccess = resolve;
          req.onerror = reject;
          req.onblocked = reject;
        })
    );
    // eslint-disable-next-line no-console
    return Promise.all(promises).then(console.log).catch(console.error);
  }

  close(): void {
    // @ts-ignore
    this.db.close();
    this.db = null;
  }

  static upgrade(db: IDBPDatabase, oldVersion: number): void {
    if (oldVersion === 0) {
      db.createObjectStore('properties', { keyPath: 'name' });
      db.createObjectStore('wallet', { keyPath: 'public_name' });
      ManageDatabase.autoIncrementUnique(db, 'private_keys', 'pubkey');
      db.createObjectStore('linked_accounts', { keyPath: 'name' });
    }
    if (oldVersion < 2) {
      // Cache only, do not backup...
      db.createObjectStore('cached_properties', { keyPath: 'name' });
    }
  }

  static getDatabaseName(
    currentWallet = currentWalletName,
    chainId = Apis.instance().chain_id
  ): string {
    return [
      DB_PREFIX,
      chainId ? chainId.substring(0, 6) : '',
      currentWallet,
    ].join('_');
  }

  async openDatabase(): Promise<IDBPDatabase> {
    // eslint-disable-next-line no-return-await
    this.db = await openDB(this.databaseName, DB_VERSION, {
      upgrade(database, version) {
        IndexedDbRoot.upgrade(database, version);
      },
    });

    return this.db;
  }
}

export default IndexedDbRoot;
