// import { notification } from 'antd';
import { JSXElementConstructor, ReactElement, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useOpenView from '../../hooks/useOpenView';
import useShallowEqualSelector from '../../utils/useShallowEqualSelector';
import { MENU_ID_MAP } from '../Menu/Menu.constant';
import { contentUrl } from '../../config/url.config';
import LockContent from '../LockContent/LockContent';

const withAuth =
  (Component: JSXElementConstructor<any>) =>
  (
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    props: any
  ): ReactElement | null => {
    const actions = useOpenView();
    const timerRedirect = useRef<ReturnType<typeof setTimeout>>();
    const { activeMenuId } = useParams<Record<string, string>>();

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const isLocked = useShallowEqualSelector(
      state => state.lockReducer.isLock
    );

    const user = useShallowEqualSelector(state => state.appReducer.user);

    const { errorMessage, errorType } = useShallowEqualSelector(state => ({
      errorType: state.appReducer.errorType,
      errorMessage: state.appReducer.errorMessage,
      user: state.appReducer.user,
    }));

    useEffect(() => {
      if (timerRedirect.current) {
        clearTimeout(timerRedirect.current);
      }

      if (user) {
        return;
      }

      timerRedirect.current = setTimeout(() => {
        const menuIdForRedirect = MENU_ID_MAP.AUTH;

        if (activeMenuId !== menuIdForRedirect) {
          actions.open(contentUrl(menuIdForRedirect));
        }
      }, 2000);

      // eslint-disable-next-line consistent-return
      return () => {
        if (timerRedirect.current) {
          clearTimeout(timerRedirect.current);
        }
      };
    }, [activeMenuId, dispatch, user, errorMessage, errorType, t, actions]);

    if (!user) {
      return null;
    }

    if (user && isLocked) {
      return <LockContent />;
    }

    return <Component {...props} isLocked={isLocked} />;
  };

export default withAuth;
