import clsx from 'clsx';
import { FC, MouseEventHandler, ReactElement, useMemo } from 'react';
import { SubscribeProps } from '../../../interfaces';
import { resolveTemplate } from '../../../utils/utils.global';
import Button from '../../common/Button/Button';
import { SUBSCRIBE_TYPES } from '../SubscribeContent.constant';
import { fetchSubOnPlan } from '../../../redux/walletReducer/walletReducer.thunk';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classes from './SubscribeItem.module.scss';
import useShallowEqualSelector from '../../../utils/useShallowEqualSelector';

const SubscribeItem: FC<SubscribeProps> = ({
  name,
  id: planId,
  activeName,
  isDefault,
  activeItemId,
  amount,
  term,
  description,
  meta,
  onChoose,
}): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useShallowEqualSelector(state => state.appReducer.user);

  const plansList = useShallowEqualSelector(
    state => state.appReducer.plansList
  );

  const { countDaysOfSubscription = 0 } = user || {};
  const { descriptionColor } = meta;

  const isActive = activeItemId === planId;

  const title = name?.toUpperCase();

  const handleCancelSub: MouseEventHandler = () => {
    if (!plansList) {
      return;
    }

    const { id: freePlanId = '' } =
      plansList.find(sub => sub.name === SUBSCRIBE_TYPES.FREE) || {};

    dispatch(
      fetchSubOnPlan({
        planId: freePlanId,
        message: 'Отмена подписки',
        refreshUser: true,
      })
    );
  };

  const desc = useMemo(
    () =>
      description.map((string: string) => (
        <li className={classes.text} key={string}>
          {resolveTemplate(t(string), { style: `color:${descriptionColor};` })}
        </li>
      )),
    [description, descriptionColor, t]
  );

  const statusName = useMemo(() => {
    if (isActive) {
      return activeName;
    }

    if (isDefault) {
      return 'by default';
    }

    return '';
  }, [isDefault, isActive, activeName]);

  const isChoosenSub = !isActive || (isActive && isDefault);

  return (
    <div className={clsx(classes.subscribeItem, isActive && classes.active)}>
      <div className={classes.header}>
        <div className={classes.subscribeItemNameBlock}>
          <span style={{ color: descriptionColor }} className={classes.title}>
            {title}
          </span>
          {!isDefault && !!term && (
            <span className={classes.term}>{` / ${t(term)}`}</span>
          )}
        </div>
        {!isDefault && !isActive ? (
          <div className={classes.price}>${amount}</div>
        ) : (
          (isDefault || !!activeName) && (
            <div
              className={clsx({
                [classes.subInfoBlock]: isActive,
              })}
            >
              {isActive && !!countDaysOfSubscription && (
                <div className={classes.endDate}>
                  {countDaysOfSubscription} {t('days_dayLeft')}
                </div>
              )}
              <div className={classes.default}>{statusName}</div>
            </div>
          )
        )}
      </div>
      <div className={classes.body}>
        <div className={classes.subItemCol}>
          <ul className={classes.description}>{desc}</ul>
        </div>
        <div className={classes.subItemCol}>
          {isChoosenSub ? (
            <Button
              disabled={isActive}
              className={clsx(classes.chooseButton, {
                [classes.hidden]: isActive && isDefault,
              })}
              onClick={onChoose}
            >
              {t('subItem_chooseButton')}
            </Button>
          ) : (
            <Button
              className={classes.buttonCancel}
              onClick={handleCancelSub}
              type="link"
            >
              {t('subItem_cancelButton')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

SubscribeItem.defaultProps = {
  activeName: undefined,
};

export default SubscribeItem;
