import { FC, ReactElement } from 'react';
import { UserIconProps } from '../../../types/components.types';

const UserIcon: FC<UserIconProps> = ({
  onClick,
  className,
  isActive,
}): ReactElement => (
  <svg
    className={className}
    onClick={onClick || undefined}
    width="42"
    height="43"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42Z"
      fill="var(--darkLightGreen)"
      stroke={isActive ? 'var(--lightLightOrangeColor)' : ''}
      strokeWidth={1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.9998 11.5049C18.1859 11.5049 15.9048 13.786 15.9048 16.5999C15.9048 19.4138 18.1859 21.6949 20.9998 21.6949C23.8137 21.6949 26.0948 19.4138 26.0948 16.5999C26.0948 13.786 23.8137 11.5049 20.9998 11.5049ZM16.8548 16.5999C16.8548 14.3107 18.7105 12.4549 20.9998 12.4549C23.289 12.4549 25.1448 14.3107 25.1448 16.5999C25.1448 18.8891 23.289 20.7449 20.9998 20.7449C18.7105 20.7449 16.8548 18.8891 16.8548 16.5999ZM14.1414 29.9468C14.1414 27.3868 14.8159 25.6208 15.9614 24.4919C17.1084 23.3615 18.8031 22.7952 20.9996 22.7952C23.1962 22.7952 24.8909 23.3615 26.0379 24.4919C27.1835 25.6208 27.858 27.3869 27.858 29.9468C27.858 30.2092 28.0706 30.4218 28.333 30.4218C28.5953 30.4218 28.808 30.2092 28.808 29.9469C28.808 27.2269 28.0892 25.1796 26.7048 23.8152C25.3217 22.4523 23.3498 21.8452 20.9996 21.8452C18.6495 21.8452 16.6776 22.4523 15.2946 23.8152C13.9102 25.1796 13.1914 27.2269 13.1914 29.9468C13.1914 30.2092 13.4041 30.4218 13.6664 30.4218C13.9287 30.4218 14.1414 30.2092 14.1414 29.9468Z"
      fill="white"
    />
  </svg>
);

UserIcon.defaultProps = {
  onClick: undefined,
  className: '',
};

export default UserIcon;
