export const REG_EXP: Readonly<Record<string, RegExp>> = {
  EMAIL:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

export const AUTH_FORM: Readonly<Record<string, number | string>> = {
  MIN_PASSWORD: 4,
};

export const MEDIA_QUERIES: Readonly<Record<string, string>> = {
  isMobile: '(max-width: 600px)',
};

export const EXPLORE_LINK: Readonly<string> =
  'https://explorer.blockchain.mn/operation/#';
