import classes from './BonusItem.module.scss';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  value: number;
  children: string;
}

const BonusItem: FC<Props> = ({ value, children }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.value}>{value} %</div>
          <div className={classes.commission}>
            {t('refBonusList_commission')}
          </div>
        </div>
        <div className={classes.text}>{t(children)}</div>
      </div>
    </div>
  );
};

export default BonusItem;
