import { ChangeEventHandler, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../common/Button/Button';
import Input from '../common/Input/Input';
import classes from './SeedContent.module.scss';
import SeedCopyElement from './SeedCopyElement/SeedCopyElement';
import validatePassword from '../../api/v2/blockchain/utils/validatePassword';
import getBrainKey from '../../api/v2/blockchain/utils/getBrainKey';
import { notification } from 'antd';
import setBrainKeyBackupDate from '../../api/v2/blockchain/utils/setBrainkeyBackupDate';
import useOpenView from '../../hooks/useOpenView';
import { contentUrl } from '../../config/url.config';
import { MENU_ID_MAP } from '../Menu/Menu.constant';
import withSeedGuard, { SEED_STATE_STORAGE_KEY } from './withSeedGuard';

const SeedContent = (): ReactElement => {
  const { t } = useTranslation();
  const actions = useOpenView();

  const [brainKey, setBrainKey] = useState<string | null>(null);
  const [isSeedComplete, setSeedComplete] = useState<boolean>(false);

  const [password, setPassword] = useState<string>('');

  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    setPassword(event.target.value);
  };

  const handleSubmitSeed = async () => {
    await setBrainKeyBackupDate();

    localStorage.setItem(SEED_STATE_STORAGE_KEY, 'DONE');

    actions.open(contentUrl(MENU_ID_MAP.PROFILE));
  };

  const handleSubmit = async () => {
    const isValid = await validatePassword(password, true);

    if (isValid) {
      const brainKeyValue = await getBrainKey();

      setBrainKey(brainKeyValue);
      setSeedComplete(true);

      return;
    }

    notification.error({
      message: t('backendMessageError_message'),
      description: t('message_passwordBad'),
    });
  };

  if (isSeedComplete) {
    return (
      <div>
        <p>{t('seed_done')}</p>
        <SeedCopyElement value={brainKey} />
        <div className={classes.footer}>
          <Button
            onClick={handleSubmitSeed}
            className={classes.submit}
            type="default"
          >
            {t('seed_seedDoneBtn')}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <section className={classes.content}>
      <div>
        <div className={classes.formItem}>
          <label htmlFor="password" className={classes.labelForm}>
            {t('regForm_password')}
          </label>
          <Input
            type="password"
            className={classes.input}
            onChange={handleChange}
            value={password}
          />
        </div>
      </div>
      <div className={classes.footer}>
        <Button
          onClick={handleSubmit}
          className={classes.submit}
          type="default"
        >
          {t('seed_show')}
        </Button>
      </div>
    </section>
  );
};

export default withSeedGuard(SeedContent);
