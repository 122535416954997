import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './SelectTime.module.scss';

interface ISelectTimeProps {
  // eslint-disable-next-line no-unused-vars
  onSelect: (value: number) => void;
  disabled?: boolean;
}

interface ITimeItem {
  name: string;
  value: number; // minutes
}

const items: ITimeItem[] = [
  {
    name: 'bill_time5m',
    value: 5,
  },
  {
    name: 'bill_time15m',
    value: 15,
  },
  {
    name: 'bill_time30m',
    value: 30,
  },
  {
    name: 'bill_time1h',
    value: 60,
  },
  {
    name: 'bill_time2h',
    value: 120,
  },
  {
    name: 'bill_time4h',
    value: 240,
  },
];

const SelectTime: FC<ISelectTimeProps> = ({ onSelect, disabled }) => {
  const { t } = useTranslation();

  const createSelect = (value: number) => () => {
    onSelect(value);
  };

  return (
    <div className={classes.root}>
      {items.map(item => (
        <button
          disabled={disabled}
          className={clsx(classes.item, {
            [classes.disabled]: disabled,
          })}
          onClick={createSelect(item.value)}
          key={item.value}
        >
          {t(item.name)}
        </button>
      ))}
    </div>
  );
};

export default SelectTime;
