/* eslint-disable arrow-parens */
import { ChangeEventHandler, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../common/Button/Button";
import Input from "../common/Input/Input";
import classes from "./LockContent.module.scss";
import { useDispatch } from "react-redux";
import { unlock } from "../../redux/lockReducer/lockReducer.slice";
import { notification } from "antd";

const LockContent = (): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [password, setPassword] = useState<string>("");

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async () => {
    const isValid = true;

    if (isValid) {
      dispatch(unlock(false));

      return;
    }

    notification.error({
      message: t("backendMessageError_message"),
      description: t("message_passwordBad"),
    });
  };

  return (
    <section className={classes.content}>
      <div>
        <div className={classes.formItem}>
          <label htmlFor="password" className={classes.labelForm}>
            {t("regForm_password")}
          </label>
          <Input
            type="password"
            className={classes.input}
            onChange={handleChange}
            value={password}
          />
        </div>
      </div>
      <div className={classes.footer}>
        <Button
          onClick={handleSubmit}
          className={classes.submit}
          type="default"
        >
          {t("seed_unlock")}
        </Button>
      </div>
    </section>
  );
};

export default LockContent;
