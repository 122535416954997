import { ReactElement } from 'react';

const ChargeIcon = (): ReactElement => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9052 31.4983C10.7669 31.4983 10.6252 31.4651 10.4957 31.3968C10.1194 31.1973 9.9409 30.7546 10.0757 30.3486L13.7401 19.2483H6.8749C6.5494 19.2483 6.2519 19.0681 6.09965 18.7793C5.94915 18.4906 5.97015 18.1423 6.15565 17.8746L18.2761 0.374589C18.5141 0.0315894 18.9674 -0.0909107 19.3454 0.0700893C19.7286 0.238089 19.9404 0.65284 19.8511 1.06059L17.3871 12.2483H24.3749C24.7074 12.2483 25.0119 12.4373 25.1606 12.7366C25.3076 13.0358 25.2709 13.3928 25.0679 13.6571L11.5981 31.1571C11.4284 31.3793 11.1694 31.4983 10.9052 31.4983ZM8.54615 17.4983H14.9511C15.2329 17.4983 15.4954 17.6331 15.6599 17.8606C15.8244 18.0881 15.8699 18.3803 15.7806 18.6481L13.3481 26.0138L22.5969 13.9983H16.2986C16.0344 13.9983 15.7824 13.8776 15.6161 13.6711C15.4499 13.4646 15.3869 13.1933 15.4429 12.9343L17.1859 5.02259L8.54615 17.4983Z"
      fill="var(--lightGreen)"
    />
  </svg>
);

export default ChargeIcon;
