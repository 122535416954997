import { generateNextKey } from "../utils/generateNextKey";
import createBlockchainAccount from "./createBlockainAccount";
import transactionUpdateKeys from "../utils/transactionUpdateKeys";
import { saveKeys } from "../utils/saveKeyToStorage";

// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
const createAccount = async <T extends Record<string, any>>(
  accountName: string,
  referrer: string
): Promise<Record<string, any> | null> => {
  const ownerPrivate = await generateNextKey();
  const activePrivate = await generateNextKey();

  try {
    const blockainAccountResult = await createBlockchainAccount(
      ownerPrivate.private_key.toPublicKey().toPublicKeyString(),
      activePrivate.private_key.toPublicKey().toPublicKeyString(),
      accountName,
      referrer || undefined // registrar, //registrar_id,
    );

    const transaction = transactionUpdateKeys();

    try {
      await saveKeys([ownerPrivate, activePrivate], transaction);
    } catch {
      transaction.abort();
    }

    return blockainAccountResult;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);

    return null;
  }
};

export default createAccount;
