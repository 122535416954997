import { FC, useEffect, useState } from 'react';
import QRCode, { BaseQRCodeProps } from 'qrcode.react';
import classes from './QCode.module.scss';
import useShallowEqualSelector from '../../utils/useShallowEqualSelector';

interface IQCodePay extends BaseQRCodeProps {
  payType?: 'wallet' | 'sub';
}

const QCodePay: FC<IQCodePay> = ({ value, payType }) => {
  const [redirectUrl, setUrl] = useState<string | null>(null);

  const user = useShallowEqualSelector(state => state.appReducer.user);

  useEffect(() => {
    if (!user) {
      return;
    }

    setUrl(value);
  }, [value, user, payType]);

  if (!redirectUrl) {
    return null;
  }

  return (
    <div className={classes.component}>
      <QRCode value={redirectUrl} />
    </div>
  );
};

QCodePay.defaultProps = {
  payType: 'sub',
};

export default QCodePay;
