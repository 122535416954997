import { FC, ReactElement } from 'react';

const WifiIcon: FC = (): ReactElement => (
  <svg
    width="38"
    height="27"
    viewBox="0 0 38 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M37.4631 8.28754C37.5886 8.16528 37.6862 8.01725 37.7491 7.85365C37.8119 7.69005 37.8386 7.51477 37.8272 7.33988C37.8158 7.165 37.7666 6.99466 37.683 6.84061C37.5994 6.68656 37.4834 6.55246 37.3431 6.44754C32.0132 2.57225 25.5903 0.489667 19.0006 0.500043C12.4107 0.489149 5.98769 2.57179 0.658056 6.44754C0.517668 6.55246 0.401692 6.68656 0.318119 6.84061C0.234547 6.99466 0.185363 7.165 0.173957 7.33988C0.162551 7.51477 0.189195 7.69005 0.252051 7.85365C0.314908 8.01725 0.412484 8.16528 0.538056 8.28754C0.756544 8.50274 1.04431 8.63342 1.35013 8.65631C1.65595 8.6792 1.95997 8.59282 2.20806 8.41254C7.09591 4.88501 12.9727 2.99081 19.0006 3.00004C25.2681 3.00004 31.0681 5.00504 35.7931 8.41004C36.3031 8.78004 37.0156 8.73504 37.4631 8.28754Z"
      fill="var(--lightBlue)"
    />
    <path
      d="M32.0736 13.6775C32.6136 13.1375 32.5586 12.2325 31.9161 11.815C28.072 9.31814 23.5849 7.99269 19.0011 8.00003C14.2386 8.00003 9.80109 9.40003 6.08609 11.815C5.93747 11.9153 5.81322 12.0477 5.72246 12.2023C5.6317 12.3569 5.57675 12.5299 5.56164 12.7086C5.54653 12.8873 5.57165 13.067 5.63515 13.2347C5.69866 13.4024 5.79892 13.5537 5.92859 13.6775C6.1416 13.8854 6.41895 14.0148 6.71513 14.0443C7.01131 14.0738 7.30873 14.0018 7.55859 13.84C10.9737 11.6527 14.9456 10.4933 19.0011 10.5C23.0566 10.4933 27.0285 11.6527 30.4436 13.84C30.9561 14.17 31.6436 14.11 32.0736 13.6775ZM26.6161 19.135C27.1811 18.57 27.0786 17.6225 26.3661 17.26C24.0849 16.0991 21.5607 15.4959 19.0011 15.5C16.3511 15.5 13.8461 16.135 11.6361 17.26C10.9236 17.6225 10.8211 18.57 11.3861 19.135L11.4236 19.1725C11.8236 19.5725 12.4436 19.6475 12.9511 19.3975C14.8342 18.4746 16.904 17.9965 19.0011 18C21.1711 18 23.2261 18.5025 25.0511 19.4C25.5586 19.65 26.1761 19.575 26.5786 19.1725L26.6161 19.135ZM21.6511 24.1C22.1411 23.61 22.1461 22.8 21.5511 22.45C20.7798 21.9909 19.8987 21.7491 19.0011 21.75C18.1035 21.7491 17.2224 21.9909 16.4511 22.45C15.8561 22.8 15.8611 23.61 16.3511 24.1L18.1161 25.865C18.2322 25.9814 18.3701 26.0738 18.522 26.1368C18.6739 26.1998 18.8367 26.2323 19.0011 26.2323C19.1655 26.2323 19.3283 26.1998 19.4802 26.1368C19.632 26.0738 19.77 25.9814 19.8861 25.865L21.6536 24.0975L21.6511 24.1Z"
      fill="var(--lightBlue)"
    />
  </svg>
);

export default WifiIcon;
