import {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  ReactElement,
  useEffect,
  useRef,
} from 'react';
import SearchIcon from '../Icons/SearchIcon/SearchIcon';
import { useDispatch, useSelector } from 'react-redux';
import { GeometryParams, GlobalStore } from '../../interfaces';
import { setSearchPlace } from '../../redux/mapReducer/mapReducer.slice';
import LocationIcon from '../Icons/LocationIcon/LocationIcon';
import { ContentControlsProps } from '../../types/components.types';
import classes from './ContentControls.module.scss';
import {
  useMapContextApi,
  useMapInstance,
} from '../../api/MapContext/MapContext.hooks';
import { useTranslation } from 'react-i18next';
import { getCurrentCoords } from '../../utils/utils.global';

const ContentControls: FC<ContentControlsProps> = ({
  ownPosition,
  setOwnPosition,
}): ReactElement => {
  const { t } = useTranslation();

  const map = useMapInstance();
  const mapApi = useMapContextApi();
  const dispatch = useDispatch();

  const searchInputRef = useRef(null);
  const searchBox = useRef(null);

  const currentSearchPlace = useSelector<GlobalStore, string>(state => {
    const { mapReducer } = state;
    return mapReducer.currentSearchPlace;
  });

  const { places } = mapApi || {};

  const handleSearchPlace: ChangeEventHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (currentSearchPlace !== event.target.value) {
      dispatch(setSearchPlace(event.target.value));
    }
  };

  useEffect(() => {
    let watchId: number;

    if (navigator.geolocation?.watchPosition) {
      watchId = navigator.geolocation.watchPosition(({ coords }) => {
        if (!ownPosition || !mapApi || !map) {
          return;
        }

        if (
          ownPosition.lat !== coords.latitude ||
          ownPosition.lng !== coords.longitude
        ) {
          setOwnPosition({ lat: coords.latitude, lng: coords.longitude });
        }
      });

      if (!ownPosition) {
        getCurrentCoords().then(coords => {
          if (ownPosition || !mapApi || !map) {
            return;
          }

          setOwnPosition(coords);
          map.setZoom(15);
          map.setCenter(new mapApi.LatLng(coords.lat, coords.lng));
        });
      }
    }
    return () => {
      if (navigator.geolocation.clearWatch && watchId) {
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, [dispatch, mapApi, map, ownPosition, setOwnPosition]);

  useEffect(() => {
    if (!places || searchBox.current || !searchInputRef.current) {
      return undefined;
    }

    const handlePlacesChanged = () => {
      if (searchBox.current && map) {
        const placesList = (
          searchBox as Record<string, any>
        ).current.getPlaces();
        const [currentPlace] = placesList;
        const { location } = currentPlace.geometry as GeometryParams;

        map.setZoom(10);
        map.setCenter(location);
      }
    };

    searchBox.current = new places.SearchBox(searchInputRef.current);
    (searchBox as Record<string, any>).current.addListener(
      'places_changed',
      handlePlacesChanged
    );

    return () => {
      if (mapApi && searchBox.current) {
        mapApi.event.clearInstanceListeners(searchBox.current);
      }
    };
  }, [places, searchBox, searchInputRef, mapApi, map]);

  const handleSetOwnPosition = () => {
    if (!ownPosition || !map) {
      return;
    }
    map.setZoom(14);
    map.setCenter(ownPosition);
  };

  return (
    <div className={classes.contentControls}>
      <div className={classes.searchBox}>
        <div className={classes.prefix}>
          <SearchIcon />
        </div>
        <input
          ref={searchInputRef}
          className={classes.searchInput}
          onChange={handleSearchPlace}
          value={currentSearchPlace}
          placeholder={t('controls_searchPlaceholder')}
        />
      </div>
      <LocationIcon
        onClick={handleSetOwnPosition}
        className={classes.location}
      />
    </div>
  );
};

export default ContentControls;
