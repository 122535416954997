import { FC, ReactElement } from 'react';

const SearchIcon: FC = (): ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5992 10.4C16.5992 13.8241 13.8234 16.6 10.3992 16.6C6.97505 16.6 4.19922 13.8241 4.19922 10.4C4.19922 6.97579 6.97505 4.19995 10.3992 4.19995C13.8234 4.19995 16.5992 6.97579 16.5992 10.4ZM15.1246 15.8325C13.8601 16.9333 12.2075 17.6 10.3992 17.6C6.42277 17.6 3.19922 14.3764 3.19922 10.4C3.19922 6.4235 6.42277 3.19995 10.3992 3.19995C14.3757 3.19995 17.5992 6.4235 17.5992 10.4C17.5992 12.2082 16.9326 13.8608 15.8317 15.1254L20.3528 19.6464C20.548 19.8417 20.548 20.1582 20.3528 20.3535C20.1575 20.5488 19.8409 20.5488 19.6457 20.3535L15.1246 15.8325Z"
      fill="var(--grayColor)"
    />
  </svg>
);

export default SearchIcon;
