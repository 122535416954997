import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  createTransaction,
  subscribeOnPlan,
} from '../../api/WalletApi/WalletApi.api';
import { createrTransaction } from '../../types';
import { tryRefreshSession } from '../appReducer/appReducer.thunk';
import { fetchTransactionsThunkCreator } from './walletReducer.creators';

export const fetchTransactions = createAsyncThunk(
  'walletReducer/fetchTransactions',
  fetchTransactionsThunkCreator
);

export const fetchCreateTransaction = createAsyncThunk(
  'walletReducer/fetchCreateTransaction',
  async (values: createrTransaction, thunkApi) => {
    const [data, isError] = await createTransaction(values);

    if (isError) {
      return thunkApi.rejectWithValue(data);
    }

    return data;
  }
);

export const fetchSubOnPlan = createAsyncThunk(
  'walletReducer/fetchSubOnPlan',
  async (
    {
      planId,
      message: messageValue,
      refreshUser,
    }: { planId: string; message?: string; refreshUser?: boolean },
    thunkApi
  ) => {
    const [data, isError] = await subscribeOnPlan(planId);

    if (isError) {
      return thunkApi.rejectWithValue(data);
    }

    if (messageValue) {
      message.success(messageValue);
    }

    if (refreshUser) {
      thunkApi.dispatch(tryRefreshSession(false));
    }

    return data;
  }
);
