import Input from '../../components/common/Input/Input';
import { Redirect, useHistory } from 'react-router-dom';
import {
  ChangeEventHandler,
  FC,
  MouseEventHandler,
  useState,
  useEffect,
} from 'react';
import Button from '../../components/common/Button/Button';
import useQuery from '../../hooks/useQuery';
import { AUTH_FORM } from '../../App.constant';
import { message } from 'antd';
import clsx from 'clsx';
import { fetchResetPassword } from '../../api/AppApi/AppApi.api';
import Logo from '../../components/Icons/Logo/Logo';
import classes from './ResetPasswordPage.module.scss';
import { useTranslation } from 'react-i18next';
import AwaitBackground from '../../components/AwaitBackground/AwaitBackground';

const ResetPasswordPage: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();

  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);
  const [isSubmitted, setSubmitted] = useState<boolean>(false);
  const [inputToken, setInputToken] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isValid, setValid] = useState<boolean>(false);

  useEffect(() => {
    if (!query && !inputToken) {
      setShouldRedirect(true);

      return;
    }

    if (inputToken || !query) {
      return;
    }

    const token = query.get('token');

    if (token && !inputToken) {
      setInputToken(token);
      history.push(history.location.pathname);

      return;
    }

    if (!inputToken) {
      setShouldRedirect(true);
    }
  }, [query, inputToken, history]);

  const handleChangePassword: ChangeEventHandler<HTMLInputElement> = event =>
    setPassword(event.target.value);

  const handleSubmitResetPassword: MouseEventHandler = async () => {
    if (!isSubmitted) {
      setSubmitted(true);
    }

    const isLessMinNumber: boolean =
      !!password && password.length < AUTH_FORM.MIN_PASSWORD;

    if (isLessMinNumber) {
      message.error(`${t('message_passwordMore')} ${AUTH_FORM.MIN_PASSWORD}`);
    }

    if (isLessMinNumber && !isValid) {
      return;
    }

    if (isLessMinNumber && isValid) {
      setValid(false);
    }

    if (!isLessMinNumber && !isValid) {
      setValid(true);
    }

    const [, isError] = await fetchResetPassword(inputToken, password);

    if (isError) {
      return;
    }

    message.loading(t('message_passwordChanged'));

    setTimeout(() => {
      history.replace('/');
    }, 2000);
  };

  const isDisabled = !password || !inputToken;
  const hasErrorBorder = isSubmitted && !isValid;

  if (shouldRedirect) {
    return <Redirect to="/" />;
  }

  return (
    <AwaitBackground>
      <section className={classes.content}>
        <header className={classes.header}>
          <Logo />
          <h1>{t('resetPage_title')}</h1>
        </header>
        <form className={classes.resetForm}>
          <Input
            className={clsx(hasErrorBorder && classes.invalid)}
            onChange={handleChangePassword}
            autoComplete="new-password"
            type="password"
            value={password}
            placeholder={t('resetPage_placeholder')}
          />
          <Button
            disabled={isDisabled}
            className={classes.submit}
            onClick={handleSubmitResetPassword}
          >
            {t('resetPage_submit')}
          </Button>
        </form>
      </section>
    </AwaitBackground>
  );
};

export default ResetPasswordPage;
