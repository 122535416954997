import { FC, useState } from 'react';
import classes from './ToggleSwitch.module.scss';
import clsx from 'clsx';
import { SwitcherProps } from '../../../types/components.types';

const ToggleSwitch: FC<SwitcherProps> = ({ name, onChange }) => {
  const [checked, setChecked] = useState<boolean>(false);

  const handleClick = () => {
    const nextValue = !checked;

    setChecked(nextValue);

    if (onChange) {
      onChange(nextValue);
    }
  };

  return (
    <div className={classes.toggleSwitch}>
      <input
        onClick={handleClick}
        type="checkbox"
        className={classes.checkbox}
        name={name}
        id={name}
      />
      <label className={classes.label} htmlFor={name}>
        <span
          className={clsx(classes.switchInner, {
            [classes.disabled]: !checked,
          })}
        />
        <span
          className={clsx(classes.switch, {
            [classes.on]: checked,
            [classes.off]: !checked,
          })}
        />
      </label>
    </div>
  );
};

export default ToggleSwitch;
