import { MENU_ID_MAP } from "../../components/Menu/Menu.constant";

const routes = {
  SETTINGS: `/content/${MENU_ID_MAP.PROFILE}/settings`,
  CHANGE_PASSWORD: `/content/${MENU_ID_MAP.PROFILE}/settings/change-password`,
  RECEIVE: `/content/${MENU_ID_MAP.WALLET}/receive`,
  SEND: `/content/${MENU_ID_MAP.WALLET}/send`,
  REF: `/content/${MENU_ID_MAP.PROFILE}/${MENU_ID_MAP.REF_PAGE}`,
  SUB: `/content/${MENU_ID_MAP.PROFILE}/${MENU_ID_MAP.SUB_PAGE}/:selectedSubId`,
  BILL_PAGE: `/content/${MENU_ID_MAP.WALLET}/${MENU_ID_MAP.BILL_PAGE}`,
  BILL_CODE: `/content/${MENU_ID_MAP.WALLET}/${MENU_ID_MAP.BILL_PAGE}/code`,
  MENU_ITEM: "/content/:activeMenuId",
  ITEM_CHANGE: "/content/item/:activeMenuId/:itemId",
  PROFILE: "/content/profile",
  REGISTRATION: "/content/registration",
  LOGIN: "/content/login",
  SEED: "/content/seed",
  LOCK: "/content/lock",
};

export default routes;
