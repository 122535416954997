import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchCreateUserThunkCreator,
  fetchPlansListThunkCreator,
  fetchUserByEmailAndPasswordThunkCreator,
  loadOauthUserThunkCreator,
  logoutUserThunkCreator,
  tryRefreshSessionThunkCreator,
} from './appReducer.creators';

export const fetchUserByEmailAndPassword = createAsyncThunk(
  'appReducer/fetchUserByEmailAndPassword',
  fetchUserByEmailAndPasswordThunkCreator
);

export const fetchCreateUser = createAsyncThunk(
  'appReducer/fetchCreateUser',
  fetchCreateUserThunkCreator
);

export const loadOauthUser = createAsyncThunk(
  'appReducer/loadOauthUser',
  loadOauthUserThunkCreator
);

export const tryRefreshSession = createAsyncThunk(
  'appReducer/tryRefreshSession',
  tryRefreshSessionThunkCreator
);

export const logoutUser = createAsyncThunk(
  'appReducer/logoutUser',
  logoutUserThunkCreator
);

export const fetchPlansList = createAsyncThunk(
  'appReducer/fetchPlansList',
  fetchPlansListThunkCreator
);
