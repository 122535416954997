/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useContext } from 'react';
import {
  RouteStateActionContext,
  RouteStateContext,
} from '../components/helpers/routeState';

export const useRouteState = () => useContext(RouteStateContext);

export const useRouteStateDispatch = () => useContext(RouteStateActionContext);
