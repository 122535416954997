import iDB from '../../../../models/IndexedDb/IndexedDb.init';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const transactionUpdate = () => {
  if (!iDB.instance) {
    throw new Error('bd undefined');
  }

  return iDB.instance.getTransaction(['wallet'], 'readwrite');
};

export default transactionUpdate;
