import CopyTextIcon from '../../Icons/CopyTextIcon/CopyTextIcon';
import InfoIcon from '../../Icons/InfoIcon/InfoIcon';
import Input from '../../common/Input/Input';
import { FC, MouseEventHandler, ReactElement, useState } from 'react';
import { ReceiveStageProps } from '../../../types/components.types';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import QCodePay from '../../QCode/QCode';
import classes from './ReceiveStage.module.scss';
import useShallowEqualSelector from '../../../utils/useShallowEqualSelector';

const ReceiveStage: FC<ReceiveStageProps> = (): ReactElement => {
  const { t } = useTranslation();

  const [focusedCopy, setFocusedCopy] = useState<boolean>(false);

  const handleMouseEnter: MouseEventHandler = () => setFocusedCopy(true);
  const handleMouseLeave: MouseEventHandler = () => setFocusedCopy(false);

  const walletAddress = useShallowEqualSelector(
    state => state.appReducer.user?.walletAddress || ''
  );

  const handleCopyText = () => {
    navigator.clipboard.writeText(walletAddress);
    message.info(t('clipboard_done'));
  };

  return (
    <div className={classes.content}>
      <div className={classes.info}>{t('receive_receiveMessage')}</div>
      <div className={classes.wallet}>
        <span className={classes.walletTitle}>{t('receive_wallet')}</span>
        <div className={classes.walletInputWrapper}>
          <div
            onMouseLeave={handleMouseLeave}
            onMouseEnter={handleMouseEnter}
            className={classes.inputSuffix}
          >
            <CopyTextIcon
              onClick={handleCopyText}
              color={focusedCopy ? '#62a4f5' : undefined}
            />
          </div>
          <Input
            className={classes.walletInput}
            disabled
            value={walletAddress}
          />
        </div>
        <QCodePay value="edc-map" payType="wallet" />
      </div>
      <div className={classes.message}>
        <div className={classes.infoBlockIcon}>
          <InfoIcon />
        </div>
        <div className={classes.messageText}>
          {t('receive_aboutSendMessage')}
        </div>
      </div>
    </div>
  );
};

export default ReceiveStage;
