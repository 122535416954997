import {
  FC,
  lazy,
  MouseEventHandler,
  ReactElement,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { CSSTransition } from 'react-transition-group';
import { POPUP_TYPE, STYLE_SIZES } from './ContentPopup.constant';
import {
  POPUP_PAGES_NAMES,
  POPUP_PAGES_REGISTER,
} from '../Content/Content.constant';
import clsx from 'clsx';
import Sider from '../Layout/Sider/Sider';
import { MEDIA_QUERIES } from '../../App.constant';
import useBreakpoint from '../../hooks/useBreakpoint';
import { throttle } from '../../utils/utils.global';
import { ContentPopupProps } from '../../types/components.types';
import { useTranslation } from 'react-i18next';
import { MENU_ID_MAP } from '../Menu/Menu.constant';
import classes from './ContentPopup.module.scss';
import { useLocation, useParams } from 'react-router-dom';
import useOpenView from '../../hooks/useOpenView';

const ContentPopupHeader = lazy(
  () => import('./ContentPopupHeader/ContentPopupHeader')
);
const ContentPopupSection = lazy(
  () => import('./ContentPopupSection/ContentPopupSection')
);

// eslint-disable-next-line no-unused-vars
type anyCallback = () => void;

const ContentPopup: FC<ContentPopupProps> = ({
  isVisible,
  asideRef,
  backUrl,
  title: popupTitle,
  as: CustomComponent,
}): ReactElement | null => {
  const actions = useOpenView();
  const location = useLocation();
  const { activeMenuId } = useParams<Record<string, string>>();
  const { t } = useTranslation();

  const Component = useMemo(() => {
    if (CustomComponent) {
      return CustomComponent;
    }

    if (!activeMenuId) {
      return null;
    }

    return POPUP_PAGES_REGISTER?.[activeMenuId] || null;
  }, [CustomComponent, activeMenuId]);

  const [popupElement, setPopupElement] = useState<HTMLDivElement | null>(null);
  const matchBreakpoints = useBreakpoint(MEDIA_QUERIES);

  const [transition, setTransition] = useState<boolean>(false);
  const [type, setType] = useState<string>('');
  const [titleContentPopup, setTitleContentPopup] = useState<string>('');
  const [position, setPosition] = useState<number | string>('');
  const [isSoftHidden, setSoftHidden] = useState<boolean>(false);

  const { isMobile } = matchBreakpoints || {};
  const isInfoPopup = type === POPUP_TYPE.INFO;

  const handleTransitionEntering = () =>
    setTimeout(() => setTransition(false), 200);

  const handleClosePopup: MouseEventHandler = () => {
    actions.open('');
  };

  const handleChangeStage = useCallback(
    (
      title: string | null,
      showBackArrow?: boolean,
      newType?: string,
      callback?: anyCallback
    ) => {
      if (newType !== type) {
        setType(newType || '');
      }

      if (callback) {
        callback();
      }
    },
    [type]
  );

  const handleBack: VoidFunction = () => handleChangeStage('');

  const calculateContentPopupPosition = useCallback(() => {
    if (!isVisible && position) {
      setPosition('');
    }

    if (!isVisible || position || !asideRef.current) {
      return;
    }

    const newPosition =
      asideRef.current.offsetWidth +
      (STYLE_SIZES as Record<string, number>).PADDING;

    if (newPosition !== position) {
      setPosition(newPosition);
    }
  }, [asideRef, isVisible, position]);

  const throttleCalculateContentPopupPosition = useMemo(
    () => throttle(calculateContentPopupPosition, 800),
    [calculateContentPopupPosition]
  );

  useEffect(() => {
    const handleEscapeClosePopup = (event: KeyboardEvent) => {
      if (!popupElement) {
        return;
      }

      if (event.key === 'Escape') {
        // eslint-disable-next-line no-console
        console.log('close point');
        // router.openPoint('');
      }
    };

    document.addEventListener('keydown', handleEscapeClosePopup, false);

    return () => {
      document.removeEventListener('keydown', handleEscapeClosePopup, false);
    };
  }, [popupElement]);

  useEffect(() => {
    window.addEventListener('resize', throttleCalculateContentPopupPosition);

    return () => {
      window.removeEventListener(
        'resize',
        throttleCalculateContentPopupPosition
      );
    };
  }, [throttleCalculateContentPopupPosition]);

  useEffect(() => {
    setTransition(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    const name = popupTitle || POPUP_PAGES_NAMES?.[activeMenuId];

    const newTitle = name ? t(name) : '';

    if (newTitle !== titleContentPopup) {
      setTitleContentPopup(newTitle);
    }
  }, [t, activeMenuId, titleContentPopup, popupTitle, location.pathname]);

  useEffect(() => {
    calculateContentPopupPosition();
  }, [calculateContentPopupPosition]);

  const { siderWidth, siderHeight } = useMemo(() => {
    let siderW: string | number = STYLE_SIZES.DEFAULT_WIDTH;
    let siderH: string | number = '';

    if (isMobile) {
      siderW = STYLE_SIZES.MOBILE_WIDTH;
    } else if (isInfoPopup) {
      siderW = STYLE_SIZES.INFO_WIDTH;
      siderH = +STYLE_SIZES.INFO_WIDTH + 49;
    }

    if (location.pathname.includes(MENU_ID_MAP.REF_PAGE) && !isMobile) {
      siderW = STYLE_SIZES.REF_WIDTH;
    }

    return {
      siderWidth: siderW,
      siderHeight: siderH,
    };

    // eslint-disable-next-line
  }, [isInfoPopup, isMobile, popupElement, location.pathname]);

  if (!isVisible) {
    return null;
  }

  const shouldDisplayTitle = !!titleContentPopup;

  if (!Component) {
    return null;
  }

  return (
    <div
      ref={setPopupElement}
      style={{
        left: isMobile ? 0 : position,
        alignItems: isInfoPopup ? 'center' : '',
        display: isSoftHidden ? 'none' : '',
      }}
      className={classes.contentPopup}
    >
      <Sider
        width={siderWidth}
        height={siderHeight}
        className={clsx(isMobile && classes.mobileSider)}
        classNameContent={clsx(classes.siderContent)}
      >
        <Suspense fallback="">
          <ContentPopupHeader
            popupValue={titleContentPopup}
            backUrl={backUrl}
            handleBack={handleBack}
            shouldDisplayTitle={shouldDisplayTitle}
            stageName={titleContentPopup}
            titleContentPopup={titleContentPopup}
            handleClosePopup={handleClosePopup}
          />
          <CSSTransition
            in={transition}
            timeout={300}
            onEntering={handleTransitionEntering}
            classNames="sectionPopup"
          >
            <ContentPopupSection
              popupValue={titleContentPopup}
              handleChangeStage={handleChangeStage}
              handleBack={handleBack}
              stageName={titleContentPopup}
              setSoftHidden={setSoftHidden}
              as={Component}
            />
          </CSSTransition>
        </Suspense>
      </Sider>
    </div>
  );
};

export default ContentPopup;
