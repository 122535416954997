import clsx from 'clsx';
import { FC, PropsWithChildren, ReactElement } from 'react';
import { SiderProps } from '../../../types/components.types';
import classes from './Sider.module.scss';

const Sider: FC<PropsWithChildren<SiderProps>> = ({
  width,
  height,
  classNameContent,
  className,
  children,
}): ReactElement => (
  <div
    style={{
      width,
      height,
      maxWidth: width,
      minWidth: width,
      flex: `0 0 ${width}`,
    }}
    className={clsx(classes.aside, className)}
  >
    <div className={clsx(classes.childrens, classNameContent)}>{children}</div>
  </div>
);

Sider.defaultProps = {
  className: '',
  classNameContent: '',
};

export default Sider;
