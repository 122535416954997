import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ChainStore, PrivateKey } from '2ab2-js';
import AwaitBackground from '../../../../../components/AwaitBackground/AwaitBackground';
import { Spin } from 'antd';
import classes from './BlockhainConnect.module.scss';
import { Apis } from '2ab2-ws-js';
import aesWorkerInstance from '../../../../../workers/Aes/AesWorker.instance';
import indexedDbActions from '../../../../../models/IndexedDb/IndexedDb.init';
import {
  loadPrivateKeys,
  loadRefs,
  loadWalletData,
} from '../../../../../models/IndexedDb/actions';
import checkNextGeneratedKey from '../../utils/checkNextGeneratedKey';
import { ChainConfig } from '2ab2-ws-js';
import transactionUpdateKeys from '../../utils/transactionUpdateKeys';
import addPrivateKeysNoIndex from '../../utils/addPrivateKeysNoIndex';

const BlockchainApiContext = createContext<Record<string, any>>(
  null as unknown as Record<string, any>
);

const BlockchainConnectSyncedContext = createContext<boolean>(false);

export const useBlockchainConnectSynced = (): boolean =>
  useContext(BlockchainConnectSyncedContext);

export const useBlockchainApi = (): Record<string, any> =>
  useContext(BlockchainApiContext);

const BlockchainConnect: FC<PropsWithChildren<Record<string, any>>> = ({
  children,
}) => {
  const [hasConnectError, setConnectError] = useState<boolean>(false);
  const [synced, setSynced] = useState<boolean>(false);
  const api = useRef<Record<string, any>>(
    null as unknown as Record<string, any>
  );

  const start = useCallback(async () => {
    ChainStore.subscribe(checkNextGeneratedKey.bind(this));

    const connect = async () => {
      try {
        await ChainStore.init();

        setSynced(true);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);

        if (!hasConnectError) {
          setConnectError(true);
        }

        setTimeout(connect, 3000);
      }
    };

    try {
      api.current = await Apis.instance(
        process.env.REACT_APP_DOMAIN_API_WS,
        true
      ).init_promise;

      await indexedDbActions.api.initInstance();
      await connect();

      await loadPrivateKeys();
      await loadRefs();
      await loadWalletData();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      setConnectError(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const importKeysWorker = useCallback(async () => {
    aesWorkerInstance.onmessage = async (message: MessageEvent) => {
      // eslint-disable-next-line no-debugger
      debugger;
      try {
        // eslint-disable-next-line no-console
        console.log('Preparing for private keys save');

        const privateCipherhexArray = message.data;

        const encPrivateKeyObjs: Record<string, any>[] = [];

        encPrivateKeyObjs.forEach((privateKeyObj, i) => {
          const {
            // eslint-disable-next-line camelcase
            import_account_names: importAccountNames,
            // eslint-disable-next-line camelcase
            public_key_string: _publicKeyString,
            // eslint-disable-next-line camelcase
            private_plainhex: privatePlainHex,
          } = privateKeyObj;

          let publicKeyString = _publicKeyString;

          const privateCipherhex = privateCipherhexArray[i];

          if (!publicKeyString) {
            // console.log('WARN: public key was not provided, this will incur slow performance')
            const privateKey = PrivateKey.fromHex(privatePlainHex);
            const publicKey = privateKey.toPublicKey(); // S L O W
            publicKeyString = publicKey.toPublicKeyString();
          } else if (publicKeyString.indexOf(ChainConfig.address_prefix) !== 0)
            throw new Error(
              'Public Key should start with ' + ChainConfig.address_prefix
            );

          const privateKeyObject = {
            import_account_names: importAccountNames,
            encrypted_key: privateCipherhex,
            pubkey: publicKeyString,
          };

          encPrivateKeyObjs.push(privateKeyObject);
        });

        // eslint-disable-next-line no-console
        console.log('Saving private keys', new Date().toString());

        const transaction = transactionUpdateKeys();

        transaction.oncomplete = () => {
          // eslint-disable-next-line no-console
          console.log('Done saving keys', new Date().toString());
        };

        try {
          await addPrivateKeysNoIndex(encPrivateKeyObjs, transaction);
        } catch (e) {
          transaction.abort();
          // eslint-disable-next-line no-console
          console.error(e);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('AesWorker.encrypt', e);
      }
    };
  }, []);

  useEffect(() => {
    importKeysWorker();
    start();
  }, [importKeysWorker, start]);

  if (!api.current) {
    return (
      <AwaitBackground>
        <section className={classes.root}>
          <Spin
            size="large"
            tip={
              hasConnectError
                ? 'Error connect to blockchain, reconnecting...'
                : 'connecting...'
            }
          />
        </section>
      </AwaitBackground>
    );
  }

  return (
    <BlockchainApiContext.Provider value={api.current}>
      <BlockchainConnectSyncedContext.Provider value={synced}>
        {children}
      </BlockchainConnectSyncedContext.Provider>
    </BlockchainApiContext.Provider>
  );
};

export default BlockchainConnect;
