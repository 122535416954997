import { ReactFacebookLoginInfo } from 'react-facebook-login';
import { GoogleLoginResponse } from 'react-google-login';
import { v4 as uuidv4 } from 'uuid';
import { GoogleUser, IPlan, PrivateUser, UserCreator } from '../../interfaces';
import Request from '../../models/Request/Request';
import RequestError from '../../models/RequestError/RequestError';
import {
  returnLoadUserType,
  returnCreateUserType,
  returnRecoveryPassword,
  User,
} from '../../types';
import { handleRequestError } from '../../utils/utils.global';
import {
  AUTH_ENDPOINTS,
  getSearchReferralEndpoint,
  USER_ENDPOINTS,
} from '../Endpoints.constant';
import { IReferralResponse } from '../../components/ReferralSystemContent/ReferralSystemContent.interface';

export const loadUser = async (
  email: string,
  password: string
): Promise<returnLoadUserType> => {
  try {
    const userValues: Omit<PrivateUser, 'username'> = {
      email,
      password,
    };

    const request = new Request('public');
    const response = await request.send(
      AUTH_ENDPOINTS.SIGN_IN,
      'POST',
      userValues
    );

    const { status, data } = response;

    if (status !== 200 || !request.isValidResponse(response)) {
      throw new RequestError('Bad load user', data);
    }

    if (data.accessToken) {
      Request.saveJwtToken(data.accessToken, data?.expireAt);
    }

    const user = {
      email: userValues.email,
      username: userValues.email,
      ...data.user,
    };

    return [user, false];
  } catch (err) {
    return handleRequestError(err as RequestError, true);
  }
};

export const loadGoogleUser = async (
  googleValues: GoogleLoginResponse
): Promise<returnLoadUserType> => {
  try {
    const request = new Request('public');

    const response = await request.send(AUTH_ENDPOINTS.SIGN_IN_GOOGLE, 'POST', {
      googleIdToken: googleValues.tokenId,
      id: uuidv4(),
    });

    const { status, data } = response;

    if (status !== 200 || !request.isValidResponse(response)) {
      throw new RequestError('Bad create user', data);
    }

    if (data.accessToken) {
      Request.saveJwtToken(data.accessToken, data?.expireAt);
    }

    const { imageUrl, name, ...googleUser } = googleValues.profileObj;

    const user: GoogleUser = {
      username: name,
      avatar: imageUrl,
      ...googleUser,
      ...data.user,
    };

    return [user, false];
  } catch (error) {
    return handleRequestError(error as RequestError);
  }
};

export const loadFacebookUser = async (
  code: string
): Promise<returnLoadUserType<ReactFacebookLoginInfo>> => {
  try {
    const request = new Request('public');

    const response = await request.send(
      AUTH_ENDPOINTS.SIGN_IN_FACEBOOK,
      'POST',
      {
        code,
        id: uuidv4(),
      }
    );

    const { status, data } = response;

    if (status !== 200 || !request.isValidResponse(response)) {
      throw new RequestError('Bad create user', data);
    }

    if (data.accessToken) {
      Request.saveJwtToken(data.accessToken, data?.expireAt);
    }

    const { url: avatarUrl = '' } = data?.picture?.data || {};

    const user: User<ReactFacebookLoginInfo> = {
      username: data.user.name,
      avatar: avatarUrl,
      ...data.user,
    };

    return [user, false];
  } catch (error) {
    return handleRequestError(error as RequestError);
  }
};

export const createUser = async (
  values: UserCreator
): Promise<returnCreateUserType> => {
  try {
    const request = new Request('public');
    const response = await request.send(AUTH_ENDPOINTS.SIGN_UP, 'POST', values);

    const { status, data } = response;

    if (status !== 200 || !request.isValidResponse(response)) {
      throw new RequestError('Bad create user', data);
    }

    if (data.accessToken) {
      Request.saveJwtToken(data.accessToken, data?.expireAt);
    }

    // eslint-disable-next-line
    const { id, ...authValues } = values;

    const user = {
      email: authValues.email,
      username: authValues.email,
      referral: authValues?.referral || null,
      ...data.user,
    };

    return [user, false];
  } catch (err) {
    return handleRequestError(err as RequestError, true);
  }
};

export const fetchRecoveryPassword = async (
  email: string
): Promise<returnRecoveryPassword> => {
  try {
    const request = new Request('public');
    const response = await request.send(
      USER_ENDPOINTS.INIT_RECOVERY_PASSWORD,
      'POST',
      { email }
    );

    const { status, data } = response;

    if (status > 300) {
      throw new RequestError('Bad recovery password', data);
    }

    return [true, false];
  } catch (err) {
    return handleRequestError(err as RequestError);
  }
};

export const fetchResetPassword = async (
  token: string,
  password: string
): Promise<returnRecoveryPassword> => {
  try {
    const request = new Request('public');
    const response = await request.send(
      USER_ENDPOINTS.FINISH_RECOVERY_PASSWORD,
      'POST',
      { password, token }
    );

    const { status, data } = response;

    if (status !== 200 || !request.isValidResponse(response)) {
      throw new RequestError('Bad reset password', data);
    }

    return [true, false];
  } catch (err) {
    return handleRequestError(err as RequestError, true);
  }
};

export const fetchChangePassword = async (
  password: string
): Promise<boolean> => {
  try {
    const request = new Request();
    const response = await request.send(USER_ENDPOINTS.EDIT_USER, 'PATCH', {
      password,
    });

    const { status, data } = response;

    if (status !== 200 || !request.isValidResponse(response)) {
      throw new RequestError('Bad change password', data);
    }

    return true;
  } catch (err) {
    handleRequestError(err as RequestError, true);
    return false;
  }
};

export const uploadUserAvatar = async (
  avatar: string // base64
): Promise<boolean> => {
  try {
    const request = new Request();
    const response = await request.send(USER_ENDPOINTS.UPLOAD_AVATAR, 'PUT', {
      avatar,
    });

    const { status, data } = response;

    if (status !== 200 || !request.isValidResponse(response)) {
      throw new RequestError('Bad upload new avatar', data);
    }

    return true;
  } catch (err) {
    handleRequestError(err as RequestError, true);
    return false;
  }
};

export const fetchRefreshUser = async (): Promise<User | null> => {
  try {
    const token = Request.getAuthToken();

    if (!token) {
      return null;
    }

    const request = new Request();

    const response = await request.send(USER_ENDPOINTS.REFRESH_USER);

    if (response.status !== 200 || !request.isValidResponse(response)) {
      throw new RequestError('Invalid refresh data', response.data);
    }

    return response.data;
  } catch (error) {
    handleRequestError(error as RequestError);
    return null;
  }
};

export const fetchPlansApi = async (): Promise<IPlan[] | null> => {
  try {
    const token = Request.getAuthToken();

    if (!token) {
      return null;
    }

    const request = new Request();

    const response = await request.send(USER_ENDPOINTS.PLAN_LIST, 'GET');

    if (response.status !== 200) {
      throw new RequestError('Invalid plan list', response.data);
    }

    return response.data;
  } catch (error) {
    handleRequestError(error as RequestError);
    return null;
  }
};

export const fetchReferralTransactionsList = async <T>(
  page: number
): Promise<IReferralResponse<T> | null> => {
  try {
    const token = Request.getAuthToken();

    if (!token) {
      return null;
    }

    const request = new Request();

    const response = await request.send(
      `${USER_ENDPOINTS.REFERRAL_TRANSACTIONS}?page=${page}`,
      'GET'
    );

    if (response.status !== 200) {
      throw new RequestError(
        'Invalid load referral transactions list',
        response.data
      );
    }

    return response.data;
  } catch (error) {
    handleRequestError(error as RequestError, true);
    return null;
  }
};

export const fetchReferralList = async (
  page = 1,
  all = false
): Promise<IReferralResponse | null> => {
  try {
    const token = Request.getAuthToken();

    if (!token) {
      return null;
    }

    const request = new Request();

    const response = await request.send(
      all
        ? USER_ENDPOINTS.REFERRAL_LIST
        : `${USER_ENDPOINTS.REFERRAL_LIST}?page=${page}`,
      'GET'
    );

    if (response.status !== 200) {
      throw new RequestError('Invalid load referral list', response.data);
    }

    return response.data;
  } catch (error) {
    handleRequestError(error as RequestError, true);
    return null;
  }
};

export const fetchSearchReferral = async (
  value: string,
  // eslint-disable-next-line
  page: number
): Promise<IReferralResponse | null> => {
  try {
    const token = Request.getAuthToken();

    if (!token) {
      return null;
    }

    const request = new Request();

    const response = await request.send(
      getSearchReferralEndpoint(value, 0),
      'GET'
    );

    if (response.status !== 200) {
      throw new RequestError('Invalid search values', response.data);
    }

    return response.data;
  } catch (error) {
    handleRequestError(error as RequestError, true);
    return null;
  }
};
