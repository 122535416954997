import { MapStore, MapPoint } from '../../interfaces';
import { mapMenuType } from '../../types';
import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import { fetchCreatePoint, fetchViewportPoints } from './mapReducer.thunk';
import { Coords } from 'google-map-react';

const initialState: MapStore = {
  enableSetAddressMode: false,
  addressCoords: null,
  mapType: 'default',
  points: [],
  currentSearchPlace: '',
  activePointId: null,
  isCreatePoint: false,
};

const mapReducer: Slice<MapStore> = createSlice({
  name: 'mapReducer',
  initialState,
  reducers: {
    setDoneCreatePoint: state => {
      state.isCreatePoint = false;
    },
    setActivePointId: {
      reducer: (state, { payload }: PayloadAction<string | null>) => {
        state.activePointId = payload;
      },
      prepare: (id: string | null) => ({ payload: id }),
    },
    addPoint: {
      reducer: (state, { payload }: PayloadAction<MapPoint>) => {
        state.points = state.points.filter(point => point.id !== payload.id);
        state.points.push(payload);
      },
      prepare: (point: MapPoint) => ({ payload: point }),
    },
    setMapType: {
      reducer: (state, { payload }: PayloadAction<mapMenuType>) => {
        state.mapType = payload;
        state.points = [];
      },
      prepare: (type: mapMenuType) => ({ payload: type }),
    },
    setSearchPlace: {
      reducer: (state, { payload }: PayloadAction<string>) => {
        state.currentSearchPlace = payload;
      },
      prepare: (place: string) => ({ payload: place }),
    },
    setEnableAddressMode: {
      reducer: (state, { payload }: PayloadAction<boolean>) => {
        state.enableSetAddressMode = payload;
      },
      prepare: (modeFlag: boolean) => ({ payload: modeFlag }),
    },
    setAddressCoords: {
      reducer: (state, { payload }: PayloadAction<Coords | null>) => {
        state.addressCoords = payload;
      },
      prepare: (coords: null | Coords) => ({ payload: coords }),
    },
  },
  extraReducers: builder => {
    builder.addCase(
      fetchCreatePoint.fulfilled,
      (state, action: PayloadAction<MapPoint>) => {
        if (
          action.payload &&
          typeof action.payload === 'object' &&
          Object.keys(action.payload).length
        ) {
          state.points.push(action.payload);
        }

        state.isCreatePoint = true;
      }
    );

    builder.addCase(
      fetchViewportPoints.fulfilled,
      (state, action: PayloadAction<MapPoint[] | MapPoint>) => {
        if (Array.isArray(action.payload)) {
          state.points = action.payload;
          return;
        }

        if (typeof action.payload === 'object') {
          state.points.push(action.payload);
        }
      }
    );
  },
});

export const {
  setActivePointId,
  setMapType,
  setSearchPlace,
  setDoneCreatePoint,
  setEnableAddressMode,
  setAddressCoords,
  addPoint,
} = mapReducer.actions;

export default mapReducer.reducer;
