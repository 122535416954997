import { ComponentType, JSXElementConstructor } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './ErrorFallback';

const withError = (Component: JSXElementConstructor<any>): ComponentType<any> =>
  withErrorBoundary(Component, {
    FallbackComponent: ErrorFallback,
    onError(error: Error) {
      // eslint-disable-next-line no-console
      console.error(error);
    },
  });

export default withError;
