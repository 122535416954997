import { JSXElementConstructor } from 'react';
import { MENU_ID_MAP } from '../Menu/Menu.constant';
import AddIcon from './AddIcon/AddIcon';
import MapIcon from './MapIcon/MapIcon';
import PowerbankIcon from './PowerbankIcon/PowerbankIcon';
import UserIcon from './UserIcon/UserIcon';
import WalletIcon from './WalletIcon/WalletIcon';

export const ICONS_REGISTER: Readonly<
  Record<string, JSXElementConstructor<any>>
> = {
  [MENU_ID_MAP.ADD]: AddIcon,
  [MENU_ID_MAP.MAP]: MapIcon,
  [MENU_ID_MAP.WALLET]: WalletIcon,
  [MENU_ID_MAP.POWERBANKS]: PowerbankIcon,
  [MENU_ID_MAP.AUTH]: UserIcon,
};
