import IndexedDb from './IndexedDb';
import IndexedDbRoot from './IndexedDb';
import ManageDatabase from './IndexedDb.utils';
import { IDBPDatabase } from 'idb';

interface IIndexedDbActions {
  instance: null | IndexedDb;
  api: {
    initInstance(): Promise<IndexedDb>;
    close(): void;
    WALLET_BACKUP_STORES: string[];
    // eslint-disable-next-line no-unused-vars
    getDatabaseName: (currentWallet?: string, chainId?: any) => string;
    getCurrentWalletName: () => string;
    deleteDatabase(): Promise<any>;
    // eslint-disable-next-line no-unused-vars
    restore(walletName: string, object: Record<string, any>): boolean;
    // eslint-disable-next-line no-unused-vars
    addToStore(storeName: string, value: any): Promise<any>;
    // eslint-disable-next-line no-unused-vars
    removeFromStore(storeName: string, value: any): Promise<any>;
    // eslint-disable-next-line no-unused-vars
    loadData(storeName: string): Promise<Record<string, any>[] | undefined>;
    // eslint-disable-next-line no-unused-vars
    getCachedProperty(name: string, defaultValue: any): Promise<any>;
    // eslint-disable-next-line no-unused-vars
    setCachedProperty(name: string, value: any): Promise<any>;
    // eslint-disable-next-line no-unused-vars
    backup(storeNames?: string[]): Promise<Record<string, any>>;
  };
}

const WALLET_BACKUP_STORES = ['wallet', 'private_keys', 'linked_accounts'];

// eslint-disable-next-line no-unused-vars
const currentWalletName = 'default';

const indexedDbActions: IIndexedDbActions = {
  instance: null,
  api: {
    WALLET_BACKUP_STORES,
    getDatabaseName: IndexedDb.getDatabaseName,
    getCurrentWalletName: () => currentWalletName,
    deleteDatabase() {
      if (!indexedDbActions.instance) {
        return Promise.resolve(true);
      }

      return IndexedDbRoot.deleteDatabase();
    },
    async initInstance() {
      indexedDbActions.instance = new IndexedDbRoot();

      return indexedDbActions.instance;
    },

    close() {
      if (indexedDbActions.instance) {
        indexedDbActions.instance.close();
      }

      indexedDbActions.instance = null;
    },
    async addToStore(storeName: string, value: any) {
      if (!indexedDbActions.instance) {
        throw new Error('instance undefined');
      }

      const transaction = indexedDbActions.instance.getTransaction(
        [storeName],
        'readwrite'
      );

      const store = transaction.objectStore(storeName);
      // @ts-ignore
      const result = await store.add(value);

      return result;
    },
    async removeFromStore(storeName: string, value: any) {
      if (!indexedDbActions.instance) {
        throw new Error('instance undefined');
      }

      const transaction = indexedDbActions.instance.getTransaction(
        [storeName],
        'readwrite'
      );

      const store = transaction.objectStore(storeName);
      // @ts-ignore
      const result = await store.delete(value);

      return result;
    },
    // eslint-disable-next-line consistent-return
    async loadData(
      storeName: string
    ): Promise<Record<string, any>[] | undefined> {
      if (!indexedDbActions.instance) {
        throw new Error('instance undefined');
      }

      const data: Record<string, any>[] = [];

      let cursorStore = await ManageDatabase.cursor(
        indexedDbActions.instance.getDatabase() as IDBPDatabase,
        storeName
      );

      if (!cursorStore) {
        return data;
      }

      while (cursorStore) {
        data.push(cursorStore.value);
        // eslint-disable-next-line no-await-in-loop
        cursorStore = await cursorStore.continue();
      }
    },
    /** Persisted to disk but not backed up.
     @return promise
     */
    async getCachedProperty(name: string, defaultValue: any) {
      if (!indexedDbActions.instance) {
        throw new Error('instance undefined');
      }
      const transaction = indexedDbActions.instance.getTransaction(
        ['cached_properties'],
        'readwrite'
      );

      const store = transaction.objectStore('cached_properties');

      const result = await store.get(name);

      return result || defaultValue;
    },
    /** Persisted to disk but not backed up. */
    async setCachedProperty(name: string, value: any) {
      if (!indexedDbActions.instance) {
        throw new Error('instance undefined');
      }
      const transaction = indexedDbActions.instance.getTransaction(
        ['cached_properties'],
        'readwrite'
      );

      const store = transaction.objectStore('cached_properties');

      // @ts-ignore
      const result = await store.put({ name, value });

      return result;
    },

    backup(storeNames = WALLET_BACKUP_STORES) {
      const promises = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const storeName of storeNames) {
        promises.push(this.loadData(storeName));
      }
      // Add each walletStore name
      return Promise.all(promises).then(results => {
        const obj: Record<string, any> = {};
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < storeNames.length; i++) {
          const storeName = storeNames[i];
          if (storeName === 'wallet') {
            const walletArray: Record<string, any>[] = results[i] as Record<
              string,
              any
            >[];
            // their should be only 1 wallet per database
            // eslint-disable-next-line no-restricted-syntax
            for (const wallet of walletArray)
              wallet.backup_date = new Date().toISOString();
          }

          obj[storeName] = results[i];
        }
        return obj;
      });
    },
    restore(walletName: string, object: Record<string, any>) {
      if (!indexedDbActions.instance) {
        throw new Error('instance undefined');
      }

      indexedDbActions.instance = new IndexedDb();

      const storeNames = Object.keys(object);

      if (!indexedDbActions.instance) {
        throw new Error('instance undefined');
      }

      const trx = indexedDbActions.instance.getTransaction(
        storeNames,
        'readwrite'
      );

      // eslint-disable-next-line no-restricted-syntax
      for (const storeName of storeNames) {
        const store = trx.objectStore(storeName);
        const records = object[storeName];

        // eslint-disable-next-line no-restricted-syntax
        for (const record of records) {
          // @ts-ignore
          store.put(record);
        }
      }

      return true;
    },
  },
};

export default indexedDbActions;
