import { Aes } from '2ab2-js';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default () => {
  // eslint-disable-next-line no-restricted-globals
  self.onmessage = (message: MessageEvent) => {
    // eslint-disable-next-line no-console
    console.log('Aes worker message', message);
    try {
      // eslint-disable-next-line no-restricted-globals,camelcase
      const { private_plainhex_array, iv, key } = message.data;

      const aes = new Aes(iv, key);

      const privateCipherhexArray = [];

      // eslint-disable-next-line camelcase,no-restricted-syntax
      for (const privatePlainhex of private_plainhex_array) {
        const privateCipherhex = aes.encryptHex(privatePlainhex);

        privateCipherhexArray.push(privateCipherhex);
      }
      postMessage(privateCipherhexArray);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('AesWorker', e);
    }
  };
};
