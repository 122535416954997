import { FC, ReactElement } from 'react';

const CofeeIcon: FC = (): ReactElement => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill="var(--lightBrownColor)" />
    <path
      d="M26 11C26.2449 11 26.4813 11.09 26.6644 11.2527C26.8474 11.4155 26.9643 11.6397 26.993 11.883L27 12V28C26.9997 28.2549 26.9021 28.5 26.7272 28.6854C26.5522 28.8707 26.313 28.9822 26.0586 28.9972C25.8042 29.0121 25.5536 28.9293 25.3582 28.7657C25.1627 28.6021 25.0371 28.3701 25.007 28.117L25 28V23H24C23.7551 23 23.5187 22.91 23.3356 22.7473C23.1526 22.5845 23.0357 22.3603 23.007 22.117L23 22V16C23 13.79 24.5 11 26 11ZM20 11C20.2449 11 20.4813 11.09 20.6644 11.2527C20.8474 11.4155 20.9643 11.6397 20.993 11.883L21 12V17C20.9999 17.8869 20.7052 18.7486 20.1622 19.4498C19.6192 20.151 18.8586 20.6519 18 20.874V28C17.9997 28.2549 17.9021 28.5 17.7272 28.6854C17.5522 28.8707 17.313 28.9822 17.0586 28.9972C16.8042 29.0121 16.5536 28.9293 16.3582 28.7657C16.1627 28.6021 16.0371 28.3701 16.007 28.117L16 28V20.874C15.1755 20.6608 14.4404 20.1902 13.9018 19.5305C13.3631 18.8708 13.049 18.0565 13.005 17.206L13 17V12C13.0003 11.7451 13.0979 11.5 13.2728 11.3146C13.4478 11.1293 13.687 11.0178 13.9414 11.0028C14.1958 10.9879 14.4464 11.0707 14.6418 11.2343C14.8373 11.3979 14.9629 11.6299 14.993 11.883L15 12V17C15 17.3511 15.0924 17.6959 15.268 18C15.4435 18.304 15.696 18.5565 16 18.732V12C16.0003 11.7451 16.0979 11.5 16.2728 11.3146C16.4478 11.1293 16.687 11.0178 16.9414 11.0028C17.1958 10.9879 17.4464 11.0707 17.6418 11.2343C17.8373 11.3979 17.9629 11.6299 17.993 11.883L18 12L18.001 18.732C18.2801 18.5707 18.516 18.3443 18.6887 18.0722C18.8614 17.8 18.9658 17.4902 18.993 17.169L19 17V12C19 11.7348 19.1054 11.4804 19.2929 11.2929C19.4804 11.1054 19.7348 11 20 11Z"
      fill="white"
    />
  </svg>
);

export default CofeeIcon;
