import { IDBPTransaction } from 'idb';
import {
  IPrivateTComb,
  PrivateKeyTcomb,
} from '../../../../lib/store/tcombStructure';
import { ChainStore } from '2ab2-js';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const addPrivateKeysNoIndex = async (
  privateKeyObjects: Record<string, any>[],
  transaction: IDBPTransaction
) => {
  const store = transaction.objectStore('private_keys');

  // eslint-disable-next-line no-restricted-syntax
  for await (const privateKeyObject of privateKeyObjects) {
    PrivateKeyTcomb(privateKeyObject as IPrivateTComb);

    // @ts-ignore
    await store.add(privateKeyObject);

    ChainStore.getAccountRefsOfKey(privateKeyObject.pubkey);
  }

  const newKeys = await store.getAll();

  return newKeys;
};

export default addPrivateKeysNoIndex;
