/* eslint-disable arrow-parens */
import { ChainStore, FetchChain } from "2ab2-js";
import { Apis } from "2ab2-ws-js";
import iDB from "../../../../models/IndexedDb/IndexedDb.init";
import privateKeyActions from "./privateKeyActions";
import { loadLinkedAccounts } from "../../../../models/IndexedDb/actions";
import loadWalletDbData from "../utils/loadWalletDbData";
import addAccountRefs from "../utils/addAccountRefs";
import onCreateWallet from "../utils/onCreateWallet";
import loadRefsDbData from "../utils/loadRefsDbData";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const onSetWallet = async (
  walletName = "default",
  createWalletPassword: string,
  brnkey?: string
) => {
  if (!iDB.instance) {
    throw new Error("iDB.instance invalid");
  }

  const props = await iDB.instance.getProperty<{
    name: string;
    value: string[];
  }>("wallet_names", {
    name: "wallet_names",
    value: ["default"],
  });

  if (/[^a-z0-9_-]/.test(walletName) || walletName === "") {
    throw new Error("Invalid wallet name");
  }

  let add;

  if (!iDB.instance) {
    throw new Error("iDB.instance invalid");
  }

  if (!props?.value?.length) {
    add = iDB.instance.setProperty("wallet_names", [
      ...new Set([...props.value, walletName]),
    ]);
  }

  const current = iDB.instance.setProperty("current_wallet", walletName);

  // eslint-disable-next-line no-return-await
  return await Promise.all([add, current]).then(() => {
    // The database must be closed and re-opened first before the current
    // application code can initialize its new state.
    // iDB.close();
    ChainStore.clearCache();
    const chainStoreUpdate = () => {
      addAccountRefs();
    };

    return Promise.all([
      loadWalletDbData().then(() => {
        const linkedAccounts = new Set();

        const chainId = Apis.instance().chain_id;
        // eslint-disable-next-line no-shadow
        return new Promise((resolve, reject) => {
          loadLinkedAccounts()
            .then((data) => {
              if (data) {
                const accountPromises = data
                  .filter((item) => {
                    if (item.chainId) {
                      return item.chainId === chainId;
                    }
                    return true;
                  })
                  .map((item) => {
                    linkedAccounts.add(item.name);
                    return FetchChain("getAccount", item.name);
                  });

                Promise.all(accountPromises)
                  .then(() => {
                    ChainStore.subscribe(chainStoreUpdate);
                    resolve(linkedAccounts);
                  })
                  .catch((err) => {
                    ChainStore.subscribe(chainStoreUpdate);
                    reject(err);
                  });
              } else {
                // eslint-disable-next-line no-console
                console.warn("invalid load linked accounts");
              }
            })
            .catch((err) => {
              reject(err);
            });
        });
      }),
      privateKeyActions.onLoadDbData().then(() => loadRefsDbData()),
    ]).then(() => {
      // Update state here again to make sure listeners re-render

      if (!createWalletPassword) {
        // this.setState({current_wallet: walletName});
        return;
      }

      // eslint-disable-next-line consistent-return
      return onCreateWallet(createWalletPassword, brnkey || "", walletName);
    });
  });
};

export default onSetWallet;
