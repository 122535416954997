import getBrainKeySequence from './GetBrainKeySequence';
import updateWallet from './updateWallet';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const incrementBrainKeySequence = async () => {
  const prevBrainKey = await getBrainKeySequence();

  return updateWallet(undefined, {
    brainkey_sequence: prevBrainKey + 1,
  });
};

export default incrementBrainKeySequence;
