import { Transaction, WalletStore } from '../../interfaces';
import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import { fetchTransactions } from './walletReducer.thunk';

const initialState: WalletStore = {
  transactions: [],
};

const walletReducer: Slice<WalletStore> = createSlice({
  name: 'walletReducer',
  initialState,
  reducers: {
    addTransaction: {
      reducer: (state, { payload }: PayloadAction<Transaction>) => {
        state.transactions.push(payload);
      },
      prepare: transactionItem => ({ payload: transactionItem }),
    },
  },
  extraReducers: builder => {
    builder.addCase(
      fetchTransactions.fulfilled,
      (state: WalletStore, action) => {
        if (Array.isArray(action.payload)) {
          state.transactions = action.payload;
        }
      }
    );
  },
});

export const { addTransaction } = walletReducer.actions;

export default walletReducer.reducer;
