import {
  FC,
  lazy,
  MouseEventHandler,
  ReactElement,
  Suspense,
  useEffect,
  useMemo,
  useState,
} from 'react';
import GoogleMapReact, { ClickEventValue, Coords } from 'google-map-react';
import getGeolocationMarkerClass from './getGeolocationMarkerClass';
import {
  setAddressCoords,
  setEnableAddressMode,
} from '../../redux/mapReducer/mapReducer.slice';
import useShallowEqualSelector from '../../utils/useShallowEqualSelector';
import { GoogleMapsApi, MapPoint } from '../../interfaces';
import { batch, useDispatch } from 'react-redux';
import {
  bootstrapKeys,
  DEFAULT_ZOOM,
  googleMapConfigOptions,
  MAP_DEFAULT_COORDS,
} from './Map.constant';
import {
  useMapContextApiAction,
  useMapInstance,
  useMapInstanceAction,
} from '../../api/MapContext/MapContext.hooks';
import usePrevious from '../../hooks/usePrevious';
import { notification } from 'antd';
import { fetchViewportPoints } from '../../redux/mapReducer/mapReducer.thunk';
import classes from './Map.module.scss';
import { toGeoJson } from './GeoJson';
import useSupercluster from '../../hooks/useSupercluster';
import { BBox } from 'geojson';

const PointIcon = lazy(() => import('./PointIcon/PointIcon'));
const Point = lazy(() => import('./Point/Point'));

interface IMapProps {
  // eslint-disable-next-line no-unused-vars
  createHandleClickPoint: (id: string) => MouseEventHandler;
  // eslint-disable-next-line no-unused-vars
  setOpenedPointsProps: (value: MapPoint | null) => void;
  openedPointId: string | null;
  isVisiblePointPopup: boolean;
  handleHidePointPopup: VoidFunction;
  ownPosition: Coords | null;
}

const Map: FC<IMapProps> = ({
  createHandleClickPoint,
  openedPointId,
  isVisiblePointPopup,
  setOpenedPointsProps,
  ownPosition,
  handleHidePointPopup,
}): ReactElement => {
  const dispatch = useDispatch();

  const setMapApi = useMapContextApiAction();

  const setMapInstance = useMapInstanceAction();
  const mapInstance = useMapInstance();

  const [locationMarker, setLocationMarker] = useState<Record<
    string,
    any
  > | null>(null);

  const [currentZoom, setCurrentZoom] = useState<number>(DEFAULT_ZOOM);
  const [bounds, setBounds] = useState<BBox | null>(null);

  const { mapType, points, enableSetAddressMode } = useShallowEqualSelector(
    state => {
      const { mapReducer } = state;
      return {
        mapType: mapReducer.mapType,
        points: mapReducer.points,
        enableSetAddressMode: mapReducer.enableSetAddressMode,
      };
    }
  );

  const geoPoints = useMemo(() => toGeoJson(points), [points]);

  const { clusters } = useSupercluster({
    points: geoPoints,
    bounds,
    zoom: currentZoom,
    options: { radius: 75, maxZoom: 13 },
  });

  const prevMapType = usePrevious(mapType);
  const prevEnableSetAddressMode = usePrevious(enableSetAddressMode);

  useEffect(() => {
    if (!isVisiblePointPopup || !points) {
      return;
    }

    const openedPointsProps =
      points.find(point => point.id === openedPointId) || null;

    setOpenedPointsProps(openedPointsProps);
  }, [openedPointId, isVisiblePointPopup, points, setOpenedPointsProps]);

  useEffect(() => {
    if (mapInstance && enableSetAddressMode) {
      mapInstance.setOptions({
        draggableCursor: 'pointer',
      });
    }

    if (mapInstance && prevEnableSetAddressMode && !enableSetAddressMode) {
      mapInstance.setOptions({
        draggableCursor: 'default',
      });
    }
  }, [mapInstance, enableSetAddressMode, prevEnableSetAddressMode]);

  useEffect(() => {
    if (prevMapType !== mapType && locationMarker) {
      const MarkerLocation = getGeolocationMarkerClass();
      const marker = new MarkerLocation(mapInstance, null, null, null);
      setLocationMarker(marker);
    }
  }, [locationMarker, mapInstance, mapType, prevMapType]);

  const handleBoundsChange = async ({
    zoom: newZoom,
    bounds: boundsValues,
  }: Record<string, any>) => {
    setBounds([
      // [westLng, southLat, eastLng, northLat]
      boundsValues.nw.lng,
      boundsValues.se.lat,
      boundsValues.se.lng,
      boundsValues.nw.lat,
    ]);

    setCurrentZoom(newZoom);

    const { lat: leftBottomLatitude, lng: leftBottomLongitude } =
      boundsValues.sw;
    const { lat: rightTopLatitude, lng: rightTopLongitude } = boundsValues.ne;

    if (leftBottomLongitude === undefined) {
      return;
    }

    dispatch(
      fetchViewportPoints({
        leftBottomLatitude,
        leftBottomLongitude,
        rightTopLatitude,
        rightTopLongitude,
      })
    );
  };

  const handleMapClick = (event: ClickEventValue) => {
    if (enableSetAddressMode) {
      notification.destroy();
      batch(() => {
        dispatch(setAddressCoords({ lat: event.lat, lng: event.lng }));
        dispatch(setEnableAddressMode(false));
      });

      return;
    }

    handleHidePointPopup();
  };

  const handleGoogleApi = ({ map, maps }: GoogleMapsApi) => {
    setMapInstance(map);
    setMapApi(maps);

    const MarkerLocation = getGeolocationMarkerClass();
    const marker = new MarkerLocation(map, null, null, null);
    setLocationMarker(marker);
  };

  const clustersElementsList = useMemo(
    () =>
      clusters.map(point => {
        const [longitude, latitude] = point.geometry.coordinates;

        const { point_count: pointsCount, id } = point.properties;

        return (
          <Point
            key={id}
            lat={latitude}
            lng={longitude}
            pointsCount={pointsCount}
            onClick={createHandleClickPoint(id)}
          >
            <PointIcon mapType={mapType} isActive={id === openedPointId} />
          </Point>
        );
      }),
    [clusters, createHandleClickPoint, mapType, openedPointId]
  );

  return (
    <Suspense fallback="">
      <div className={classes.container}>
        <GoogleMapReact
          onClick={handleMapClick}
          onChange={handleBoundsChange}
          resetBoundsOnResize
          bootstrapURLKeys={bootstrapKeys}
          yesIWantToUseGoogleMapApiInternals
          options={googleMapConfigOptions}
          defaultZoom={DEFAULT_ZOOM}
          onGoogleApiLoaded={handleGoogleApi}
          defaultCenter={ownPosition || MAP_DEFAULT_COORDS.CENTER}
        >
          {clustersElementsList}
        </GoogleMapReact>
      </div>
    </Suspense>
  );
};

export default Map;
