import { useContext } from 'react';
import {
  returnTypeMapContextApiAction,
  returnTypeMapContextApi,
  returnTypeMapInstance,
  returnTypeMapInstanceAction,
} from '../../types';
import {
  MapContextApi,
  MapContextApiAction,
  MapContextInstance,
  MapContextInstanceAction,
} from './MapContext.context';

export const useMapContextApiAction = (): returnTypeMapContextApiAction =>
  useContext<returnTypeMapContextApiAction>(MapContextApiAction);
export const useMapContextApi = (): returnTypeMapContextApi =>
  useContext<returnTypeMapContextApi>(MapContextApi);
export const useMapInstance = (): returnTypeMapInstance =>
  useContext<returnTypeMapInstance>(MapContextInstance);
export const useMapInstanceAction = (): returnTypeMapInstanceAction =>
  useContext<returnTypeMapInstanceAction>(MapContextInstanceAction);
