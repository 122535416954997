import clsx from 'clsx';
import { FC, ReactElement } from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import classes from './SeedCopyElement.module.scss';
import CopyLinkIcon from '../../Icons/CopyLinkIcon/CopyLinkIcon';

interface ISeedCopyElement {
  value: string | null;
}

const SeedCopyElement: FC<ISeedCopyElement> = ({ value }): ReactElement => {
  const { t } = useTranslation();

  const handleCopyText = () => {
    navigator.clipboard.writeText(value || '');
    message.info(t('clipboard_done'));
  };

  return (
    <div className={clsx(classes.statsItem, classes.seedBlock)}>
      <div className={classes.container}>
        <div className={clsx(classes.counter, classes.link)}>{value}</div>
        <div
          onClick={handleCopyText}
          className={classes.copyButton}
          role="button"
          aria-pressed="false"
          tabIndex={-1}
          aria-label="copy text"
        >
          <CopyLinkIcon />
        </div>
      </div>
    </div>
  );
};

export default SeedCopyElement;
