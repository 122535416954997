import t from 'tcomb';

export interface IPrivateTComb {
  id: number;
  pubkey: string;
  label: string;
  // eslint-disable-next-line camelcase
  import_account_names: Array<string>;
  // eslint-disable-next-line camelcase
  brainkey_sequence: number;
  // eslint-disable-next-line camelcase
  encrypted_key: string;
}

export const Asset = t.struct(
  {
    bitasset_data_id: t.maybe(t.String),
    bitasset_data: t.maybe(t.Object),
    dynamic_asset_data_id: t.String,
    dynamic_data: t.maybe(t.Object),
    params: t.maybe(t.Object),
    id: t.String,
    issuer: t.String,
    market_asset: t.Boolean,
    options: t.Object,
    precision: t.Number,
    symbol: t.String,
  },
  'Asset'
);

export const Block = t.struct(
  {
    extensions: t.Array,
    id: t.Number,
    previous: t.String,
    timestamp: t.Date,
    transactions: t.Array,
    transaction_merkle_root: t.String,
    witness: t.String,
    witness_signature: t.String,
  },
  'Block'
);

export const WalletTcomb = t.struct(
  {
    public_name: t.String,
    created: t.Date,
    last_modified: t.Date,
    backup_date: t.maybe(t.Date),
    password_pubkey: t.String,
    encryption_key: t.String,
    encrypted_brainkey: t.maybe(t.String),
    brainkey_pubkey: t.String,
    brainkey_sequence: t.Number,
    brainkey_backup_date: t.maybe(t.Date),
    deposit_keys: t.maybe(t.Object),
    // password_checksum: t.Str,
    chain_id: t.String,
  },
  'WalletTcomb'
);

export const PrivateKeyTcomb = t.struct<IPrivateTComb>(
  {
    id: t.maybe(t.Number),
    pubkey: t.String,
    label: t.maybe(t.String),
    import_account_names: t.maybe(t.Array),
    brainkey_sequence: t.maybe(t.Number),
    encrypted_key: t.String,
  },
  'PrivateKeyTcomb'
);

// export let PublicKeyTcomb = t.struct({
//    id: t.maybe(t.Num),
//    pubkey: t.Str,
//    key_id: t.maybe(t.Str)
// }, "PublicKeyTcomb");

export const LimitOrder = t.struct(
  {
    expiration: t.Date,
    for_sale: t.Number,
    id: t.String,
    sell_price: t.Object,
    seller: t.String,
  },
  'LimitOrder'
);

export const SettleOrder = t.struct(
  {
    settlement_date: t.Date,
    balance: t.Object,
    owner: t.String,
  },
  'SettleOrder'
);

export const ShortOrder = t.struct(
  {
    expiration: t.Date,
    for_sale: t.Number,
    id: t.String,
    sell_price: t.Object,
    seller: t.String,
  },
  'ShortOrder'
);

export const CallOrder = t.struct(
  {
    borrower: t.String,
    call_price: t.Object,
    collateral: t.Number,
    debt: t.Number,
    id: t.String,
  },
  'CallOrder'
);
