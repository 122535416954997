import getWalletStore from './getWalletStore';
import isNill from './isNill';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getBrainKeySequence = async () => {
  const store = await getWalletStore();

  const key = store?.brainkey_sequence;

  if (isNill(key)) {
    return 0;
  }

  return key;
};

export default getBrainKeySequence;
