import { FC, ReactElement } from 'react';
import { AppStoreIconProps } from '../../../types/components.types';
import classes from './AppStoreIcon.module.scss';

const AppStoreIcon: FC<AppStoreIconProps> = ({ onClick }): ReactElement => (
  <div role="link" aria-label="app store link" tabIndex={-1} onClick={onClick}>
    <img
      className={classes.icon}
      src="/img/appStore.png"
      alt="appStore_download"
    />
  </div>
);

export default AppStoreIcon;
