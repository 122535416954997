/* eslint-disable no-unused-vars */
import { Radio, RadioChangeEvent } from 'antd';
import { ReactElement, useState } from 'react';
import classes from './TimeSwitch.module.scss';
import BillInput from '../BillInput/BillInput';
import moment, { Moment } from 'moment';
import TimeStepInput from '../TimeStepInput/TimeStepInput';

export type EndTime = 'default' | 'timer';

const TimeSwitch = (): ReactElement => {
  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [time, setTime] = useState<Moment | null>(null);
  const [currentDate, setCurrentDate] = useState<Moment | null>(() => moment());
  const [endTimeType, setEndTimeType] = useState<EndTime>('default');

  const handleChangeType = (event: RadioChangeEvent) => {
    setEndTimeType(event.target.value);
  };

  const timerDisabled = endTimeType !== 'timer';
  const defaultDisabled = endTimeType !== 'default';

  const handleChangeDate = (value: Moment | null) => {
    // eslint-disable-next-line no-console
    console.log(value);

    setCurrentDate(value);
  };

  return (
    <div>
      <Radio.Group value={endTimeType} onChange={handleChangeType}>
        <Radio className={classes.radioGroup} value="default">
          <BillInput
            onChangeDate={handleChangeDate}
            disabled={defaultDisabled}
            dateValue={currentDate}
            showTime
            type="date"
          />
        </Radio>
        <Radio className={classes.radioGroup} value="timer">
          <TimeStepInput onChange={setTime} disabled={timerDisabled} showTime />
        </Radio>
      </Radio.Group>
    </div>
  );
};

export default TimeSwitch;
