import { LockStore } from '../../interfaces';
import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';

const initialState: LockStore = {
  isLock: true,
};

const lockReducer: Slice<LockStore> = createSlice({
  name: 'lockReducer',
  initialState,
  reducers: {
    lock: {
      reducer: (state, { payload }: PayloadAction<boolean>) => {
        state.isLock = payload;
      },
      prepare: () => ({
        payload: true,
      }),
    },
    unlock: {
      reducer: (state, { payload }: PayloadAction<boolean>) => {
        state.isLock = payload;
      },
      prepare: () => ({
        payload: false,
      }),
    },
  },
});

export const { lock, unlock } = lockReducer.actions;

export default lockReducer.reducer;
