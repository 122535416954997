import { IDBPDatabase, IDBPObjectStore, IDBPTransaction } from 'idb';

// eslint-disable-next-line no-unused-vars,max-classes-per-file
// @ts-ignore
// eslint-disable-next-line no-unused-vars

class ManageDatabase {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,class-methods-use-this
  trxReadwrite(db: IDBDatabase, objectStores: string) {
    return db.transaction([objectStores], 'readwrite');
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static async removeAll(
    store: IDBPObjectStore<
      any,
      string | string[],
      any,
      'readonly' | 'readwrite' | 'versionchange'
    >,
    key: string
  ) {
    try {
      // @ts-ignore
      return store.delete(key);
    } catch {
      return null;
    }
  }

  // eslint-disable-next-line no-unused-vars,@typescript-eslint/explicit-module-boundary-types
  static async add(
    store: IDBPObjectStore<
      any,
      string | string[],
      any,
      'readonly' | 'readwrite' | 'versionchange'
    >,
    value: Record<string, any>
  ) {
    try {
      // @ts-ignore
      return await store.add(value);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      return null;
    }
  }

  // eslint-disable-next-line no-unused-vars,@typescript-eslint/explicit-module-boundary-types
  static async cursor(
    db: IDBPDatabase,
    storeName: string,
    _transaction?: IDBPTransaction
  ) {
    let transaction: IDBPTransaction | undefined = _transaction;

    try {
      if (!_transaction) {
        transaction = await db.transaction([storeName], 'readonly');
      }

      const store = (transaction as IDBPTransaction).objectStore(storeName);

      return await store.openCursor();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      return null;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static autoIncrementUnique(
    db: IDBPDatabase,
    tableName: string,
    uniqueIndex: string
  ) {
    return db
      .createObjectStore(tableName, { keyPath: 'id', autoIncrement: true })
      .createIndex('by_' + uniqueIndex, uniqueIndex, { unique: true });
  }
}

export default ManageDatabase;
