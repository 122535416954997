import { forwardRef, ReactElement } from 'react';
import Menu from '../Menu/Menu';
import classes from './Aside.module.scss';
import { AsideProps } from '../../types/components.types';
import { useParams } from 'react-router-dom';

const Aside = forwardRef<HTMLDivElement, AsideProps>(
  ({ customMenuList, hasLangSwitcher }, ref): ReactElement => {
    const { activeMenuId } = useParams<Record<string, string>>();

    return (
      <div ref={ref} className={classes.aside}>
        <Menu
          hasLangSwitcher={hasLangSwitcher}
          customMenuList={customMenuList}
          activeMenuId={activeMenuId}
        />
      </div>
    );
  }
);

export default Aside;
