import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';
import { fetchTransactionsListApi } from '../../api/WalletApi/WalletApi.api';
import { ErrorResponse, Transaction } from '../../interfaces';

export const fetchTransactionsThunkCreator: AsyncThunkPayloadCreator<
  Transaction[] | ErrorResponse
> = async (_, thunkApi) => {
  const [data, isError] = await fetchTransactionsListApi();

  if (isError) {
    return thunkApi.rejectWithValue(data);
  }

  return data;
};
