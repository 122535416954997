import { ErrorResponse } from '../../interfaces';
import Request from '../../models/Request/Request';
import RequestError from '../../models/RequestError/RequestError';
import {
  createrTransaction,
  returnTransactionList,
  returnCreateTransaction,
} from '../../types';
import { handleRequestError } from '../../utils/utils.global';
import { USER_ENDPOINTS, WALLET_ENDPOINTS } from '../Endpoints.constant';

export const fetchTransactionsListApi =
  async (): Promise<returnTransactionList> => {
    try {
      const request = new Request();

      const response = await request.send(
        WALLET_ENDPOINTS.TRANSACTIONS_LIST,
        'GET'
      );

      return [response.data, false];
    } catch (error: unknown) {
      return handleRequestError(error as RequestError);
    }
  };

export const createTransaction = async (
  values: createrTransaction
): Promise<returnCreateTransaction> => {
  try {
    const request = new Request();

    const response = await request.send(
      WALLET_ENDPOINTS.TRANSACTION_CREATE,
      'POST',
      values
    );

    return [response.data, false];
  } catch (error) {
    return handleRequestError(error as RequestError, true);
  }
};

export const subscribeOnPlan = async (
  planId: string
): Promise<[boolean | ErrorResponse, boolean]> => {
  try {
    const request = new Request();

    const response = await request.send(USER_ENDPOINTS.SUB_UPDATE, 'POST', {
      planId,
    });

    return [response.data, false];
  } catch (error) {
    return handleRequestError(error as RequestError, true);
  }
};
